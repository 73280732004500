import { render, staticRenderFns } from "./InviteBulkMember.vue?vue&type=template&id=2e275082&scoped=true&"
import script from "./InviteBulkMember.vue?vue&type=script&lang=ts&"
export * from "./InviteBulkMember.vue?vue&type=script&lang=ts&"
import style0 from "./InviteBulkMember.vue?vue&type=style&index=0&id=2e275082&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e275082",
  null
  
)

export default component.exports