<template>
  <v-navigation-drawer
    v-model="drawer"
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
    app
  >
    <v-toolbar
      ref="header"
      class="l-navigation-header"
    >
      <v-toolbar-title>
        <a href="/">
          <img
            alt="Home"
            class="pcLogo"
            src="@/img/logo_color.png"
            title="Home"
          >
        </a>
      </v-toolbar-title>
    </v-toolbar>
    <div :style="listContainerStyle">
      <v-text-field
        v-model="searchString"
        autocomplete="off"
        class="ma-2"
        clearable
        hide-details
        placeholder="名前/タグ/メールアドレス"
        solo
      >
        <template #append>
          <v-fade-transition leave-absolute>
            <v-progress-circular
              v-if="loading"
              color="primary"
              indeterminate
              size="24"
            />
            <v-icon v-else>
              {{ icons.mdiMagnify }}
            </v-icon>
          </v-fade-transition>
        </template>
      </v-text-field>
      <v-divider />
      <InfiniteScroll
        :loading="loading"
        class="p-userList"
        dark
        list-item-type="list-item-avatar"
        @infinite="fetch"
      >
        <template v-for="item of users">
          <v-list-item
            :key="item.id"
            :to="`/members/${item.id}`"
            class="p-userList__item"
          >
            <v-list-item-avatar
              class="mr-4 p-userList__item__avatar"
              size="32px"
            >
              <Avatar
                :user="item"
                size="32px"
              />
              <span class="c-feelingBadge">{{ _.get(item, 'feeling.emoji', defaultFeeling) }}</span>
            </v-list-item-avatar>
            <v-list-item-content class="py-2">
              <v-row
                align="center"
                class="ma-0 pa-0"
              >
                <div class="p-userList__item__userName">
                  <strong>{{ item.name }}</strong>
                </div>
                <v-spacer />
                <v-icon
                  v-if="item.online"
                  color="#99d04a"
                  x-small
                >
                  {{ icons.mdiCircle }}
                </v-icon>
                <v-icon
                  v-else
                  color="#d3d4d3"
                  x-small
                >
                  {{ icons.mdiCircleOutline }}
                </v-icon>
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-btn
                      :disabled="item.id === currentUser.id"
                      class="p-user-list__item__thanksBtn ml-1"
                      icon
                      tile
                      @mouseenter="tooltip.mouseenter"
                      @mouseleave="tooltip.mouseleave"
                      @click.prevent.capture="
                        tooltip.mouseleave()
                        clickThanks(item)
                      "
                    >
                      <v-icon>{{ icons.mdiHandHeart }}</v-icon>
                    </v-btn>
                  </template>
                  <span>感謝をおくる</span>
                </v-tooltip>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </template>
      </InfiniteScroll>
    </div>
    <div class="c-adPanel">
      <WordPressArticlePanel
        v-if="wordPressArticlePanelHeight >0"
        :domain="wordpressDomain"
        :height="wordPressArticlePanelHeight - 4 * 2"
      />
    </div>
    <SendThanksDialog
      v-model="thanksDialog"
      :recipient-id="recipientId"
    />
  </v-navigation-drawer>
</template>
<script lang="ts">
import Vue from 'vue'
import { defaultFeeling } from '@/store/models/Feeling'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { Item, Record } from '@vuex-orm/core'
import { User } from '@/store/models/Person'
import Component from 'vue-class-component'
import visibilityChange from '@/directives/visibilityChange'
import Me from '@/store/models/Me'
import { mdiCircle, mdiCircleOutline, mdiGiftOutline, mdiHandHeart, mdiMagnify } from '@mdi/js'
import Avatar from '@/components/organisms/Avatar.vue'
import InfiniteScroll from '@/components/organisms/InfiniteScroll.vue'
import WordPressArticlePanel from '@/components/organisms/WordPressArticlePanel.vue'
import _isNil from 'lodash/isNil'
import SendThanksDialog from '@/components/organisms/gifts/SendThanksDialog.vue'

@Component({
  components: {
    Avatar,
    InfiniteScroll,
    SendThanksDialog,
    WordPressArticlePanel,
  },
  directives: {
    visibilityChange,
  },
})
export default class extends Vue {
  $refs: {
    header: Vue
  }
  readonly defaultFeeling = defaultFeeling
  readonly icons = {
    mdiMagnify,
    mdiCircle,
    mdiCircleOutline,
    mdiGiftOutline,
    mdiHandHeart,
  }
  @Prop({ default: null })
  readonly value?: boolean
  loadings = []
  searchString = ''
  page = 0
  recipientId: number = null
  header: Element = null
  listContainerStyle: Partial<CSSStyleDeclaration> = {
    overflowY: 'auto',
  }
  thanksDialog = false

  get drawer(): boolean {
    return this.value
  }

  set drawer(value) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get users(): Record[] {
    const searchStrings = (this.searchString || '').trim().split(' ')
    const query = User.available().with('feeling')
    searchStrings.forEach((searchString) => {
      const re = new RegExp(searchString.trim().replace(/[\\^$.*+?()[\]{}|]/g, '\\$&'))
      query.where((record) => {
        if (searchString === '') return true
        if (re.test(record.email)) return true
        if (re.test(record.name)) return true
        const tags = record.tags || []
        for (const tag of tags) {
          if (re.test(tag)) {
            return true
          }
        }
        return false
      })
    })
    return query.orderBy('takes', 'desc').get()
  }

  get loading(): boolean {
    return this.loadings.length > 0
  }

  get wordPressArticlePanelHeight(): number {
    if (_isNil(this.wordpressDomain)) return 0
    if (this.$vuetify.breakpoint.smAndDown) return 0
    return 260
  }

  get wordpressDomain(): string {
    return process.env.WORDPRESS_DOMAIN || null
  }

  mounted(): void {
    this.header = this.$refs.header.$el
    this.onResize()
    window.addEventListener('resize', this.onResize)
  }

  @Emit()
  input(value): boolean {
    return value
  }

  @Watch('searchString')
  search(): void {
    this.page = 0
    this.fetch()
  }

  fetch(): void {
    const key = Math.random().toString(32).substring(2)
    this.loadings.push(key)
    User.fetchAll({ useCache: false, available: true }).finally(() => {
      this.loadings = this.loadings.filter((val) => val !== key)
    })
  }

  beforeDestroy(): void {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize)
  }

  onResize(): void {
    const rect = this.header.getBoundingClientRect()
    this.listContainerStyle = {
      maxHeight: `calc(100% - ${rect.height}px - ${this.wordPressArticlePanelHeight}px)`,
      overflowY: 'auto',
    }
  }

  clickThanks(user: Item<User>): void {
    this.recipientId = user.id
    this.thanksDialog = true
  }
}
</script>

<style lang="scss" scoped>
.p-userList {
  &__item {
    &__userName {
      min-width: 0;
      min-height: 21px;
      overflow-x: hidden;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__avatar {
      overflow: visible;
    }

    &:hover {
      &__userName {
        color: #000;
      }

      &__thanksBtn {
        color: #000;
      }
    }
  }
}

.c-feelingBadge {
  text-shadow: 1px 2px #e15d74,
  -1px 2px #e15d74,
  1px -2px #e15d74,
  -1px -2px #e15d74,
  2px 1px #e15d74,
  -2px 1px #e15d74,
  2px -1px #e15d74,
  -2px -1px #e15d74;
}

.c-adPanel {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 4px;
}
</style>
