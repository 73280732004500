/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
/* eslint @typescript-eslint/no-use-before-define: "off" */
/**
 * HACK: Userを継承したかったが、afterWhere、afterOrderByがもともとのクラスに定義されていないためかエラーになってしまうためコピペになっちゃってます。
 */
import { Collection, Fields, InstanceOf, Item, Query, Record } from '@vuex-orm/core'
import _isNil from 'lodash/isNil'
import * as md5 from 'js-md5'
import coverImage from '@/img/cover.png'
import _get from 'lodash/get'
import { nullDateForOrderBy } from '@/store/models/const'
import Feeling from '@/store/models/Feeling'
import _cloneDeep from 'lodash/cloneDeep'
import _forEach from 'lodash/forEach'
import Model, { FetchOptions, FetchResult } from '@/store/models/Model'
import { getDisplayName } from '@/utils/user'
import Department from '@/store/models/Department'
import * as http from '@/utils/http'

export const UserStatuses = [
  'active', 'invited', 'applied', 'deactivated',
] as const
export type UserStatus = typeof UserStatuses[number]
;export default class Person extends Model {
  static entity = 'Person'
  static _endpoint = 'persons'
  static readonly dateTimeFields = [
    'last_sign_in_at',
    'current_sign_in_at',
    'invitation_created_at',
    'invitation_sent_at',
    'invitation_accepted_at',
    'created_at',
    'updated_at',
    'deleted_at',
  ]
  id!: number
  email!: string
  name!: string
  avatar: string
  cover: string
  last_sign_in_at: string
  current_sign_in_at: string
  invitation_created_at: string
  invitation_sent_at: string
  invitation_accepted_at: string
  created_at: string
  updated_at: string
  deleted_at: string
  online: boolean
  description: string
  approved_at: string

  get avatarImage(): string {
    const avatar = _get(this, 'avatar')
    return avatar ? avatar : `https://www.gravatar.com/avatar/${md5(_get(this, 'email'))}?d=identicon`
  }

  get coverImage(): string {
    const cover = _get(this, 'cover')
    return cover ? cover : coverImage
  }

  get displayName(): string {
    return getDisplayName(this)
  }

  get status(): UserStatus {
    if (!_isNil(_get(this, 'deleted_at'))) return 'deactivated'
    if (!_isNil(_get(this, 'invitation_sent_at'))) {
      if (_isNil(_get(this, 'invitation_accepted_at'))) return 'invited'
    }
    if (_isNil(_get(this, 'approved_at'))) return 'applied'
    return 'active'
  }

  static available<T extends typeof Model>(this: T): Query<InstanceOf<T>> {
    return this.query()
      .where((record) => record.id > 0)
      .where((record) => _isNil(record.deleted_at))
  }

  static types(): {
    User: typeof User
    Resident: typeof Resident
  } {
    return {
      User,
      Resident,
    }
  }

  static fields(): Fields {
    return {
      id: this.attr(null),
      email: this.attr(null),
      name: this.attr(null),
      avatar: this.attr(null),
      cover: this.attr(null),
      last_sign_in_at: this.attr(null),
      current_sign_in_at: this.attr(null),
      invitation_created_at: this.attr(null),
      invitation_sent_at: this.attr(null),
      invitation_accepted_at: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      deleted_at: this.attr(null),
      online: this.attr(false),
      description: this.attr(null),
      approved_at: this.attr(null),
    }
  }
}

const isDeactivated = ({ deleted_at }: Item<User>): boolean => {
  return !_isNil(deleted_at)
}

const isInvited = ({ invitation_sent_at, invitation_accepted_at }: Item<User>): boolean => {
  if (_isNil(invitation_sent_at)) return false
  return _isNil(invitation_accepted_at)
}

const isApplied = ({ approved_at }: Item<User>): boolean => {
  return _isNil(approved_at)
}

export class User extends Person {
  static entity = 'users'
  static _endpoint = 'users'
  static baseEntity = Person.entity
  admin: string
  feeling_id: number
  feeling: Feeling
  tags: string[]
  point: number
  takes: number
  keeps: number
  followers: Collection<User>
  followings: Collection<User>
  department_id: number
  department: Item<Department>
  employee_id: string

  static queryByStatus<T extends typeof User>(this: T, status: 'all' | UserStatus): Query<InstanceOf<T>> {
    switch (status) {
      case 'deactivated':
        return this.deactivated()
      case 'invited':
        return this.invited()
      case 'applied':
        return this.applied()
      case 'active':
        return this.active()
      default:
        return this.query()
    }
  }

  static deactivated<T extends typeof Model>(this: T): Query<InstanceOf<T>> {
    return this.query().where(isDeactivated)
  }

  static invited<T extends typeof Model>(this: T): Query<InstanceOf<T>> {
    return this.query().where((record) => !isDeactivated(record) && isInvited(record))
  }

  static applied<T extends typeof Model>(this: T): Query<InstanceOf<T>> {
    return this.query().where((record) => !isDeactivated(record) && isApplied(record))
  }

  static active<T extends typeof Model>(this: T): Query<InstanceOf<T>> {
    return this.query().where((record) => !isDeactivated(record) && !isInvited(record) && !isApplied(record))
  }

  static fields(): Fields {
    return {
      ...super.fields(),
      admin: this.attr(null),
      feeling_id: this.attr(null),
      feeling: this.belongsTo(Feeling, 'feeling_id'),
      tags: this.attr(null),
      point: this.attr(0),
      takes: this.attr(0),
      keeps: this.attr(0),
      followers: this.attr([]),
      followings: this.attr([]),
      department_id: this.attr(null),
      department: this.belongsTo(Department, 'department_id'),
      employee_id: this.attr(null),
    }
  }

  static afterWhere(persons: Record[]): Record[] {
    return persons.map((person) => {
      const _person: Record = _cloneDeep(person)
      // 日付項目のnullの順番をサーバーサイドと同じにするため（他の項目も必要であれば追加する）
      _forEach(User.dateTimeFields, (field) => {
        if (_isNil(_person[field])) _person[field] = nullDateForOrderBy
      })
      return _person
    })
  }

  static afterOrderBy(persons: Record[]): Record[] {
    return persons.map((person) => {
      const _person: Record = _cloneDeep(person)
      // afterWhereで変更した値を戻す
      _forEach(User.dateTimeFields, (field) => {
        if (_person[field] === nullDateForOrderBy) _person[field] = null
      })
      return _person
    })
  }

  static async fetchAll(fetchOptions: Partial<FetchOptions & { available: boolean }> = {}): Promise<FetchResult> {
    if (fetchOptions?.available) {
      fetchOptions.params = fetchOptions.params || {}
      fetchOptions.params['with_deleted'] = true // 削除メンバーを含む
      fetchOptions.params['with_unaccepted'] = true // 招待に応じていないメンバーを含む
      fetchOptions.params['with_unapproved'] = true // 承認していないメンバーを含む
      fetchOptions.params['q[deleted_at_null]'] = true
      fetchOptions.params['q[g[0][m]]'] = 'or'
      fetchOptions.params['q[g[0][g[0][invitation_sent_at_not_null]]]'] = true
      fetchOptions.params['q[g[0][g[0][invitation_accepted_at_not_null]]]'] = true
      fetchOptions.params['q[g[0][g[1][invitation_sent_at_null]]]'] = true
      fetchOptions.params['q[g[0][g[1][approved_at_not_null]]'] = true
    }
    return super.fetchAll(fetchOptions)
  }

  static createSearchCondition({
    searchString,
    departmentId,
    tags,
    status = 'all',
  }: {
    searchString?: string
    departmentId?: number
    tags?: string[]
    status?: 'all' | UserStatus
  }): Partial<http.RansackParams> {
    const condition: Partial<http.RansackParams> = {}
    if (`${searchString || ''}` !== '') {
      condition['q[email_or_name_cont]'] = searchString
    }
    if (!_isNil(departmentId)) {
      condition['q[department_id_eq]'] = departmentId
    }
    if (!_isNil(tags) && tags.length > 0) {
      condition['q[tags_name_in]'] = tags
    }
    switch (status) {
      case 'invited':
        condition['q[deleted_at_null]'] = true
        condition['q[invitation_sent_at_not_null]'] = true
        condition['q[invitation_accepted_at_null]'] = true
        break
      case 'applied':
        condition['q[deleted_at_null]'] = true
        condition['q[approved_at_null]'] = true
        break
      case 'deactivated':
        condition['q[deleted_at_not_null]'] = true
        break
      case 'active':
        condition['q[deleted_at_null]'] = true
        condition['q[g[0][m]]'] = 'or'
        condition['q[g[0][g[0][invitation_sent_at_not_null]]]'] = true
        condition['q[g[0][g[0][invitation_accepted_at_not_null]]]'] = true
        condition['q[g[0][g[1][invitation_sent_at_null]]]'] = true
        condition['q[g[0][g[1][approved_at_not_null]]'] = true
        break
      case 'all':
      default:
    }
    condition['with_deleted'] = true // 削除メンバーを含む
    condition['with_unaccepted'] = true // 招待に応じていないメンバーを含む
    condition['with_unapproved'] = true // 承認していないメンバーを含む
    return condition
  }
}

export class Resident extends Person {
  static entity = 'residents'
  static _endpoint = 'residents'
  static baseEntity = Person.entity
  shake: number
  role: string
  tags: string[]

  static fields(): Fields {
    return {
      ...super.fields(),
      shake: this.attr(0),
      role: this.attr(null),
      tags: this.attr(null),
    }
  }

  static afterWhere(persons: Record[]): Record[] {
    return persons.map((person) => {
      const _person: Record = _cloneDeep(person)
      // 日付項目のnullの順番をサーバーサイドと同じにするため（他の項目も必要であれば追加する）
      _forEach(Resident.dateTimeFields, (field) => {
        if (_isNil(_person[field])) _person[field] = nullDateForOrderBy
      })
      return _person
    })
  }

  static afterOrderBy(persons: Record[]): Record[] {
    return persons.map((person) => {
      const _person: Record = _cloneDeep(person)
      // afterWhereで変更した値を戻す
      _forEach(Resident.dateTimeFields, (field) => {
        if (_person[field] === nullDateForOrderBy) _person[field] = null
      })
      return _person
    })
  }
}
