<template>
  <v-list-item>
    <v-list-item-avatar class="align-self-start c-list-item__avatar">
      <Avatar :user="comment.user" />
    </v-list-item-avatar>
    <v-list-item-content class="flex-column align-start">
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <div>
          <router-link
            :to="{ name: 'member/home', params: { id: comment.user_id } }"
            class="c-comment__user__name"
          >
            <strong>{{ _.get(comment, 'user.name') }}</strong>
          </router-link>
          <div class="timeline-date ml-2">
            {{ $d($moment(comment.created_at).toDate(), 'long') }}
          </div>
        </div>
      </v-row>
      <v-form
        v-show="editingComment"
        class="c-comment__body"
        @submit.stop.prevent="save"
      >
        <MessageEditor
          v-validate="'required|max:280'"
          :attachments="editingComment && editingComment.attachments"
          :error-messages="commentErrorMessages"
          :value="editingComment && editingComment.text"
          class="c-comment__text--editing"
          data-vv-name="commentBody"
          data-vv-validate-on="blur|input"
          @changeAttachments="editingComment.attachments = $event"
          @input="editingComment.text = $event"
          @keydown.alt.enter.stop.prevent.capture="save"
          @keydown.ctrl.enter.stop.prevent.capture="save"
          @keydown.shift.enter.stop.prevent.capture="save"
          @keydown.meta.enter.stop.prevent.capture="save"
          @keydown.esc.stop.prevent.capture="cancel"
        />
        <v-row class="ma-0 pa-0">
          <v-btn
            class="mr-2"
            @click="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="isInvalid"
            color="primary"
            type="submit"
          >
            {{ $t('button.save') }}
          </v-btn>
          <v-spacer />
        </v-row>
      </v-form>
      <v-row
        v-show="!editingComment"
        class="ma-0 pa-0 c-comment__body"
      >
        <span
          :class="{ 'c-comment__text--edited': isEdited }"
          class="c-comment__text"
          v-html="$marked(comment.body)"
        />
        <v-row
          v-if="isOwn"
          align-content="start"
          class="ma-0 pa-0 flex-grow-0 flex-shrink-0 ml-auto"
        >
          <v-spacer />
          <v-btn
            :disabled="disabled"
            icon
            small
            tile
            @click.capture="edit"
          >
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
        </v-row>
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mt-2"
      >
        <ReactionChipGroup
          :loading="reactionLoading"
          :value="reactions"
          @click:reaction="selectReaction"
        />
      </v-row>
    </v-list-item-content>
    <Actions
      :absolute="true"
      :show="!editingComment && (showActions || showReaction)"
    >
      <ReactionAction
        @hidden="showReaction = false"
        @shown="showReaction = true"
        @select:reaction="selectReaction"
      />
    </Actions>
  </v-list-item>
</template>
<script lang="ts">
import { defaultFeeling } from '@/store/models/Feeling'
import Avatar from '@/components/organisms/Avatar.vue'
import Component, { mixins } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Comment from '@/store/models/Comment'
import Reactable from '@/mixins/reactable'
import Actable from '@/mixins/actable'
import _get from 'lodash/get'
import Me from '@/store/models/Me'
import _isNil from 'lodash/isNil'
import { mdiPencil } from '@mdi/js'
import { CommentableModel as _CommentableModel } from '@/store/models'
import { FieldError } from 'vee-validate/types/vee-validate'
import MessageEditor, { MessageEditorValue } from '@/components/organisms/editor/MessageEditor.vue'
import notifyResponseError from '@/utils/notifyResponseError'

type CommentableModel = _CommentableModel
@Component({
  components: {
    Avatar,
    MessageEditor,
  },
})
export default class CommentListItemComponent extends mixins(Actable, Reactable) {
  readonly defaultFeeling = defaultFeeling
  readonly reactableModel = Comment
  readonly icons = { mdiPencil }
  @Prop({ default: null })
  readonly model!: CommentableModel
  @Prop({ default: {} })
  readonly value!: Record
  @Prop({ default: false })
  readonly disabled!: boolean
  editingComment: MessageEditorValue = null

  get comment(): Record {
    return this.item
  }

  get item(): Record {
    return Comment.query().with('user').with('reactions').with('reactions.user').find(this.value.id)
  }

  get isEdited(): boolean {
    return _get(this.comment, 'edited')
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get isOwn(): boolean {
    if (_isNil(this.comment)) return false
    return this.comment.user_id === _get(this.currentUser, 'id')
  }

  get isInvalid(): boolean {
    if (_isNil(this.editingComment)) return true
    if (this.editingComment.text.replace(/[\s\t\n]+/g, '') === '') return true
    return this.$validator.errors && this.$validator.errors.count() > 0
  }

  get commentErrorMessages(): FieldError[] {
    if (this.isInvalid) return null
    return this.$validator.errors.collect('commentBody')
  }

  @Emit('startEditing')
  startEditing(): void {
    return void 0
  }

  @Emit('finishEditing')
  finishEditing(): void {
    return void 0
  }

  edit(): void {
    this.editingComment = {
      text: this.comment.body,
      attachments: [],
    }
    this.startEditing()
  }

  cancel(): void {
    this.reset()
    this.finishEditing()
  }

  async save(): Promise<void> {
    if (this.isInvalid) return
    if (!(await this.$validator.validateAll())) return
    const comment: {
      body: string
      attachment_ids?: number[]
    } = {
      body: this.editingComment.text,
    }
    if (this.editingComment.attachments.length > 0) {
      comment['attachment_ids'] = this.editingComment.attachments.map((file) => file.id)
    }
    this.reset()
    this.$http
      .patch(['comments', this.comment.id].join('/'), comment)
      .then(({ data }) => {
        this.model.insertOrUpdate({ data })
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        if (err.status === 422) {
          notifyResponseError(err.response.data)
          return
        }
        return Promise.reject(err)
      })
      .finally(() => {
        this.reset()
        this.finishEditing()
      })
  }

  reset(): void {
    this.$validator.pause()
    this.$validator.reset().then(() => this.$validator.resume())
    this.showActions = false
    this.showReaction = false
    this.editingComment = null
  }
}
</script>
<style lang="scss" scoped>
form {
  &.c-comment__body {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.c- {
  &comment {
    &__body {
      width: 100%;
    }

    &__text {
      word-break: break-all;
      word-wrap: break-word;

      &--edited {
        // noinspection CssInvalidPseudoSelector
        :deep(.markdown-body) {
          &::after {
            display: inline-table;
            margin-left: 4px;
            color: #d3d4d3;
            content: '(edited)';
          }

          > :not(ol, ul, pre, blockquote):last-child {
            display: inline;
          }
        }
      }
    }

    &__user {
      &__name {
        font-size: .9rem;
      }
    }
  }

  &list-item {
    &__avatar {
      overflow: visible;
    }
  }
}
</style>
