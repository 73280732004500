<template>
  <v-menu
    content-class="c-help elevation-3 white pa-2"
    offset-overflow
    offset-x
    offset-y
    right
    top
  >
    <slot />
    <template #activator="{ on }">
      <v-btn
        icon
        small
        v-on="on"
      >
        <v-icon>{{ icons.mdiHelpCircleOutline }}</v-icon>
      </v-btn>
    </template>
  </v-menu>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { mdiHelpCircleOutline } from '@mdi/js'
import { Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  readonly icons = {
    mdiHelpCircleOutline,
  }
  @Prop({ default: false })
  readonly absolute?: boolean
}
</script>
<style lang="scss" scoped>
.c- {
  &help {
    max-width: 280px;
  }
}
</style>
