<template>
  <v-dialog
    v-model="show"
    :max-width="width"
  >
    <v-form @submit.stop.prevent="submit">
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          {{ title }}
        </v-card-title>
        <v-card-text
          v-if="!isNew"
          class="pa-4"
        >
          <v-alert
            class="ma-0"
            outlined
            type="warning"
          >
            メンバーに設定されている部署名が変更されます。<br>
            設定中のメンバーの部署名を変更したくない場合には、部署の新規作成をご利用ください。
          </v-alert>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-text-field
            v-model="department.name"
            v-validate="'required|max:120'"
            :disabled="isLoading"
            :error-messages="errors.collect('department.name')"
            :label="$t('department.name')"
            autocomplete="off"
            data-vv-name="department.name"
            required
            type="text"
          />
        </v-card-text>
        <v-card-text class="pt-0">
          <v-autocomplete
            v-model="department.users"
            :disabled="isLoading"
            :error-messages="errors.collect('department.users')"
            :items="users"
            :label="$t('department.users')"
            :loading="isLoading"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            :search-input.sync="searchUser"
            data-vv-name="department.users"
            hide-no-data
            item-text="displayName"
            multiple
            return-object
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click.stop="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="isSaving"
            :loading="isSaving"
            color="primary"
            text
            type="submit"
          >
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { Collection, Record } from '@vuex-orm/core'
import Department from '@/store/models/Department'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import notification from '@/utils/notification'
import { User } from '@/store/models/Person'
import _map from 'lodash/map'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly item?: Record
  isSaving = false
  department: Record = { name: '', users: [] }
  searchUser = ''
  isLoading = false

  get width(): string {
    if (this.$vuetify.breakpoint.mdAndUp) return '600px'
    return '90%'
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  get isNew(): boolean {
    return _get(this.item, 'id', -1) < 0
  }

  get title(): string {
    const target = this.$t('label.departments')
    return this.isNew
      ? (this.$t('dialog.title.new', { target }) as string)
      : (this.$t('dialog.title.edit', { target }) as string)
  }

  get users(): Record[] {
    return this.createUserItems(
      User.query()
        .with(['department'])
        .where((record) => record.id > 0)
        .get(),
    )
  }

  createUserItems(users: Collection<User>): {
    id: number
    displayName: string,
  }[] {
    return users.map(({ id, displayName }) => {
      return {
        id,
        displayName,
      }
    })
  }

  @Emit()
  input(value): boolean {
    return value
  }

  @Watch('value')
  async onChangeValue(): Promise<void> {
    if (!this.value) return
    this.isLoading = true
    this.department = this.isNew ? { name: '' } : _cloneDeep(this.item)
    await User.fetchAll({
      params: {
        'with_unaccepted': true,
        'with_deleted': true,
        'with_unapproved': true,
      },
      useCache: false,
    })
    this.department.users = this.createUserItems(
      User.query().with(['department']).where('department_id', this.department.id).get(),
    )
    this.isLoading = false
  }

  async post(): Promise<void> {
    this.isSaving = true
    const department = {
      name: this.department.name,
      users: _map(this.department.users, ({ id }) => ({ id })),
    }
    const { data } = await this.$http.post('departments', department)
    Department.insertOrUpdate({ data })
    notification.success(this.$t('message.created') as string)
    this.isSaving = false
  }

  async patch(): Promise<void> {
    this.isSaving = true
    const department = {
      name: this.department.name,
      users: _map(this.department.users, ({ id }) => ({ id })),
    }
    const { data } = await this.$http.patch(['departments', this.department.id].join('/'), department)
    Department.insertOrUpdate({ data })
    notification.success(this.$t('message.updated') as string)
    this.isSaving = false
  }

  cancel(): void {
    this.show = false
    this.reset()
  }

  @Emit('submit')
  async submit(): Promise<void> {
    if (this.isNew) {
      await this.post()
    } else {
      await this.patch()
    }
    User.fetchAll({
      params: {
        'q[department_id_eq]': this.department.id,
        'with_unaccepted': true,
        'with_deleted': true,
        'with_unapproved': true,
      },
      useCache: false,
    })
    this.show = false
    this.reset()
  }

  reset(): void {
    this.$validator.reset()
    this.department = { name: '' }
  }
}
</script>
