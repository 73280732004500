<template>
  <MyContainer>
    <template #title>
      契約内容
    </template>
    <MyContent>
      <AgreementVerification />
    </MyContent>
  </MyContainer>
</template>
<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import AgreementVerification from '@/components/organisms/agreements/AgreementVerification.vue'
import MyContainer from '@/components/atoms/MyContainer.vue'
import PointSettings from '@/components/organisms/tenants/PointSettings.vue'
import MyContent from '@/components/atoms/MyContent.vue'

@Component({
  components: {
    MyContent,
    MyContainer,
    PointSettings,
    AgreementVerification,
  },
})
export default class extends Vue {
}
</script>
