<template>
  <v-list-item
    :class="`p-award-list-item--${rank}`"
    class="p-award-list-item"
  >
    <v-list-item-content class="mr-4 p-award-list-item__rank">
      <v-avatar size="38">
        <img :src="rankImages[rank]">
      </v-avatar>
    </v-list-item-content>
    <v-list-item-avatar
      class="mr-2"
      size="24"
    >
      <img :src="image">
    </v-list-item-avatar>
    <v-list-item-content class="p-award-list-item__name">
      {{ name }}
    </v-list-item-content>
    <v-list-item-content class="p-award-list-item__value">
      {{ count }}
    </v-list-item-content>
  </v-list-item>
</template>
<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import rank1 from '@/img/rank1.png'
import rank2 from '@/img/rank2.png'
import rank3 from '@/img/rank3.png'
import rank4 from '@/img/rank4.png'
import rank5 from '@/img/rank5.png'

@Component
export default class extends Vue {
  rankImages = {
    1: rank1,
    2: rank2,
    3: rank3,
    4: rank4,
    5: rank5,
  }
  @Prop()
  readonly rank!: number
  @Prop()
  readonly image!: string
  @Prop()
  readonly name!: string
  @Prop()
  readonly count!: number
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/variables';

.p-award-list-item {
  &__rank {
    flex: 0 0 38px;
    justify-content: center;
    width: 38px;
    padding: 0;

    @media #{map-get($display-breakpoints, 'sm-only')} {
      margin-left: 5%;
    }

    @media #{map-get($display-breakpoints, 'lg-only')} {
      margin-left: 5%;
    }

    @media #{map-get($display-breakpoints, 'xl-only')} {
      margin-left: 10%;
    }

    // noinspection CssInvalidPseudoSelector
    :deep(.v-avatar) {
      flex: 0;
      background-color: transparent;
    }
  }

  &__name {
    flex-grow: 2;
    overflow: auto;
    white-space: nowrap;
  }

  &__value {
    justify-content: right;
    font-size: 1rem;

    @media #{map-get($display-breakpoints, 'sm-only')} {
      margin-right: 5%;
    }

    @media #{map-get($display-breakpoints, 'lg-only')} {
      margin-right: 5%;
    }

    @media #{map-get($display-breakpoints, 'xl-only')} {
      margin-right: 10%;
    }
  }
}
</style>
