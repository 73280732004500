<template>
  <v-list class="p-award-list">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar"
    />
    <template v-else>
      <AwardListItem
        v-for="{ user, count, rank } in items"
        :key="user?.id"
        :count="count"
        :image="user?.avatarImage"
        :name="user?.name ? user?.displayName : user?.email"
        :rank="rank"
      />
    </template>
  </v-list>
</template>
<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import AwardListItem from '@/components/organisms/award/AwardListItem.vue'

export type Item = {
  user: Record
  count: number
  rank: number
}
@Component({
  components: {
    AwardListItem,
  },
})
export default class extends Vue {
  @Prop()
  readonly items!: {
    user: Record
    count: number
    rank: number
  }[]
  @Prop()
  readonly loading!: boolean
}
</script>
