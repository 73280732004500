<template>
  <v-card>
    <v-card-title class="text-h5">
      メンバー登録URL
    </v-card-title>
    <v-card-text>
      <v-text-field
        :append-icon="icons.mdiClipboardTextOutline"
        :value="url"
        outlined
        readonly
        type="text"
        @click="$event.target.select()"
        @click:append="copy"
      />
      <img
        :src="qrcode"
        width="200"
      >
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { mdiClipboardTextOutline } from '@mdi/js'
import notification from '@/utils/notification'
import qrcode, { QRCodeRenderersOptions } from 'qrcode'
import logo from '@/img/logo.png'

@Component
export default class extends Vue {
  icons = { mdiClipboardTextOutline }
  qrcode = ''

  get url(): string {
    return location.origin + '/sign_up'
  }

  mounted(): void {
    const canvas = document.createElement('canvas')
    const icon = new Image()
    icon.onload = () => {
      const options: QRCodeRenderersOptions = {
        width: icon.width * 4,
        margin: 2,
      }
      qrcode.toCanvas(canvas, this.url, options, () => {
        const ctx = canvas.getContext('2d')
        const left = Math.floor((canvas.width - icon.width) / 2)
        const top = Math.floor((canvas.height - icon.height) / 2)
        ctx.drawImage(icon, left, top)
        this.qrcode = canvas.toDataURL('image/png', 1)
      })
    }
    icon.src = logo
  }

  copy(): void {
    navigator.clipboard.writeText(this.url).then(() => {
      notification.info(this.$t('message.copied') as string)
    })
  }
}
</script>
