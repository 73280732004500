<template>
  <div class="p-correlation-diagram">
    <v-progress-circular
      v-show="loading"
      :indeterminate="loading"
      :size="50"
      class="mx-auto align-self-center"
      color="primary"
    />
    <div
      v-show="!loading"
      class="p-correlation-diagram__diagram"
    >
      <div
        ref="diagram"
        class="p-correlation-diagram__diagram__content"
      />
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { CorrelationDiagramValue } from './interfaces'
import cytoscape, { EdgeDefinition, NodeDefinition } from 'cytoscape'
import _map from 'lodash/map'
import d3Force from 'cytoscape-d3-force'
import _forEach from 'lodash/forEach'
import { Prop } from 'vue-property-decorator'

cytoscape.use(d3Force)
@Component
export default class CorrelationDiagram extends Vue {
  $refs!: {
    diagram: HTMLDivElement
  }
  @Prop({ default: false })
  loading!: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cytoscape: any
  timeoutDiagram = 0
  idDestroyed = false

  beforeDestroy(): void {
    this.idDestroyed = true
  }

  clearTimeoutDiagram(): void {
    clearInterval(this.timeoutDiagram)
    this.timeoutDiagram = 0
  }

  isInView(): boolean {
    try {
      const rect = this.$refs.diagram.getBoundingClientRect()
      return 0 < rect.bottom && rect.top < window.innerHeight
    } catch (_e) {
      return false
    }
  }

  async draw(data: CorrelationDiagramValue, markerStart = false, markerEnd = false): Promise<void> {
    const nodes: NodeDefinition[] = _map(data.nodes, (node) => {
      return {
        data: {
          ...node,
          id: `n${node.id}`,
        },
      }
    })
    const edges: EdgeDefinition[] = _map(data.links, (link, idx) => {
      return {
        data: {
          ...link,
          source: `n${link.source}`,
          target: `n${link.target}`,
          id: `e${idx}`,
        },
      }
    })
    const fetchImage = async (url): Promise<string | ArrayBuffer | null> => {
      const { data } = await this.$http.get(url, { responseType: 'blob' })
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.readAsDataURL(data)
      })
    }
    // NOTE: this.$refs.diagramが表示されるまで待つ
    const { cancel } = await new Promise<{ cancel: boolean }>((resolve) => {
      this.timeoutDiagram = window.setInterval(() => {
        if (this.idDestroyed) {
          this.clearTimeoutDiagram()
          resolve({ cancel: true })
        }
        if (this.isInView()) {
          this.clearTimeoutDiagram()
          resolve({ cancel: false })
        }
      }, 100)
    })
    if (cancel) return
    this.cytoscape = cytoscape({
      container: this.$refs.diagram,
      ready() {
        _forEach(this.nodes(), async (node) => {
          const { data } = node.json()
          let image = data.image
          const pattern = '^' + `${location.protocol}//${location.host}`.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&')
          if (new RegExp(pattern).test(image)) {
            // HACK: ActionStorageがS3へリダイレクトしているせいかブラウザーのキャッシュが聞いた場合にエラーになってしまう
            image = await fetchImage(`${image}?${new Date().getTime()}`)
          }
          node.css({
            width: `${4 * data.radius}`,
            height: `${4 * data.radius}`,
            'background-image': image,
            'background-fit': 'contain',
          })
        })
        _forEach(this.edges(), (edge) => {
          const { data } = edge.json()
          edge.css({
            width: data.width,
            'line-color': data.color,
            'source-arrow-color': data.color,
            'target-arrow-color': data.color,
          })
        })
      },
      layout: {
        name: 'd3-force',
        animate: true,
        velocityDecay: 0.2,
        linkStrength: 0.01,
        linkId: (d) => d.id,
        manyBodyStrength: -500,
        collideRadius: (d) => d.radius,
        xStrength: 0.04,
        yStrength: 0.04,
        randomize: true,
        infinite: true,
      },
      style: [
        {
          selector: 'node[name]',
          style: {
            'background-color': '#efefef',
            'border-color': '#efefef',
            'border-width': 1,
            label: 'data(name)',
            'text-valign': 'bottom',
            'text-halign': 'center',
            'text-margin-y': '8px',
          },
        },
        {
          selector: 'edge',
          style: {
            'curve-style': 'unbundled-bezier',
            'source-arrow-shape': markerStart ? 'triangle' : 'none',
            'target-arrow-shape': markerEnd ? 'triangle' : 'none',
          },
        },
      ],
      elements: {
        nodes,
        edges,
      },
    })
  }
}
</script>
<style lang="scss" scoped>
.p-correlation-diagram {
  display: flex;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  text-align: center;

  &__diagram {
    position: relative;
    width: 100%;
    height: 100%;

    &__content {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: left;
    }
  }
}
</style>
