<template>
  <v-row
    class="ma-0 pa-0"
  >
    <v-col
      v-for="(file, index) in files"
      :key="index"
      cols="auto"
    >
      <v-btn
        :min-width="size"
        class="pa-0"
        height="auto"
        outlined
        @click.capture.stop="open(file)"
      >
        <v-img
          :height="size"
          :src="_.get(file, 'url')"
          :style="{ aspectRatio: aspectRatios[index] }"
          :width="size"
          class="transparent rounded"
          contain
          @load="load(index, $event)"
        >
          <template #placeholder>
            <v-row
              align="center"
              class="ma-0 pa-0 fill-height"
              justify="center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
              />
            </v-row>
          </template>
        </v-img>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'

const ImagePreview = namespace('imagePreview')
@Component
export default class extends Vue {
  @Prop({ default: [] })
  readonly files!: Record[]
  @Prop({ default: 36 })
  readonly size?: number | string
  @Prop({ default: false })
  readonly isMine?: boolean
  aspectRatios: number[] = []
  @ImagePreview.Action('show') show

  open(file): void {
    const options: { file: Record; onDelete?: () => void } = { file }
    if (this.isMine) {
      options.onDelete = () => this.delete(file)
    }
    this.show(options)
  }

  load(index, url): void {
    const img = new Image()
    img.addEventListener('load', () => {
      this.$set(this.aspectRatios, index, img.width / img.height)
      img.remove()
    })
    img.src = url
  }

  @Emit('delete')
  delete(file): Record {
    return file
  }

  mounted(): void {
    this.aspectRatios = this.files.map(() => 1)
  }
}
</script>
