<template>
  <div>
    <h3>{{ label }}</h3>
    <SelectDate
      :loading="loading"
      :value="dates"
      @input="changeDateRange"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import SelectCategory from '@/components/organisms/categories/SelectCategory.vue'
import SelectDate from '@/components/organisms/date/SelectDate.vue'

@Component({
  components: { SelectDate, SelectCategory },
})
export default class extends Vue {
  @Prop({ default: '期間' })
  readonly label?: string

  @Prop({ default: null })
  readonly value?: [string, string]

  @Prop({ default: false })
  readonly loading?: boolean

  get dates(): [string, string] {
    return this.value
  }

  @Emit('input')
  changeDateRange(value: [string, string]): [string, string] {
    return value
  }
}
</script>
