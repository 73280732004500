<template>
  <InfiniteScroll
    v-visibility-change="visibilityChange"
    :loading="loading"
    two-line
    @infinite="fetch"
  >
    <template v-for="item of tweets">
      <TweetListItem
        :key="item.id"
        :commentable="true"
        :disabled="isEditing"
        :value="item"
        @finishEditing="isEditing = false"
        @startEditing="isEditing = true"
      />
    </template>
    <v-list-item v-if="!loading && !exists">
      <v-list-item-content>
        <v-container
          class="d-flex justify"
          fluid
        >
          <div>
            つぶやきはまだありません。
            <template v-if="isOwn">
              早速つぶやこう！
            </template>
          </div>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </InfiniteScroll>
</template>
<script lang="ts">
import Vue from 'vue'
import _get from 'lodash/get'
import { defaultFeeling } from '@/store/models/Feeling'
import TweetListItem from '@/components/organisms/tweets/TweetListItem.vue'
import Tweet from '@/store/models/Tweet'
import Component from 'vue-class-component'
import Me from '@/store/models/Me'
import { Record } from '@vuex-orm/core'
import InfiniteScroll from '@/components/organisms/InfiniteScroll.vue'
import _isNil from 'lodash/isNil'
import visibilityChange from '@/directives/visibilityChange'
import { Watch } from 'vue-property-decorator'
import { RansackParams } from '@/utils/http'

@Component({
  components: {
    TweetListItem,
    InfiniteScroll,
  },
  directives: {
    visibilityChange,
  },
  $_veeValidate: {
    validator: 'new',
  },
  metaInfo() {
    return {
      title: 'つぶやき',
    }
  },
})
export default class extends Vue {
  readonly defaultFeeling = defaultFeeling
  isEditing = false
  loading = true
  readonly limit = 25
  page = 0

  get currentUser(): Record {
    return Me.query().first()
  }

  get userId(): number {
    return +this.$route.params.id
  }

  get isOwn(): boolean {
    return this.userId === _get(this.currentUser, 'id')
  }

  get exists(): boolean {
    return !_isNil(this.tweets) && this.tweets.length > 0
  }

  get tweets(): Record[] {
    return Tweet.query().with(['user', 'feeling']).where('user_id', this.userId).orderBy('created_at', 'desc').get()
  }

  get searchParams(): Partial<RansackParams> {
    return {
      page: this.page + 1,
      limit: this.limit,
    }
  }

  mounted(): void {
    this.page = 0
    this.fetch()
  }

  @Watch('userId')
  changeUserId(): void {
    this.page = 0
    this.fetch()
  }

  fetch({ useCache } = { useCache: true }): void {
    this.loading = true
    Tweet.fetch({
      url: ['users', this.userId, 'tweets'].join('/'),
      params: this.searchParams,
      useCache,
    })
      .then(() => {
        this.page = Math.floor(this.tweets.length / this.limit)
      })
      .finally(() => {
        this.loading = false
      })
  }

  visibilityChange(visible): void {
    if (visible && !this.loading) {
      this.page = 0
      this.fetch({ useCache: false })
    }
  }
}
</script>
