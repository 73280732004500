<template>
  <v-dialog
    v-model="show"
    :max-width="width"
  >
    <v-form @submit.stop.prevent="submit">
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          <code>{{ _.get(category, 'name') }}</code>を削除します。
        </v-card-title>

        <v-card-text class="pa-4">
          <v-alert
            class="ma-0"
            outlined
            type="warning"
          >
            過去につけられた感謝タグが全て削除されます。<br>
            本当に削除しますか？
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click.stop="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="isDeleting"
            :loading="isDeleting"
            color="error"
            text
            type="submit"
          >
            {{ $t('button.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Category from '@/store/models/Category'
import notification from '@/utils/notification'

@Component
export default class DeleteCategoryDialogComponent extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly item!: Record
  isDeleting = false

  get width(): string {
    if (this.$vuetify.breakpoint.mdAndUp) return '600px'
    return '90%'
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  get category(): Record {
    return this.item
  }

  @Emit()
  input(value): boolean {
    return value
  }

  async delete(): Promise<void> {
    this.isDeleting = true
    if (!(await this.$validator.validateAll())) {
      this.isDeleting = false
      return
    }
    await this.$http.delete(['categories', this.category.id].join('/'))
    Category.delete(this.category.id)
    notification.success(this.$t('message.deleted') as string)
    this.isDeleting = false
  }

  cancel(): void {
    this.show = false
  }

  @Emit('submit')
  async submit(): Promise<void> {
    await this.delete()
    this.show = false
  }
}
</script>
