<template>
  <v-carousel
    :height="height"
    :show-arrows="false"
    class="c-wordpress-articles"
    cycle
    hide-delimiter-background
    hide-delimiters
    show-arrows-on-hover
  >
    <v-carousel-item
      v-for="(item, idx) in items"
      :key="idx"
      :href="item.link"
      class="c-wordpress-article rounded"
      rel="noopener"
      target="_blank"
    >
      <div
        :style="{ height: `${height}px` }"
        class="d-flex flex-column overflow-hidden rounded grey lighten-3"
      >
        <img
          :height="imgHeight"
          :src="item.src"
          alt=""
          class="c-wordpress-article__image flex-grow-0 rounded"
        >
        <div class="c-wordpress-article__title">
          <span v-text="item.title" />
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import Axios, { AxiosInstance } from 'axios'
import { Prop } from 'vue-property-decorator'
import { WP_REST_API_Post as WpPost } from 'wp-types'

@Component
export default class extends Vue {
  @Prop({ default: null })
  domain!: string
  @Prop({ default: 400 })
  height?: number
  data: WpPost[] = []

  get items(): {
    title: string
    link: string
    src: string
  }[] {
    return this.data.map(({ title, link, _embedded: { 'wp:featuredmedia': media } }) => {
      return {
        title: title.rendered,
        link,
        src: media.length > 0 ? media[0]['source_url'] : '',
      }
    })
  }

  get http(): AxiosInstance {
    return Axios.create({ baseURL: `https://${this.domain}/wp-json` })
  }

  get width(): number {
    const rect = this.$el.getBoundingClientRect()
    return rect.width
  }

  get imgHeight(): number {
    return this.width / (16 / 9)
  }

  get titleStyle(): Partial<CSSStyleDeclaration> {
    return {
      height: `${this.height - this.imgHeight}px`,
    }
  }

  async mounted(): Promise<void> {
    try {
      const { data } = await this.http.get('/wp/v2/posts?_embed&per_page=5&page=1')
      this.data = data
    } catch (e) {
      console.error(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-wordpress-articles {
  position: static;
  width: 100%;

  .c-wordpress-article {
    // noinspection CssInvalidPseudoSelector
    :deep(*) {
      color: inherit;
      background-color: inherit;
    }

    color: rgb(25, 25, 25);

    &:hover {
      color: rgb(231, 119, 129);
    }

    &__image {
      object-fit: cover;
    }

    &__title {
      padding: 12px;
      overflow: hidden;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.3;

      > span {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        -webkit-line-clamp: 4;
      }
    }
  }
}
</style>
