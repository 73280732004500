<template>
  <v-container class="ml-2">
    <slot name="before" />
    <v-row class="ma-0 pa-0">
      <v-col
        lg="6"
        md="12"
        sm="12"
        xl="6"
      >
        <DateRangeFilter
          :loading="loading"
          :value="dates"
          @input="changeDateRange"
        />
      </v-col>
      <v-col
        lg="6"
        md="12"
        sm="12"
        xl="6"
      >
        <CategoryFilter
          :loading="loading"
          :value="category"
          @input="changeCategory"
        />
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col
        lg="6"
        md="12"
        sm="12"
        xl="6"
      >
        <DepartmentFilter
          :loading="loading"
          @input="changeDepartment"
        />
      </v-col>
      <v-col
        lg="6"
        md="12"
        sm="12"
        xl="6"
      >
        <TagFilter
          :loading="loading"
          :value="tag"
          @input="changeTag"
        />
      </v-col>
    </v-row>
    <slot name="after" />
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import _isNil from 'lodash/isNil'
import { Record } from '@vuex-orm/core'
import Department from '@/store/models/Department'
import { UserTag } from '@/store/models/Tag'
import UserStatusFilter from '@/components/organisms/UserStatusFilter.vue'
import DepartmentFilter from '@/components/organisms/DepartmentFilter.vue'
import TagFilter from '@/components/organisms/TagFilter.vue'
import DateRangeFilter from '@/components/organisms/DateRangeFilter.vue'
import CategoryFilter from '@/components/organisms/CategoryFilter.vue'

export interface SearchValue {
  from: string
  to: string
  category: number
  tag: string | string[]
  department: number
}

@Component({
  name: 'search-condition',
  components: {
    CategoryFilter,
    DateRangeFilter,
    TagFilter,
    DepartmentFilter,
    UserStatusFilter,
  },
})
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: Partial<SearchValue>
  @Prop({ default: true })
  readonly loading: boolean

  get dates(): string[] {
    if (_isNil(this.value)) return []
    return [this.$moment(this.value.from).format('YYYY-MM-DD'), this.$moment(this.value.to).format('YYYY-MM-DD')]
  }

  set dates([from, to]: string[]) {
    this.input({
      from,
      to,
      category: this.category,
      tag: this.tag,
      department: this.department,
    })
  }

  get category(): number {
    return this.value.category
  }

  set category(category: number) {
    this.input({
      from: this.dates[0],
      to: this.dates[1],
      category,
      tag: this.tag,
      department: this.department,
    })
  }

  get tag(): string | string[] {
    return this.value.tag
  }

  set tag(tag: string | string[]) {
    this.input({
      from: this.dates[0],
      to: this.dates[1],
      category: this.category,
      tag,
      department: this.department,
    })
  }

  get department(): number {
    return this.value.department
  }

  set department(department: number) {
    this.input({
      from: this.dates[0],
      to: this.dates[1],
      category: this.category,
      tag: this.tag,
      department,
    })
  }

  get departments(): Record[] {
    return Department.query().orderBy('display_order', 'asc').all()
  }

  changeDateRange(value: string[]): void {
    this.dates = value
  }

  changeCategory(value: number): void {
    this.category = value
  }

  changeTag(value: string[]): void {
    this.tag = value
  }

  changeDepartment(value: Record): void {
    this.department = value?.id
  }

  @Emit('input')
  input(value: SearchValue): SearchValue {
    return value
  }

  async mounted(): Promise<void> {
    await Promise.all([UserTag.fetchAll({ useCache: false }), Department.fetchAll({ useCache: false })])
  }
}
</script>
