<template>
  <v-form
    v-model="isValid"
    :disabled="isInviting"
    @submit.stop.prevent="invite"
  >
    <v-card>
      <v-card-title class="text-h5">
        メンバー招待
      </v-card-title>
      <v-card-text>
        新規メンバーを作成する場合は招待が必要です。招待するメンバーのメールアドレスを入力し、「招待する」ボタンを押してください。
      </v-card-text>
      <v-card-text v-if="messages.length >0">
        <v-alert
          :value="messages.length >0"
          color="error"
          dark
          dismissible
        >
          <div
            v-for="(message, index) in messages"
            :key="index"
          >
            {{ message }}
          </div>
        </v-alert>
      </v-card-text>
      <v-card-text v-if="overflow">
        <v-alert
          v-model="overflow"
          color="warning"
          dismissible
          outlined
          prominent
          text
        >
          <v-row
            align="center"
            class="ma-0 pa-0"
          >
            <v-col class="grow">
              {{ $t('message.user.overflow') }}
            </v-col>
            <v-col class="shrink">
              <v-btn
                :to="{ name: 'agreements', hash: '#plan' }"
                color="warning"
              >
                {{ $t('button.editPlan') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
      <v-card-text>
        <v-combobox
          v-model="emails"
          v-validate="'required|email'"
          :error-messages="errors.collect('user.email')"
          :label="$t('user.email')"
          :prepend-icon="icons.mdiEmail"
          append-icon=""
          clearable
          counter
          data-vv-name="user.email"
          deletable-chips
          hide-no-data
          hide-selected
          multiple
          required
          small-chips
          type="email"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="cancelLabel"
          @click="cancel"
        >
          {{ cancelLabel }}
        </v-btn>
        <v-btn
          v-if="backLabel"
          @click="back"
        >
          {{ backLabel }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="skipLabel"
          @click="skip"
        >
          {{ skipLabel }}
        </v-btn>
        <v-btn
          :disabled="emails.length === 0 || isInviting"
          :loading="isInviting"
          color="primary"
          type="submit"
        >
          {{ inviteLabel ? inviteLabel : '招待' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import _isNil from 'lodash/isNil'
import _chunk from 'lodash/chunk'
import _map from 'lodash/map'
import Axios from 'axios'
import notification from '@/utils/notification'
import { Emit, Prop } from 'vue-property-decorator'
import { User } from '@/store/models/Person'
import { downloadCsv } from '@/utils/download'
import { mdiDownload, mdiEmail, mdiUpload } from '@mdi/js'
import _filter from 'lodash/filter'
import _indexOf from 'lodash/indexOf'
import _find from 'lodash/find'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class InviteMemberComponent extends Vue {
  $refs!: {
    csvFile: HTMLInputElement
  }
  @Prop({ default: null })
  inviteLabel?: string
  @Prop({ default: null })
  skipLabel?: string
  @Prop({ default: null })
  backLabel?: string
  @Prop({ default: null })
  cancelLabel?: string
  readonly icons = {
    mdiDownload,
    mdiUpload,
    mdiEmail,
  }
  isInviting = false
  isValid = false
  emails: string[] = []
  messages = []
  overflow = false

  download(): void {
    downloadCsv(['member1@example.com', 'member2@example.com'].join(String.fromCharCode(13, 10)), 'emails.csv')
  }

  upload(): void {
    this.$refs.csvFile.click()
  }

  select(event: Event): void {
    const { files } = event.target as HTMLInputElement
    if (files.length === 0 || _isNil(files[0])) {
      return
    }
    const reader = new FileReader()
    reader.onload = () => {
      this.emails = _map((reader.result as string).split(/\r?\n/), (row) => {
        return row.split(',')[0]
      })
    }
    reader.readAsText(files[0])
  }

  async invite(): Promise<void> {
    this.isInviting = true
    this.messages = []
    if (!(await this.$validator.validateAll())) {
      this.isInviting = false
      return
    }
    let status = -1
    for (const emails of _chunk(this.emails, 5)) {
      const responses = await Axios.all(
        _map(emails, (email) => {
          return (
            this.$http
              .post('invitations', {
                invitation: { email },
              })
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .catch((err: any) => {
                status = status === 200 ? status : err.response.status
                return {
                  ...err,
                  data: err.response.data,
                  status: err.response.status,
                  statusText: err.response.statusText,
                }
              })
          )
        }),
      )
      const successes = _filter(responses, { status: 200 })
      const unprocessableEntities = _filter(responses, { status: 422 })
      const internalServerErrors = _filter(responses, { status: 500 })
      User.insertOrUpdate({
        data: _map(successes, ({ data }) => data),
      })
      if (unprocessableEntities.length > 0) {
        const data = _map(unprocessableEntities, ({ data }) => data)
        if (!_isNil(_find(data, (val) => _indexOf(val, 'User::OverflowError') !== -1))) {
          this.overflow = true
        } else {
          this.messages = data
        }
        break
      }
      if (internalServerErrors.length > 0) {
        break
      }
    }
    this.emails = []
    this.$validator.reset()
    this.isInviting = false
    if (status === 200) {
      notification.success(this.$t('message.invited') as string)
    }
    this.invited()
  }

  @Emit('invited')
  invited(): void {
    return void 0
  }

  @Emit('skip')
  skip(): void {
    return void 0
  }

  @Emit('back')
  back(): void {
    return void 0
  }

  @Emit('cancel')
  cancel(): void {
    return void 0
  }
}
</script>
<style lang="scss" scoped>
input[type='file'] {
  width: 0;
  max-width: 0;
  opacity: 0;
}
</style>
