<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :value="value"
    class="p-profile"
    max-width="580"
    @input="changeDialog"
  >
    <template #activator="{ on }">
      <slot
        :on="on"
        name="activator"
      />
    </template>
    <template #default="dialog">
      <v-form
        v-model="valid"
        :loading="loading"
        class="fill-height"
        @submit.stop.prevent="save(dialog)"
      >
        <v-card
          class="d-flex flex-column fill-height c-modal"
          flat
        >
          <v-card-title
            class="text-h5 grey lighten-2 c-modal__header"
            primary-title
          >
            プロフィールを編集
          </v-card-title>
          <div class="p-profile__header">
            <v-avatar
              class="p-profile__avatar"
              size="100px"
            >
              <v-img :src="data.avatarImage">
                <template #placeholder>
                  <v-row
                    align="center"
                    class="ma-0 pa-0 fill-height"
                    justify="center"
                  >
                    <v-progress-circular
                      color="primary"
                      indeterminate
                    />
                  </v-row>
                </template>
              </v-img>
              <SelectImageDialog
                :height="200"
                :width="200"
                max-width="420px"
                rounded
                title="アバター画像"
                @select="selectImage('avatar', $event)"
              >
                <template #activator="{ on }">
                  <v-overlay
                    absolute
                    opacity="0.46"
                  >
                    <v-btn
                      class="p-profile__avatarButton"
                      dark
                      icon
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiCamera }}</v-icon>
                    </v-btn>
                  </v-overlay>
                </template>
              </SelectImageDialog>
            </v-avatar>
            <v-responsive
              aspect-ratio="3/1"
              max-height="200px"
              min-height="120px"
            >
              <v-img
                :src="data.coverImage"
                max-height="200px"
                min-height="120px"
              >
                <template #placeholder>
                  <v-row
                    align="center"
                    class="ma-0 pa-0 fill-height ma-0"
                    justify="center"
                  >
                    <v-progress-circular
                      color="primary"
                      indeterminate
                    />
                  </v-row>
                </template>
              </v-img>
              <div class="p-profile__coverActions">
                <v-btn
                  v-show="data.cover"
                  class="p-profile__coverButton mr-1"
                  dark
                  icon
                  @click="resetImage('cover')"
                >
                  <v-icon>
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
                <SelectImageDialog
                  :height="200"
                  :width="600"
                  max-width="600px"
                  title="背景画像"
                  @select="selectImage('cover', $event)"
                >
                  <template #activator="{ on }">
                    <v-btn
                      class="p-profile__coverButton"
                      dark
                      icon
                      v-on="on"
                    >
                      <v-icon dark>
                        {{ icons.mdiCamera }}
                      </v-icon>
                    </v-btn>
                  </template>
                </SelectImageDialog>
              </div>
            </v-responsive>
          </div>
          <v-card-text class="flex-grow-1">
            <v-card-text>
              <v-text-field
                v-model="data.name"
                v-validate="'required|max:200'"
                :error-messages="errors.collect('userName')"
                :label="$t('userName')"
                autocomplete="off"
                data-vv-name="userName"
                required
              />
              <v-textarea
                v-model="data.description"
                v-validate="'max:800'"
                :error-messages="errors.collect('userDescription')"
                :label="$t('userDescription')"
                autocomplete="off"
                data-vv-name="userDescription"
              />
            </v-card-text>
          </v-card-text>
          <v-spacer />
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="loading"
              text
              @click="close(dialog)"
            >
              {{ $t('button.cancel') }}
            </v-btn>
            <v-btn
              :disabled="loading || invalid"
              :loading="loading"
              color="primary"
              text
              type="submit"
            >
              {{ $t('button.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Me from '@/store/models/Me'
import { Record } from '@vuex-orm/core'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import SelectImageDialog from '@/components/organisms/images/SelectImageDialog.vue'
import { mdiCamera, mdiDelete } from '@mdi/js'

interface SelectedFile {
  file: File
  changed: boolean
}

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    SelectImageDialog,
  },
})
export default class EditProfileDialog extends Vue {
  readonly icons = { mdiCamera, mdiDelete }
  @Prop({ default: null })
  readonly value?: boolean
  data: Record = {}
  valid = false
  loading = false
  images: {
    cover: SelectedFile
    avatar: SelectedFile
  } = {
    cover: { file: null, changed: false },
    avatar: { file: null, changed: false },
  }
  private _internalValue = false

  get invalid(): boolean {
    if (this.data.name === '') return true
    return !this.valid
  }

  get my(): Record {
    return Me.query().first()
  }

  @Emit()
  input(value): boolean {
    this._internalValue = value
    return value
  }

  @Watch('value')
  watchValue(): void {
    this.changeDialog(this.value)
  }

  changeDialog(value): void {
    if (this._internalValue === value) return
    if (value) {
      this.fetch()
    }
    this.input(value)
  }

  fetch(): void {
    this.data = Me.query().first() as object
  }

  selectImage(prop, { file, dataUrl }): void {
    this.$set(this.images, prop, { file, changed: true })
    this.$set(this.data, prop, dataUrl)
  }

  resetImage(prop): void {
    const image: SelectedFile = {
      file: null,
      changed: true,
    }
    let data = null
    if (this.images[prop].changed) {
      image.changed = false
      data = this.my[prop]
    }
    this.$set(this.images, prop, image)
    this.$set(this.data, prop, data)
  }

  async update(): Promise<void> {
    this.loading = true
    const { data } = await this.$http.patch('me', {
      user: this.data,
    })
    this.loading = false
    Me.insertOrUpdate({ data })
  }

  close(dialog): void {
    this.reset()
    dialog.value = false
  }

  async save(dialog): Promise<void> {
    if (!(await this.$validator.validateAll())) {
      return
    }
    this.loading = true
    const uploads = []
    for (const prop in this.images) {
      const image = this.images[prop]
      if (!image.changed) continue
      if (image.file) {
        uploads.push(this.uploadImage(prop, image.file))
      } else {
        uploads.push(this.deleteImage(prop))
      }
    }
    Promise.all(uploads)
      .then(() => this.update())
      .then(() => {
        this.close(dialog)
      })
      .finally(() => {
        this.loading = false
      })
  }

  reset(): void {
    this.$validator.pause()
    this.$validator.reset().then(() => this.$validator.resume())
    this.data = {}
    this.images = {
      cover: { file: null, changed: false },
      avatar: { file: null, changed: false },
    }
  }

  async uploadImage(prop: string, file: File): Promise<void> {
    const formData = new FormData()
    formData.append(`user[${prop}]`, file)
    const { data } = await this.$http.put(['my', prop].join('/'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    Me.insertOrUpdate({ data })
  }

  async deleteImage(prop: string): Promise<void> {
    const { data } = await this.$http.delete(['my', prop].join('/'))
    Me.insertOrUpdate({ data })
  }
}
</script>
<style lang="scss" scoped>
.p-profile {
  &__header {
    position: relative;
  }

  &__avatar {
    position: absolute;
    bottom: -8px;
    left: 8px;
    z-index: 3;

    &Button {
      z-index: 1;
    }
  }

  &__cover {
    &Actions {
      position: absolute;
      right: 16px;
      bottom: 16px;
    }

    &Button {
      z-index: 1;
      background-color: rgb(120, 120, 120, 60%);
    }
  }
}
</style>
