<template>
  <v-dialog
    v-model="show"
    :max-width="width"
  >
    <v-form @submit.stop.prevent="submit">
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          {{ title }}
        </v-card-title>
        <v-card-text
          v-if="!isNew"
          class="pa-4"
        >
          <v-alert
            class="ma-0"
            outlined
            type="warning"
          >
            過去につけられた感謝タグも全て変更されます。意味が大きく異なる文言に変更してしまうと感謝を贈った方が意図しないものになってしまうかもしれないので注意して変更してください。<br>
            過去の感謝タグを変更したくない場合には、感謝タグの新規作成をご利用ください。
          </v-alert>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-text-field
            v-model="category.name"
            v-validate="'required|max:120'"
            :error-messages="errors.collect('category.name')"
            :label="$t('category.name')"
            autocomplete="off"
            data-vv-name="category.name"
            required
            type="text"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click.stop="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="isSaving"
            :loading="isSaving"
            color="primary"
            text
            type="submit"
          >
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Category from '@/store/models/Category'
import _cloneDeep from 'lodash/cloneDeep'
import _pick from 'lodash/pick'
import _get from 'lodash/get'
import notification from '@/utils/notification'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class EditCategoryDialogComponent extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly item?: Record
  isSaving = false
  category: Record = { name: '' }

  get width(): string {
    if (this.$vuetify.breakpoint.mdAndUp) return '600px'
    return '90%'
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  get isNew(): boolean {
    return _get(this.item, 'id', -1) < 0
  }

  get title(): string {
    const target = this.$t('label.category')
    return this.isNew
      ? (this.$t('dialog.title.new', { target }) as string)
      : (this.$t('dialog.title.edit', { target }) as string)
  }

  @Emit()
  input(value): boolean {
    return value
  }

  @Watch('value')
  onChangeItem(): void {
    if (!this.value) return
    this.category = this.isNew ? { name: '' } : _cloneDeep(this.item)
  }

  async post(): Promise<void> {
    this.isSaving = true
    const { data } = await this.$http.post('categories', _pick(this.category, ['name']))
    Category.insertOrUpdate({ data })
    notification.success(this.$t('message.created') as string)
    this.isSaving = false
  }

  async patch(): Promise<void> {
    this.isSaving = true
    const { data } = await this.$http.patch(['categories', this.category.id].join('/'), _pick(this.category, ['name']))
    Category.insertOrUpdate({ data })
    notification.success(this.$t('message.updated') as string)
    this.isSaving = false
  }

  cancel(): void {
    this.show = false
    this.reset()
  }

  @Emit('submit')
  async submit(): Promise<void> {
    if (this.isNew) {
      await this.post()
    } else {
      await this.patch()
    }
    this.show = false
    this.reset()
  }

  reset(): void {
    this.$validator.reset()
    this.category = { name: '' }
  }
}
</script>
