<template>
  <v-dialog
    v-model="show"
    max-width="600px"
  >
    <RestoreMemberDialog
      v-model="restoreDialog"
      :item="editUser"
      @restored="show = false"
    />
    <SuspendMemberDialog
      v-model="suspendDialog"
      :item="editUser"
      @suspended="show = false"
    />
    <v-form @submit.stop.prevent="submit">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('dialog.title.edit', { target: $t('label.user') }) }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  v-model="user.employee_id"
                  :disabled="isLoading"
                  :label="$t('user.employeeId')"
                  autocomplete="off"
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  v-model="user.name"
                  v-validate="'required'"
                  :disabled="isLoading"
                  :error-messages="errors.collect('user.name')"
                  :label="$t('user.name')"
                  autocomplete="off"
                  data-vv-name="user.name"
                  required
                  type="text"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  v-model="user.email"
                  :disabled="isLoading"
                  :label="$t('user.email')"
                  autocomplete="off"
                  readonly
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
                <v-autocomplete
                  v-model="user.department"
                  :disabled="isLoading"
                  :error-messages="errors.collect('user.department')"
                  :items="departments"
                  :label="$t('user.department')"
                  :loading="isLoading"
                  :search-input.sync="searchDepartment"
                  data-vv-name="user.department"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  return-object
                  @change="searchDepartment = null"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
                <v-combobox
                  :disabled="isLoading"
                  :error-messages="errors.collect('user.tags')"
                  :items="tags"
                  :label="$t('user.tags')"
                  :loading="isLoading"
                  :search-input.sync="searchTag"
                  :value="user.tags"
                  clearable
                  counter
                  data-vv-name="user.tags"
                  deletable-chips
                  hide-no-data
                  hide-selected
                  multiple
                  small-chips
                  @change="changeTags"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
                <v-switch
                  v-model="user.admin"
                  :disabled="user.id === currentUser?.id || isLoading"
                  :label="$t('user.admin')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!user.deleted_at"
            :disabled="isSaving || isLoading || !currentUser || user.id === _.get(currentUser, 'id')"
            :loading="isSaving || isLoading"
            text
            @click.stop="suspendDialog = true"
          >
            {{ $t('button.suspend') }}
          </v-btn>
          <v-btn
            v-else
            :disabled="isSaving || isLoading"
            :loading="isSaving || isLoading"
            text
            @click.stop="restoreDialog = true"
          >
            {{ $t('button.restore') }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            @click.stop="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="isSaving || isLoading"
            :loading="isSaving || isLoading"
            color="primary"
            text
            type="submit"
          >
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import { User } from '@/store/models/Person'
import _cloneDeep from 'lodash/cloneDeep'
import _map from 'lodash/map'
import _isNil from 'lodash/isNil'
import Me from '@/store/models/Me'
import notification from '@/utils/notification'
import { UserTag } from '@/store/models/Tag'
import Department from '@/store/models/Department'
import SuspendMemberDialog from '@/components/organisms/members/SuspendMemberDialog.vue'
import RestoreMemberDialog from '@/components/organisms/members/RestoreMemberDialog.vue'
import notifyResponseError from '@/utils/notifyResponseError'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    RestoreMemberDialog,
    SuspendMemberDialog,
  },
})
export default class EditMemberDialogComponent extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly item!: Record
  isSaving = false
  isLoading = false
  user: Record = {}
  searchTag: string = null
  searchDepartment: string = null
  suspendDialog = false
  restoreDialog = false

  get editUser(): Record {
    return this.user
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
    if (!value) {
      this.user = {}
      this.searchTag = null
      this.searchDepartment = null
    }
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get tags(): string[] {
    return _map(UserTag.all(), 'name')
  }

  get departments(): Record[] {
    return Department.query().orderBy('display_order', 'asc').all()
  }

  @Emit()
  input(value): boolean {
    return value
  }

  @Watch('value')
  async watchValue(): Promise<void> {
    if (this.value) {
      this.isLoading = true
      await Promise.all([UserTag.fetchAll({ useCache: false }), Department.fetchAll({ useCache: false })])
      const user = User.query().with(['department']).find(this.item.id)
      this.user = _cloneDeep(user)
      this.isLoading = false
    }
  }

  async save(): Promise<void> {
    this.isSaving = true
    if (!(await this.$validator.validateAll())) {
      this.isSaving = false
      return Promise.reject()
    }
    const user: Record = User.query().with(['department']).find(this.item.id)
    const tags = _map(this.user.tags, (tag) => {
      if (typeof tag === 'string') {
        return tag
      }
      return tag.name
    })
    try {
      const { data } = await this.$http.patch(['users', this.user.id].join('/'), {
        name: (this.user.name || '').trim() || null,
        admin: this.user.admin || null,
        'department_id': this.user.department?.id || null,
        'employee_id': (this.user.employee_id || '').trim() || null,
      })
      if (user.tags.length > 0) {
        const { data: resData } = await this.$http.delete(['users', data.id, 'tags'].join('/'), {
          data: {
            tags: user.tags,
          },
        })
        data.tags = resData.tags
      }
      if (tags.length > 0) {
        const { data: resData } = await this.$http.post(['users', data.id, 'tags'].join('/'), {
          tags,
        })
        data.tags = resData.tags
      }
      User.insertOrUpdate({ data })
      notification.success(this.$t('message.updated') as string)
      UserTag.fetchAll({ useCache: false })
    } catch (e) {
      return Promise.reject(e)
    } finally {
      this.isSaving = false
    }
  }

  cancel(): void {
    this.show = false
  }

  async submit(): Promise<void> {
    await this.$nextTick()
    try {
      await new Promise((resolve) => {
        const done = (): void => {
          if (_isNil(this.searchTag)) {
            clearInterval(interval)
            resolve()
          }
        }
        done()
        const interval = setInterval(() => {
          done()
        }, 200)
      })
      await this.save()
      this.show = false
    } catch (e) {
      if (e.status === 422) {
        notifyResponseError(e.response)
      }
      console.error(e)
    }
  }

  changeTags(value: string[]): void {
    this.user.tags = value
    this.searchTag = null
  }
}
</script>
