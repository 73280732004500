<template>
  <div class="c-dashboard-panel__ranking py-0 px-3">
    <template v-if="loading">
      <v-row class="ma-0 pa-0">
        <v-col
          class="c-dashboard-panel__ranking__cell d-flex flex-column align-center"
          cols="6"
        >
          <label class="text-subtitle-2 c-dashboard-panel__label">
            <v-skeleton-loader
              height="22px"
              type="text"
              width="84px"
            />
          </label>
          <span class="text-h5 primary--text"><v-skeleton-loader
            height="32px"
            type="text"
            width="51px"
          /></span>
        </v-col>
        <v-col
          class="c-dashboard-panel__ranking__cell d-flex flex-column align-center"
          cols="6"
        >
          <label class="text-subtitle-2 c-dashboard-panel__label">
            <v-skeleton-loader
              height="22px"
              type="text"
              width="84px"
            />
          </label>
          <span class="text-h5 success--text"><v-skeleton-loader
            height="32px"
            type="text"
            width="51px"
          /></span>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col
          class="d-flex flex-column align-center c-dashboard-panel__ranking__cell c-help-container"
          cols="12"
        >
          <div class="pa-0 ma-0 c-help--absolute">
            <Help v-if="!hideHelpLastRank">
              <slot name="help-last-rank" />
            </Help>
          </div>
          <label class="text-subtitle-2 c-dashboard-panel__label">
            <v-skeleton-loader
              height="22px"
              type="text"
              width="70px"
            />
          </label>
          <div>
            <span class="text-h4"><v-skeleton-loader
              height="40px"
              type="text"
              width="53px"
            /></span>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row class="ma-0 pa-0">
        <v-col
          class="c-dashboard-panel__ranking__cell d-flex flex-column align-center"
          cols="6"
        >
          <label class="text-subtitle-2 c-dashboard-panel__label">おくったTips</label>
          <span class="text-h5 primary--text">{{ gives }}</span>
        </v-col>
        <v-col
          class="c-dashboard-panel__ranking__cell d-flex flex-column align-center"
          cols="6"
        >
          <label class="text-subtitle-2 c-dashboard-panel__label">もらったTips</label>
          <span class="text-h5 success--text">{{ takes }}</span>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col
          class="d-flex flex-column align-center c-dashboard-panel__ranking__cell c-help-container"
          cols="12"
        >
          <div class="pa-0 ma-0 c-help--absolute">
            <Help v-if="!hideHelpLastRank">
              <slot name="help-last-rank" />
            </Help>
          </div>
          <label class="text-subtitle-2 c-dashboard-panel__label">前回の順位</label>
          <div>
            <span class="text-h4">{{ lastRank }}</span>位
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mdiHelpCircleOutline } from '@mdi/js'
import Help from '@/components/organisms/help/Help.vue'

@Component({
  name: 'panel-ranking',
  components: { Help },
})
export default class PanelRanking extends Vue {
  readonly icons = {
    mdiHelpCircleOutline,
  }
  @Prop({ default: 0 })
  readonly takes!: number
  @Prop({ default: 0 })
  readonly gives!: number
  @Prop({ default: 0 })
  readonly lastRank!: number
  @Prop({ default: false })
  readonly loading?: boolean

  get hideHelpLastRank(): boolean {
    return !this.$slots['help-last-rank']
  }
}
</script>
<style lang="scss" scoped>
@import 'node_modules/vuetify/src/styles/settings/index';

$border: 1px solid #dedede;

.v-skeleton-loader {
  // noinspection CssInvalidPseudoSelector
  display: flex;
  align-items: center;

  // noinspection CssInvalidPseudoSelector
  > :deep(*) {
    margin-bottom: 0;
  }
}

.c- {
  &dashboard-panel {
    &__ranking {
      width: 100%;
      border: $border;
      border-radius: $border-radius-root;

      > :not(:first-child) {
        border-top: $border;
      }

      &__cell {
        &:not(:first-child) {
          border-left: $border;
        }
      }
    }

    &__label {
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  }

  &help-container {
    position: relative;
  }

  &help {
    &--absolute {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}
</style>
