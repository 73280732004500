<template>
  <v-list
    reverse
    two-line
  >
    <template v-for="item of comments">
      <CommentListItem
        :key="item.id"
        :model="model"
        :value="item"
      />
      <v-divider :key="`divider-${item.id}`" />
    </template>
  </v-list>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import CommentListItem from '@/components/organisms/comments/CommentListItem.vue'
import { CommentableModel as _CommentableModel } from '@/store/models'

type CommentableModel = _CommentableModel
@Component({
  components: {
    CommentListItem,
  },
})
export default class CommentListComponent extends Vue {
  @Prop({ default: null })
  readonly model!: CommentableModel
  @Prop({ default: [] })
  readonly value!: Record[]

  get comments(): Record[] {
    return this.value
  }
}
</script>
