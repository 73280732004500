<template>
  <MyContainer>
    <template #title>
      {{ $t('label.integrations') }}
    </template>
    <MyContent>
      <v-list>
        <v-list-item
          v-for="(service, index) in services"
          :key="index"
        >
          <v-list-item-avatar>
            <img :src="service.avatar">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ service.title }}<span
                v-if="service.isIntegrated"
                class="ml-2 primary--text text-caption"
              >追加済</span>
            </v-list-item-title>
            <v-list-item-subtitle>{{ service.description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="!service.isIntegrated || _.isNil(service.removeFrom)"
              color="primary"
              @click.prevent.stop="service.addTo.action"
            >
              {{ service.addTo.label }}
            </v-btn>
            <v-btn
              v-else
              color="warning"
              @click.prevent.stop="openRemoveDialog(service)"
            >
              {{ service.removeFrom.label }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </MyContent>
    <v-dialog
      v-model="removeDialogIsDisplayed"
      width="500"
    >
      <v-card v-if="service">
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          システム連携の削除
        </v-card-title>
        <v-card-text class="mt-4">
          {{ service.title }} との連携を削除します。
        </v-card-text>
        <v-card-text v-if="service.removeMessage">
          {{ service.removeMessage }}
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="closeRemoveDialog"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="service.removeFrom.action"
          >
            {{ $t('button.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </MyContainer>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import slackLogo from '@/img/Slack_Mark_Web.png'
import Me from '@/store/models/Me'
import { Record } from '@vuex-orm/core'
import _get from 'lodash/get'
import notification from '@/utils/notification'
import MyContainer from '@/components/atoms/MyContainer.vue'
import DepartmentList from '@/components/organisms/departments/DepartmentList.vue'
import MyContent from '@/components/atoms/MyContent.vue'

type Action = () => void

interface IntegrationServiceButton {
  label: string
  action: Action
}

interface IntegrationService {
  avatar: string
  title: string
  description: string
  addTo: IntegrationServiceButton
  removeFrom?: IntegrationServiceButton
  removeMessage?: string
  isIntegrated: boolean
}

@Component({
  components: { MyContent, DepartmentList, MyContainer },
})
export default class extends Vue {
  service: IntegrationService = null

  get removeDialogIsDisplayed(): boolean {
    return !!this.service
  }

  set removeDialogIsDisplayed(value: boolean) {
    if (!value) {
      this.service = null
    }
  }

  get tenant(): Record {
    const currentUser: Record = Me.query().with('tenant').first()
    return _get(currentUser, 'tenant')
  }

  get services(): IntegrationService[] {
    const integrations = _get(this.tenant, 'integrations')
    const services: IntegrationService[] = []
    if (process.env.SLACK_APP_CLIENT_ID) {
      const params = []
      params.push(`client_id=${process.env.SLACK_APP_CLIENT_ID}`)
      if (process.env.SLACK_APP_SCOPE) {
        params.push(`scope=${process.env.SLACK_APP_SCOPE}`)
      }
      if (process.env.SLACK_APP_USER_SCOPE) {
        params.push(`user_scope=${process.env.SLACK_APP_USER_SCOPE}`)
      }
      params.push(`redirect_uri=${process.env.SLACK_APP_REDIRECT_URI || location.href}`)
      params.push(`state=${location.host.split('.')[0]}`)
      const slackAuthUrl = `https://slack.com/oauth/v2/authorize?${params.join('&')}#slack`
      services.push({
        avatar: slackLogo,
        title: 'Slack',
        description: '感謝やつぶやきが、チャンネルに通知されます。また、Slackから感謝が送れるようになります。',
        addTo: {
          label: 'Slackに追加する',
          action: () => {
            location.href = slackAuthUrl
          },
        },
        removeFrom: {
          label: '削除する',
          action: async () => {
            const { status } = await this.$http.delete('integrations/slack')
            if (status !== 204) {
              notification.error(this.$t('message.failed') as string)
              return
            }
            Me.load({ useCache: false })
            this.closeRemoveDialog()
          },
        },
        removeMessage: 'Slackに登録されているアプリは削除されません。Slackからもアプリを削除してください。',
        isIntegrated: _get(integrations, 'slack'),
      })
    }
    return services
  }

  mounted(): void {
    Me.load()
  }

  openRemoveDialog(service): void {
    this.service = service
  }

  closeRemoveDialog(): void {
    this.service = null
  }
}
</script>
