<template>
  <v-app-bar
    app
    fixed
  >
    <v-btn
      class="hidden-md-and-up"
      icon
      @click="clickMenu"
    >
      <v-icon>{{ icons.mdiMenu }}</v-icon>
    </v-btn>
    <v-spacer />
    <div
      :class="classOfMembers"
      class="hidden-xs-only mr-3"
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span v-on="on">
            <v-icon :color="colorOfMembers">
              {{ icons.mdiAccountOutline }}
            </v-icon>
            {{ numberOfMembers }}
          </span>
        </template>
        <span>招待中/利用停止中を含む登録されているメンバー数</span>
      </v-tooltip>
    </div>
    <v-divider
      class="hidden-xs-only mx-3"
      inset
      vertical
    />
    <v-badge
      :value="numberOfNotices >0"
      class="mr-3"
      overlap
      style="z-index: 9"
    >
      <template #badge>
        <span class="pa-0">{{ toBadgeString(numberOfNotices) }}</span>
      </template>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            icon
            max-height="36px"
            max-width="36px"
            @click="
              on.mouseleave()
              showNoticeDialog()
            "
            @mouseenter="on.mouseenter"
            @mouseleave="on.mouseleave"
          >
            <v-icon>
              {{ icons.mdiInformationOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>お知らせ</span>
      </v-tooltip>
    </v-badge>
    <v-badge
      :value="numberOfTakes >0"
      class="avatar"
      overlap
      style="z-index: 8"
    >
      <template #badge>
        <span class="pa-0">{{ toBadgeString(numberOfTakes) }}</span>
      </template>
      <v-avatar size="36px">
        <img
          :src="_.get(currentUser, 'avatarImage')"
          alt="Avatar"
        >
      </v-avatar>
    </v-badge>
    <strong class="hide-sp">{{ _.get(currentUser, 'name') }}</strong>
    <v-menu :nudge-width="100">
      <template #activator="{ on }">
        <v-btn
          color="primary"
          icon
          text
          v-on="on"
        >
          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="moveToTimeline">
          <v-list-item-title>一般画面</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item :to="{ name: 'wizard' }">
          <v-list-item-title>初期設定</v-list-item-title>
        </v-list-item>
        <v-list-item @clic.prevent.stop="inquiryDialog = true">
          <v-list-item-title>お問い合わせ</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click="logout">
          <v-list-item-title>ログアウト</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="inquiryDialog"
      max-width="640px"
    >
      <Inquiry />
    </v-dialog>
  </v-app-bar>
</template>
<script lang="ts">
import Vue from 'vue'
import devise from '@/utils/auth/DeviseAuthToken'
import _isNil from 'lodash/isNil'
import Me from '@/store/models/Me'
import Gift from '@/store/models/Gift'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import { mdiAccountOutline, mdiDotsVertical, mdiInformationOutline, mdiMenu } from '@mdi/js'
import { User } from '@/store/models/Person'
import _get from 'lodash/get'
import Notice from '@/store/models/Notice'
import Inquiry from '@/components/organisms/Inquiry.vue'
import { ElementDynamicClass } from '@/interfaces/ElementDynamicClass'
import { Emit } from 'vue-property-decorator'
import numberToString from '@/utils/numberToString'
import { namespace } from 'vuex-class'

const NoticeDialog = namespace('noticeDialog')
@Component({
  components: { Inquiry },
})
export default class extends Vue {
  readonly icons = {
    mdiMenu,
    mdiDotsVertical,
    mdiAccountOutline,
    mdiInformationOutline,
  }
  loading = true
  inquiryDialog = false
  @NoticeDialog.Action('show') showNoticeDialog

  get currentUser(): Record {
    return Me.query().with(['tenant']).first()
  }

  get tenant(): Record {
    return _get(this.currentUser, 'tenant')
  }

  get capacity(): number {
    return +this.tenant?.capacity?.replace('up_to_', '')
  }

  get numberOfMembers(): number {
    return User.available().count()
  }

  get colorOfMembers(): string {
    const diff = this.capacity - this.numberOfMembers
    if (diff <= 0) {
      return 'red'
    }
    if (diff < 10) {
      return 'orange'
    }
    return ''
  }

  get classOfMembers(): ElementDynamicClass {
    const classOfMembers = []
    if (this.colorOfMembers) {
      classOfMembers.push(`${this.colorOfMembers}--text`)
    }
    return classOfMembers
  }

  get numberOfTakes(): number {
    return Gift.query()
      .where((record) => record.recipient_id === this.currentUser.id && _isNil(record.accepted_at))
      .count()
  }

  get numberOfNotices(): number {
    return Notice.query()
      .where((record) => !record.read)
      .count()
  }

  toBadgeString(value: number): string {
    return numberToString(value, 99)
  }

  @Emit('click:menu')
  clickMenu(): void {
    return void 0
  }

  async logout(): Promise<void> {
    await this.$http.delete('auth/sign_out')
    devise.logout()
  }

  moveToTimeline(): void {
    this.$router.push({ name: `general` })
  }

  mounted(): void {
    User.fetchAll({ useCache: false, available: true })
  }
}
</script>
<style lang="scss" scoped>
.avatar {
  margin-right: 20px;
}
</style>
