import '@/polyfill'
import Vue from 'vue'
import enhance from '@/enhance'
import store from '@/store'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import router from '@/apps/router'
import App from '@/apps/App.vue'
import VueChannel from '@/plugins/vue-channel'

enhance(Vue)
Vue.use(VueChannel)
document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('App'))
  new Vue({
    el,
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
  })
})
