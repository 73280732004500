<template>
  <div>
    <h3>{{ label }}</h3>
    <v-chip-group
      :value="chosenTags"
      clearable
      column
      multiple
      @change="changeTags"
    >
      <v-chip
        v-for="tag in tags"
        :key="tag"
        :value="tag"
        filter
      >
        {{ tag }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import { UserTag } from '@/store/models/Tag'

@Component
export default class extends Vue {
  @Prop({ default: 'メンバータグ（OR条件）' })
  readonly label?: string

  @Prop({ default: [] })
  readonly value?: string[]

  @Prop({ default: false })
  readonly loading?: boolean

  get chosenTags(): string[] {
    return this.value
  }

  get tags(): string[] {
    return UserTag.all().map(({ name }) => name)
  }

  @Emit('input')
  changeTags(value: string[]): string[] {
    return value
  }
}
</script>
