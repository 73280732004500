<template>
  <div>
    <h3>{{ label }}</h3>
    <v-text-field
      :loading="loading"
      :value="text"
      autocomplete="off"
      clearable
      hide-details
      @change="changeText"
      @click:clear="clearText"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import SelectCategory from '@/components/organisms/categories/SelectCategory.vue'

@Component({
  components: { SelectCategory },
})
export default class extends Vue {
  @Prop({ default: '任意の文字列' })
  readonly label?: string

  @Prop({ default: '' })
  readonly value?: string

  @Prop({ default: false })
  readonly loading?: boolean

  get text(): string {
    return this.value
  }

  @Emit('input')
  changeText(value: string): string {
    return value
  }

  clearText(): void {
    this.changeText('')
  }
}
</script>
