<template>
  <v-card>
    <v-card-title class="text-h5">
      感謝タグ一覧
    </v-card-title>
    <v-card-text>
      感謝タグを編集する場合は
      <v-icon>{{ icons.mdiPencil }}</v-icon>
      ボタン、削除する場合は
      <v-icon>{{ icons.mdiDelete }}</v-icon>
      ボタンを押してください。
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="categories"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="totalCount"
      class="elevation-1"
    >
      <template #top>
        <v-toolbar flat>
          <v-spacer />
          <v-btn
            class="mb-2"
            color="primary"
            @click="showEditDialog()"
          >
            {{ $t('button.new') }}
          </v-btn>
          <EditCategoryDialog
            v-model="editDialog"
            :item="editingItem"
            @submit="fetch"
          />
          <DeleteCategoryDialog
            v-model="deleteDialog"
            :item="deletingItem"
            @submit="fetch"
          />
        </v-toolbar>
      </template>
      <template #item="{ item }">
        <tr>
          <td class="handle">
            <v-icon>{{ icons.mdiDragVertical }}</v-icon>
          </td>
          <td>{{ item.name }}</td>
          <td>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  tile
                  @click="showEditDialog(item)"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('button.edit') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  tile
                  @click="showDeleteDialog(item)"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('button.delete') }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import Category from '@/store/models/Category'
import _find from 'lodash/find'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import { Watch } from 'vue-property-decorator'
import _forEach from 'lodash/forEach'
import _cloneDeep from 'lodash/cloneDeep'
import _map from 'lodash/map'
import _flatten from 'lodash/flatten'
import DeleteCategoryDialog from './DeleteCategoryDialog.vue'
import EditCategoryDialog from './EditCategoryDialog.vue'
import { mdiDelete, mdiDragVertical, mdiPencil } from '@mdi/js'
import { DataTableHeader } from 'vuetify'
import Sortable from 'sortablejs'
import _pick from 'lodash/pick'

@Component({
  components: {
    DeleteCategoryDialog,
    EditCategoryDialog,
  },
})
export default class extends Vue {
  readonly icons = {
    mdiPencil,
    mdiDelete,
    mdiDragVertical,
  }
  options: Partial<{
    sortBy: string[]
    sortDesc: boolean[]
    page: number
    itemsPerPage: number
  }> = {}
  isLoading = true
  totalCount = 0
  deletingItem: Record = null
  deleteDialog = false
  editingItem: Record = null
  editDialog = false
  categories: Record[] = []

  get headers(): DataTableHeader[] {
    return [
      {
        value: 'handle',
        text: '',
        align: 'start',
        sortable: false,
        width: '56px',
      },
      {
        text: this.$t('category.name') as string,
        value: 'name',
        sortable: false,
      },
      {
        text: this.$t('label.action') as string,
        value: 'action',
        sortable: false,
        width: '120px',
      },
    ]
  }

  getCategories(): Record[] {
    const { sortBy, sortDesc, page, itemsPerPage } = this.options
    const category = Category.query()
    _forEach(sortBy, (key, index) => {
      category.orderBy(key, sortDesc[index] ? 'desc' : 'asc')
    })
    category.orderBy('display_order', 'asc')
    category.orderBy('id', 'asc')
    if (itemsPerPage > 0) {
      category.offset((page - 1) * itemsPerPage).limit(itemsPerPage)
    }
    return category.get()
  }

  // HACK: 初期表示時にデータテーブルのitemsに空を指定してしまうとリアクティブにデータが反映されないのでダイアログを閉じた際に再取得
  @Watch('editDialog')
  onChangeEditDialog(val, prev): void {
    if (!prev) return
    if (val) return
    if (this.categories.length > 1) return
    this.fetch()
  }

  @Watch('options')
  async fetch(): Promise<void> {
    this.isLoading = true
    const { sortBy, sortDesc, page, itemsPerPage } = this.options
    const limit = itemsPerPage > 0 ? itemsPerPage : 2
    const params = {
      page,
      limit,
      'q[s]': [],
    }
    sortBy.forEach((key, index) => {
      params['q[s]'].push(`${key} ${sortDesc[index] ? 'desc' : 'asc'}`)
    })
    params['q[s]'].push(`display_order asc`)
    params['q[s]'].push(`id asc`)
    const { data, headers } = await this.$http.get('categories', { params })
    const totalCount = _find(headers, (_val, key) => key.toLowerCase() === 'x-total-count')
    this.totalCount = totalCount ? +totalCount : 0
    await Category.insertOrUpdate({ data })
    if (itemsPerPage === -1) {
      const totalPage = Math.ceil(this.totalCount / limit)
      const requests = []
      for (params.page++; params.page <= totalPage; params.page++) {
        requests.push(this.$http.get('categories', { params: _cloneDeep(params) }))
      }
      const responses = await Promise.all(requests)
      const data = _flatten(_map(responses, (response) => response.data))
      await Category.insertOrUpdate({ data })
    }
    this.categories = this.getCategories()
    this.isLoading = false
  }

  showDeleteDialog(item): void {
    this.deletingItem = item
    this.deleteDialog = true
  }

  showEditDialog(item = null): void {
    this.editingItem = item
    this.editDialog = true
  }

  async mounted(): Promise<void> {
    const table: HTMLTableElement = await new Promise((resolve) => {
      const getTable = (): void => {
        const table: HTMLTableElement = this.$el.querySelector('.v-data-table tbody')
        if (table) {
          resolve(table)
          return
        }
        setTimeout(() => {
          getTable()
        }, 100)
      }
      getTable()
    })
    Sortable.create(table, {
      handle: '.handle',
      onEnd: ({ newIndex, oldIndex }) => {
        const selected = this.categories[oldIndex]
        const dropped = this.categories[newIndex]
        this.isLoading = true
        this.categories = []
        Promise.all([
          this.$http.patch(['categories', selected.id].join('/'), _pick(dropped, ['display_order'])),
          this.$http.patch(['categories', dropped.id].join('/'), _pick(selected, ['display_order'])),
        ])
          .then((responses) => Category.update(responses.map(({ data }) => data)))
          .finally(() => {
            this.categories = this.getCategories()
            this.isLoading = false
          })
        Category.update([
          {
            ...selected,
            'display_order': dropped.display_order,
          },
          {
            ...dropped,
            'display_order': selected.display_order,
          },
        ])
      },
    })
  }
}
</script>
<style lang="scss" scoped>
th,
td {
  &:first-child,
  &:last-child {
    width: 1px;
    white-space: nowrap;
  }
}

.handle {
  cursor: move;
}
</style>
