<template>
  <v-dialog
    v-model="show"
    max-width="623px"
    persistent
  >
    <v-form v-model="valid">
      <v-card class="l-dialog">
        <v-card-title
          class="grey lighten-2"
          primary-title
        >
          会社名を変更する
          <v-spacer />
          <span
            class="v-card__close"
            @click.stop="cancel"
          />
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row
              class="ma-0 pa-0 c-dialog"
              dense
            >
              <v-text-field
                v-model="displayName"
                v-validate="'required'"
                :error-messages="errors.collect('tenant.displayName')"
                autocomplete="off"
                class="c-dialog__verification__form"
                data-vv-name="tenant.displayName"
                placeholder="会社名を入力する"
                required
                type="text"
              />
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :elevation="4"
            @click.stop="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="!valid || saving"
            :elevation="4"
            :loading="saving"
            color="primary"
            @click.stop="update"
          >
            {{ $t('button.change') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import notification from '@/utils/notification'
import Me from '@/store/models/Me'
import Tenant from '@/store/models/Tenant'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class TenantDialogComponent extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  displayName = ''
  saving = false
  valid = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  get tenant(): Record {
    const currentUser: Record = Me.query().with('tenant').first()
    return _cloneDeep(_get(currentUser, 'tenant'))
  }

  @Emit()
  input(value): boolean {
    return value
  }

  @Watch('value')
  async watchValue(): Promise<void> {
    if (this.value) {
      this.displayName = _get(this.tenant, 'display_name')
      this.$validator.reset()
    }
  }

  cancel(): void {
    this.close()
  }

  async update(): Promise<void> {
    this.saving = true
    if (!(await this.$validator.validate('tenant.displayName'))) {
      this.saving = false
      return
    }
    const distributionDays = _get(this.tenant, 'distribution_days') || []
    if (distributionDays.length === 0) {
      distributionDays.push(1)
    }
    const { data } = await this.$http.patch('us', {
      tenant: {
        'display_name': this.displayName,
        'distribution_days': distributionDays, // NOTE: 曜日は渡さないと空で登録されるので
      },
    })
    // NOTE: カード情報はサーバーから帰ってくるデータに持っていないので上書き
    data.card = this.tenant.card
    Tenant.insertOrUpdate({ data })
    notification.success(this.$t('message.saved') as string)
    this.close()
  }

  private close(): void {
    this.show = false
    this.$validator.reset()
    this.saving = false
  }
}
</script>
<style lang="scss" scoped>
.c- {
  &dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 94%;
    padding: 20px 0 10px;
    margin: 0 auto;
    text-align: center;
  }
}
</style>
