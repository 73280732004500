<template>
  <div class="p-my-correlation-diagram">
    <v-expansion-panels class="p-my-correlation-diagram__expansion-panels ma-2">
      <v-expansion-panel>
        <v-expansion-panel-header v-slot="{ open }">
          フィルター {{ open ? '' : `: ${conditionText}` }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SearchCondition
            v-model="searchCondition"
            :loading="loading"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <CorrelationDiagram
      ref="diagram"
      :loading="loading"
      class="p-my-correlation-diagram__diagram"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import CorrelationDiagram, { Link, Node } from '@/components/organisms/diagrams/CorrelationDiagram'
import { Prop, Watch } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _maxBy from 'lodash/maxBy'
import _sumBy from 'lodash/sumBy'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import _find from 'lodash/find'
import * as http from '@/utils/http'
import { User } from '@/store/models/Person'
import SearchCondition, { SearchValue } from '@/components/organisms/SearchCondition.vue'
import Category from '@/store/models/Category'
import GiftSummary from '@/store/models/GiftSummary'
import _filter from 'lodash/filter'

@Component({
  components: {
    SearchCondition,
    CorrelationDiagram,
  },
})
export default class MyCorrelationDiagramComponent extends Vue {
  $refs!: {
    diagram: CorrelationDiagram
  }
  @Prop({ default: 'took' })
  readonly type!: 'took' | 'gave'
  loading = true
  searchCondition: Partial<SearchValue> = {}

  get conditionText(): string {
    const conditionTexts = []
    if (this.searchCondition.from && this.searchCondition.to) {
      conditionTexts.push(`${this.searchCondition.from} ~ ${this.searchCondition.to}`)
    }
    if (this.searchCondition.category) {
      const category: Record = Category.find(this.searchCondition.category)
      conditionTexts.push(category.name)
    }
    if (!_isNil(this.searchCondition.tag)) {
      if (typeof this.searchCondition.tag === 'string') {
        conditionTexts.push(this.searchCondition.tag)
      } else {
        if (this.searchCondition.tag.length > 0) {
          conditionTexts.push(this.searchCondition.tag.join(' / '))
        }
      }
    }
    return conditionTexts.join(', ')
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get entity(): 'takes' | 'gives' {
    return this.type === 'took' ? 'takes' : 'gives'
  }

  get sourceProp(): 'sender' | 'recipient' {
    return this.type === 'took' ? 'sender' : 'recipient'
  }

  get targetProp(): 'sender' | 'recipient' {
    return this.type === 'took' ? 'recipient' : 'sender'
  }

  makeNodes(items: Record[]): Node[] {
    if (_isNil(this.currentUser)) {
      return []
    }
    const baseRadius = 20
    const nodes: Node[] = []
    nodes.push({
      id: this.currentUser.id,
      name: this.currentUser.name,
      image: this.currentUser.avatarImage,
      radius: baseRadius,
    })
    if (items.length === 0) {
      return nodes
    }
    const totalPoint = _sumBy(items, 'points')
    const sourceGroup = _groupBy(items, `${this.sourceProp}_id`)
    nodes.push(
      ..._map(sourceGroup, (g) => {
        const user = g[0][this.sourceProp]
        const point = _sumBy(g, 'points')
        const extendRadius = totalPoint > 0 ? (point / totalPoint) * 10 : 0
        return {
          id: user.id,
          name: user.name,
          image: user.avatarImage,
          radius: baseRadius + extendRadius,
        }
      }),
    )
    const targetGroup = _groupBy(
      _filter(items, (val) => !_find(nodes, { id: val[`${this.targetProp}_id`] })),
      `${this.targetProp}_id`,
    )
    nodes.push(
      ..._map(targetGroup, (g) => {
        const user = g[0][this.targetProp]
        return {
          id: user.id,
          name: user.name,
          image: user.avatarImage,
          radius: baseRadius,
        }
      }),
    )
    return nodes
  }

  makeLinks(items: Record[]): Link[] {
    const baseWidth = 1.2
    const group = _groupBy(items, (val) => {
      return val[`${this.sourceProp}_id`] + '-' + val[`${this.targetProp}_id`]
    })
    return _map(group, (g, k) => {
      const keys = k.split('-')
      const latest = this.$moment(_get(_maxBy(g, 'last_occurred_at'), 'last_occurred_at'), 'YYYY-MM-DD').unix()
      const min = this.$moment(this.searchCondition.from, 'YYYY-MM-DD').unix()
      let max: number
      if (this.$moment().isBefore(this.searchCondition.to)) {
        max = this.$moment(this.$moment().format('YYYY-MM-DD')).unix()
      } else {
        max = this.$moment(this.searchCondition.to, 'YYYY-MM-DD').unix()
      }
      const rate = latest === max ? 1 : (latest - min) / (max - min)
      const red = 255
      const green = Math.floor(200 * (1 - rate))
      const blue = Math.floor(200 * (1 - rate))
      return {
        source: keys[0],
        target: keys[1],
        width: baseWidth * _sumBy(g, 'count'),
        color: `rgba(${red}, ${green}, ${blue}, 1)`,
      }
    })
  }

  async mounted(): Promise<void> {
    await Me.load()
    this.searchCondition = {
      from: this.$moment().add(-1, 'week').startOf('month').format('YYYY-MM-DD'),
      to: this.$moment().add(-1, 'week').endOf('month').format('YYYY-MM-DD'),
    }
  }

  @Watch('searchCondition')
  async fetch(): Promise<void> {
    this.loading = true
    GiftSummary.truncate()
      .then(() =>
        User.fetchAll({
          params: {
            'with_deleted': true, // 削除メンバーを含む
          },
        }),
      )
      .then(() => {
        const condition: {
          'user_tags_name_in'?: string[]
          'sender_tags_name_in'?: string[]
          'categories_id_in'?: number[]
          'created_at_gteq'?: string
          'created_at_lteq'?: string
          'sender_id_eq'?: number
          'user_id_eq'?: number
        } = {
          'created_at_gteq': this.$moment(this.searchCondition.from).startOf('day').format(),
          'created_at_lteq': this.$moment(this.searchCondition.to).endOf('day').format(),
        }
        if (!_isNil(this.searchCondition.category)) {
          condition['categories_id_in'] = [this.searchCondition.category]
        }
        if (!_isNil(this.searchCondition.tag)) {
          if (typeof this.searchCondition.tag === 'string') {
            condition[`${this.type === 'took' ? 'sender' : 'user'}_tags_name_in`] = [this.searchCondition.tag]
          } else {
            if (this.searchCondition.tag.length > 0) {
              condition[`${this.type === 'took' ? 'sender' : 'user'}_tags_name_in`] = this.searchCondition.tag
            }
          }
        }
        condition[`${this.type === 'took' ? 'user' : 'sender'}_id_eq`] = this.currentUser.id
        const params = new http.SearchCondition({
          condition,
        }).toRansackParams()
        return GiftSummary.fetchAll({ params })
      })
      .then(() => {
        const items = GiftSummary.query().with(['sender', 'recipient']).orderBy('count', 'desc').get()
        const nodes = this.makeNodes(items)
        const links = this.makeLinks(items)
        const target = this.currentUser.id
        const markerStart = this.type === 'gave'
        const markerEnd = this.type === 'took'
        this.$refs.diagram.draw({ target, nodes, links }, markerStart, markerEnd)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/index';

.p-my-correlation-diagram {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  height: 100%;

  &__diagram {
    flex-grow: 1;
    border: 1px solid #dedede;
    border-radius: $border-radius-root;
  }

  &__expansion-panels {
    width: auto;
  }
}
</style>
