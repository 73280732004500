<template>
  <v-navigation-drawer
    v-model="drawer"
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
    app
    fixed
  >
    <v-toolbar class="l-navigation-header">
      <v-toolbar-title>
        <a href="/">
          <img
            class="pcLogo"
            src="@/img/logo_color.png"
          >
        </a>
      </v-toolbar-title>
    </v-toolbar>
    <v-list dark>
      <template v-for="(menu, index) in menuItems">
        <v-list-group
          v-if="menu.children"
          :key="index"
          :disabled="!menu.available"
          color="white"
          no-action
          value="true"
        >
          <template #activator>
            <v-list-item-title>
              <v-icon v-if="menu.icon">
                {{ menu.icon }}
              </v-icon>
              {{ menu.label }}
            </v-list-item-title>
          </template>
          <v-list-item
            v-for="(child, childIndex) in menu.children"
            :key="`${index}-${childIndex}`"
            :disabled="!child.available"
            :to="child.to"
            link
          >
            <v-list-item-title class="p-navigation-child-item__title">
              <v-icon v-if="child.icon">
                {{ child.icon }}
              </v-icon>
              {{ child.label }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :key="index"
          :disabled="!menu.available"
          :to="menu.to"
        >
          <v-list-item-title>
            <v-icon>{{ menu.icon }}</v-icon>
            {{ menu.label }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import {
  mdiAccount,
  mdiAccountGroup,
  mdiChartBar,
  mdiCog,
  mdiGraph,
  mdiHomeCity,
  mdiTag,
  mdiTextBoxOutline,
} from '@mdi/js'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import _get from 'lodash/get'
import routes from '@/apps/admin/routes'
import _find from 'lodash/find'
import _isNil from 'lodash/isNil'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _includes from 'lodash/includes'
import { RawLocation, RouteConfig } from 'vue-router'

interface MenuItem {
  to: RawLocation
  label: string
  icon?: string
  available: boolean
  children?: MenuItem[]
}

@Component
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: boolean

  get menuItems(): MenuItem[] {
    const menuItems = [
      {
        name: 'admin/dashboard',
        label: this.$t('label.dashboard'),
        icon: mdiGraph,
      },
      {
        name: 'results',
        label: this.$t('label.results'),
        icon: mdiChartBar,
      },
      {
        name: 'departments',
        label: this.$t('label.departments'),
        icon: mdiAccountGroup,
      },
      {
        name: 'members',
        label: this.$t('label.member'),
        icon: mdiAccount,
        children: [
          {
            name: 'invitation',
            label: this.$t('label.invitation'),
          },
          {
            name: 'members',
            label: this.$t('label.list'),
          },
          this.tenant?.exchange_mode === 'drawable'
            ? {
              name: 'points',
              label: this.$t('label.listOfPoints'),
            }
            : null,
        ].filter((child) => !!child),
      },
      {
        name: 'categories',
        label: this.$t('label.thanksTags'),
        icon: mdiTag,
      },
      {
        name: 'communities',
        label: this.$t('label.communities'),
        icon: mdiHomeCity,
      },
      {
        name: 'agreements',
        label: this.$t('label.agreements'),
        icon: mdiTextBoxOutline,
      },
      {
        name: 'settings',
        label: this.$t('label.settings'),
        icon: mdiCog,
        children: [
          {
            name: 'settings',
            label: this.$t('label.generalSettings'),
          },
          {
            name: 'integrations',
            label: this.$t('label.integrations'),
          },
        ],
      },
    ].filter((menu) => !!menu)
    return this.convertMenuToRoute(menuItems)
  }

  get currentUser(): Record {
    return Me.query().with(['tenant']).first()
  }

  get tenant(): Record {
    return _get(this.currentUser, 'tenant')
  }

  get drawer(): boolean {
    return this.value
  }

  set drawer(value) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  findRoute(routes, name): RouteConfig {
    return _find(routes, (route) => {
      if (!_isNil(route.children)) {
        return this.findRoute(route.children, name)
      }
      return name === route.name
    })
  }

  convertMenuToRoute(menuItems, parent: MenuItem = null): MenuItem[] {
    return _filter(
      _map(menuItems, ({ name, label, icon, hash, children }) => {
        const route = this.findRoute(routes, name)
        if (_isNil(route) && _isNil(parent)) return null
        const menuItem: MenuItem = {
          to: {
            name: name ? name : _get(parent.to, 'name'),
            hash,
          },
          label,
          icon,
          available: false,
        }
        if (!_isNil(menuItem)) {
          menuItem.available = !_includes(route?.meta?.unavailablePlans, _get(this.tenant, 'plan'))
        }
        if (!_isNil(parent)) {
          menuItem.available = menuItem?.available && parent?.available
        }
        if (children) {
          menuItem.children = this.convertMenuToRoute(children, menuItem)
        }
        return menuItem
      }),
      (menuItem) => !_isNil(menuItem),
    )
  }

  @Emit()
  input(value): boolean {
    return value
  }
}
</script>
<style lang="scss" scoped>
.p- {
  &navigation-child-item {
    &__title {
      &::before {
        position: absolute;
        margin-left: -1em;
        content: '-';
      }
    }
  }
}
</style>
