<template>
  <v-dialog
    v-model="show"
    max-width="260px"
  >
    <v-form @submit.stop.prevent="submit">
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          気分
        </v-card-title>
        <v-card-text>
          <v-select
            :item-text="itemText"
            :items="feelings"
            :value="_.get(currentUser, 'feeling.value', ':neutral_face:')"
            item-value="value"
            label="今の気分はどうですか？"
            @change="changeFeeling($event)"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click.stop="cancel"
          >
            キャンセル
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary"
            text
            type="submit"
          >
            変更
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import _find from 'lodash/find'
import Me from '@/store/models/Me'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class EditFeelingDialogComponent extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  feeling: Record = {
    value: ':neutral_face:',
  }
  feelings = [
    {
      emoji: '😐',
      value: ':neutral_face:',
      name: '普通',
    },
    {
      emoji: '😄',
      value: ':smile:',
      name: '良い',
    },
    {
      emoji: '😨',
      value: ':fearful:',
      name: '悪い',
    },
  ]
  loading = false

  get currentUser(): Record {
    return Me.query().with('feeling').first()
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  @Emit()
  input(value): boolean {
    return value
  }

  async post(): Promise<void> {
    this.loading = true
    this.$http
      .post('feeling', this.feeling)
      .then(({ data }) => {
        Me.insertOrUpdate({ data })
      })
      .finally(() => {
        this.loading = false
      })
  }

  cancel(): void {
    this.show = false
  }

  async submit(): Promise<void> {
    await this.post()
    this.show = false
  }

  changeFeeling(value): void {
    this.feeling = _find(this.feelings, (feeling) => feeling.value === value)
  }

  itemText(item): string {
    return `${item.emoji}:${item.name}`
  }
}
</script>
