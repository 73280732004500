import { render, staticRenderFns } from "./GiftResult.vue?vue&type=template&id=a01c05a8&scoped=true&"
import script from "./GiftResult.vue?vue&type=script&lang=ts&"
export * from "./GiftResult.vue?vue&type=script&lang=ts&"
import style0 from "./GiftResult.vue?vue&type=style&index=0&id=a01c05a8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a01c05a8",
  null
  
)

export default component.exports