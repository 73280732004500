<template>
  <v-container
    class="fill-height align-content-start"
    fluid
  >
    <v-row
      v-if="$scopedSlots['title']"
      class="ma-0 pa-0"
    >
      <v-col class="ma-0 pa-0">
        <h1 class="screen-title">
          <slot name="title" />
        </h1>
        <p
          v-if="$scopedSlots['description']"
          class="text-caption"
        >
          <slot name="description" />
        </p>
      </v-col>
    </v-row>
    <slot />
  </v-container>
</template>
<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'

@Component
export default class extends Vue {
}
</script>
