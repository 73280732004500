import Timeline from '@/pages/Timeline.vue'
import Tweet from '@/pages/Tweet.vue'
import Gift from '@/pages/Gift.vue'
import Profile from '@/pages/Profile.vue'
import TweetList from '@/pages/users/TweetList.vue'
import TakeList from '@/pages/users/TakeList.vue'
import GiveList from '@/pages/users/GiveList.vue'
import FollowingList from '@/pages/users/FollowingList.vue'
import Dashboard from '@/pages/Dashboard.vue'
import Award from '@/pages/Award.vue'

export default [
  {
    name: 'general',
    path: '',
    redirect: { name: 'timeline' },
    meta: {
      auth: true,
    },
  },
  {
    name: 'timeline',
    path: 'timeline',
    component: Timeline,
    meta: {
      auth: true,
    },
  },
  {
    name: 'tweets',
    path: 'tweets/:id',
    component: Tweet,
    meta: {
      auth: true,
    },
  },
  {
    name: 'takes',
    path: 'takes/:id',
    component: Gift,
    meta: {
      auth: true,
    },
  },
  {
    name: 'award',
    path: 'award',
    component: Award,
    meta: {
      auth: true,
    },
  },
  {
    path: 'members/:id',
    component: Profile,
    meta: {
      auth: true,
    },
    children: [
      {
        name: 'member/tweets',
        path: 'tweets',
        component: TweetList,
        meta: {
          auth: true,
        },
      },
      {
        name: 'member/takes',
        path: 'takes',
        component: TakeList,
        meta: {
          auth: true,
        },
      },
      {
        name: 'member/gives',
        path: 'gives',
        component: GiveList,
        meta: {
          auth: true,
        },
      },
      {
        name: 'member/followings',
        path: 'followings',
        component: FollowingList,
        meta: {
          auth: true,
          own: true,
        },
      },
      {
        name: 'member/home',
        path: '',
        redirect: { name: 'member/tweets' },
      },
      {
        path: '*',
        redirect: { name: 'member/tweets' },
      },
    ],
  },
  {
    name: 'dashboard',
    path: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true,
      unavailablePlans: ['simple'],
    },
  },
]
