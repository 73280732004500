<template>
  <v-form
    v-model="isValid"
    :loading="saving"
    class="p-point"
    @submit.stop.prevent="save"
  >
    <v-card>
      <v-card-title class="text-h5">
        チップ設定
      </v-card-title>
      <v-card-text v-if="false">
        <v-radio-group
          v-model="exchangeMode"
          v-validate="'required'"
          :error-messages="errors.collect('tenant.exchangeMode')"
          :label="$t('tenant.exchangeMode')"
          class="p-point__exchange-mode"
          data-vv-name="tenant.exchangeMode"
          row
        >
          <v-radio
            v-for="item in exchangeModeItems"
            :key="item.value"
            :label="item.name"
            :value="item.value"
            class="my-0 mr-4 pa-0"
          />
        </v-radio-group>
        <ul>
          <li>標準: 個人画面のもらったチップ数は[今月の合計]が表示されます。</li>
          <li>
            消費: もらったチップを管理者が手動で消費できます。個人画面のもらったチップ数は[すべての合計] -
            [消費数]が表示されます。
          </li>
        </ul>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="pointsPerUnit"
          v-validate="'required|numeric|max_value:1000000'"
          :error-messages="errors.collect('tenant.pointsPerUnit')"
          :label="$t('tenant.pointsPerUnit')"
          autocomplete="off"
          data-vv-name="tenant.pointsPerUnit"
          max="1000000"
          required
          type="number"
        />
      </v-card-text>
      <v-card-text>
        <!-- HACK: checkbox-groupがなかったので -->
        <v-radio-group
          :error-messages="errors.collect('tenant.distributionDays')"
          :label="$t('tenant.distributionDays')"
          class="p-point__distribution-days"
          row
        >
          <v-checkbox
            v-for="weekday in weekdays"
            :key="weekday.value"
            v-model="distributionDays"
            v-validate="'required:invalidateFalse'"
            :error-messages="errors.collect('tenant.distributionDays')"
            :label="weekday.label"
            :value="weekday.value"
            class="my-0 mr-4 pa-0"
            data-vv-name="tenant.distributionDays"
            hide-details
          />
        </v-radio-group>
      </v-card-text>
      <v-card-text>
        <v-row
          align="baseline"
          class="ma-0 pa-0"
        >
          <v-text-field
            v-model="pointExpiresAfter"
            v-validate="'required|numeric|max_value:52'"
            :error-messages="errors.collect('tenant.pointExpiresAfter')"
            :label="$t('tenant.pointExpiresAfter')"
            autocomplete="off"
            data-vv-name="tenant.pointExpiresAfter"
            max="52"
            required
            type="number"
          />
          週間
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="cancelLabel"
          @click="cancel"
        >
          {{ cancelLabel }}
        </v-btn>
        <v-btn
          v-if="backLabel"
          @click="back"
        >
          {{ backLabel }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="skipLabel"
          @click="skip"
        >
          {{ skipLabel }}
        </v-btn>
        <v-btn
          :loading="saving"
          color="primary"
          type="submit"
        >
          {{ saveLabel ? saveLabel : '保存' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import Me from '@/store/models/Me'
import { Record } from '@vuex-orm/core'
import Tenant, { ExchangeMode } from '@/store/models/Tenant'
import notification from '@/utils/notification'
import _get from 'lodash/get'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class PointSettingsComponent extends Vue {
  @Prop({ default: null })
  readonly saveLabel?: string
  @Prop({ default: null })
  readonly skipLabel?: string
  @Prop({ default: null })
  readonly backLabel?: string
  @Prop({ default: null })
  readonly cancelLabel?: string
  saving = false
  isValid = false
  weekdays: {
    value: number
    label: string
  }[] = []
  readonly exchangeModeItems = [
    {
      value: 'stack' as ExchangeMode,
      name: '標準',
    },
    {
      value: 'drawable' as ExchangeMode,
      name: '消費',
    },
  ] as const
  exchangeMode: ExchangeMode = 'stack'
  pointsPerUnit = ''
  pointExpiresAfter = ''
  distributionCycle = ''
  distributionDays = []

  get tenant(): Record {
    const currentUser: Record = Me.query().with('tenant').first()
    return _get(currentUser, 'tenant')
  }

  @Watch('tenant')
  changeTenant(): void {
    this.exchangeMode = _get(this.tenant, 'exchange_mode')
    this.pointsPerUnit = _get(this.tenant, 'points_per_unit')
    this.pointExpiresAfter = _get(this.tenant, 'point_expires_after')
    this.distributionCycle = _get(this.tenant, 'distribution_cycle')
    this.distributionDays = _get(this.tenant, 'distribution_days')
  }

  @Watch('distributionDays')
  validateDistributionDays(): void {
    this.$validator.validate('tenant.distributionDays')
  }

  created(): void {
    this.weekdays = Array.from(new Array(7).keys()).map((key) => {
      return {
        value: key,
        label: this.$moment().weekday(key).format('ddd'),
      }
    })
  }

  mounted(): void {
    Me.load()
    this.changeTenant()
  }

  async patch(): Promise<void> {
    const { data } = await this.$http.patch('us', {
      tenant: {
        'points_per_unit': this.pointsPerUnit,
        'point_expires_after': this.pointExpiresAfter,
        'distribution_cycle': this.distributionCycle,
        'distribution_days': this.distributionDays,
        'exchange_mode': this.exchangeMode,
      },
    })
    // カード情報はサーバーで持っていないので上書き
    data.card = this.tenant.card
    Tenant.insertOrUpdate({ data })
  }

  async save(): Promise<void> {
    this.saving = true
    if (!(await this.$validator.validateAll())) {
      this.saving = false
      return
    }
    await this.patch()
    this.saving = false
    notification.success(this.$t('message.saved') as string)
    this.saved()
  }

  @Emit('saved')
  saved(): void {
    return void 0
  }

  @Emit('skip')
  skip(): void {
    return void 0
  }

  @Emit('back')
  back(): void {
    return void 0
  }

  @Emit('cancel')
  cancel(): void {
    return void 0
  }
}
</script>
<style lang="scss" scoped>
.p-point {
  &__distribution-days {
    // noinspection CssInvalidPseudoSelector
    :deep(.v-input--checkbox) {
      > .v-input__control {
        > .v-input__slot {
          margin: 0;
        }
      }
    }
  }
}
</style>
