<template>
  <section class="p-agreement__verification">
    <table class="mb-5">
      <tbody>
        <tr>
          <td>会社名</td>
          <td>{{ _.get(tenant, 'display_name') }}</td>
          <td>
            <v-btn
              class="c-btn c-btn-gray p-agreement__verification__btn pt-1 pb-1"
              @click="showTenantDisplayNameDialog"
            >
              {{ $t('button.change') }}
            </v-btn>
          </td>
        </tr>
        <tr>
          <td>チーム名</td>
          <td colspan="2">
            {{ _.get(tenant, 'name') }}
          </td>
        </tr>
        <tr>
          <td>料金プラン</td>
          <td colspan="2">
            {{ $t(`plans.${_.get(tenant, 'plan', 'trial')}`) }}({{ capacityLimit }}名利用)
          </td>
        </tr>
      </tbody>
    </table>
    <TenantDialog v-model="displayNameDialog" />
    <v-sheet>
      <h2 class="p-agreement__verification__subtitle mb-2">
        プラン変更について
      </h2>
      <p>
        お客様の状況と提供する機能を相談し最適なプランを提示いたします。プランまたは利用者上限の変更をご検討の場合は<a
          href="https://agelu.tips/contact/"
          rel="external nofollow noopener"
          target="_blank"
        >お問い合わせフォーム</a>または担当営業までご連絡ください。
      </p>
    </v-sheet>
    <v-sheet>
      <h2 class="p-agreement__verification__subtitle mb-2">
        解約について
      </h2>
      <p>
        ご解約をご希望の場合は<a
          href="https://agelu.tips/contact/"
          rel="external nofollow noopener"
          target="_blank"
        >お問い合わせフォーム</a>または担当営業までご連絡ください。
      </p>
    </v-sheet>
  </section>
</template>
<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import _get from 'lodash/get'
import TenantDialog from '@/components/organisms/tenants/TenantDialog.vue'

@Component({
  components: { TenantDialog },
})
export default class AgreementVerification extends Vue {
  displayNameDialog = false

  get tenant(): Record {
    const currentUser: Record = Me.query().with('tenant').first()
    return _get(currentUser, 'tenant')
  }

  get capacityLimit(): string {
    return _get(this.tenant, 'capacity').replace('up_to_', '')
  }

  showTenantDisplayNameDialog(): void {
    this.displayNameDialog = true
  }
}
</script>
<style lang="scss" scoped>
table {
  width: 100%;
  border-spacing: 0;
  border-top: 1px solid #ccc;

  tr {
    th,
    td {
      padding: 15px;
    }

    td {
      padding-left: 40px;
      font-size: 13px;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;

      &:first-child {
        width: 180px;
        padding-left: 20px;
        font-weight: 900;
        vertical-align: top;
        background: #eee;
      }

      &:last-child {
        width: 120px;
        border-right: 1px solid #ccc;
      }

      &:not(:last-child) {
        border-right: none;
      }

      &:not(:first-child) {
        &:not(:nth-child(2)) {
          padding: 0 10px;
          border-left: none;
        }
      }
    }
  }
}

.p- {
  &agreement {
    &__verification {
      &__btn {
        width: 100px;
        padding: 0;
        font-size: 12px;
      }

      &__subtitle {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
</style>
