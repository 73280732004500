<template>
  <v-dialog
    v-model="dialog"
    max-width="80vw"
  >
    <v-form @submit.stop.prevent="submit">
      <v-card>
        <v-card-title>利用詳細</v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  :label="$t('exchange.point')"
                  :value="exchange?.point"
                  hide-details
                  readonly
                />
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="12">
                <v-textarea
                  :label="$t('exchange.memo')"
                  :value="exchange?.memo"
                  hide-details
                  readonly
                />
              </v-col>
            </v-row>
            <v-row
              v-if="useDelete"
              class="ma-0 pa-0"
            >
              <v-col cols="12">
                <v-checkbox
                  v-model="confirmed"
                  v-validate="'required:invalidateFalse'"
                  :error-messages="errors.collect('confirmed')"
                  data-vv-name="confirmed"
                  hide-details
                  label="この利用履歴を削除します。"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="useDelete"
            :disabled="loading"
            :loading="loading"
            color="error"
            type="submit"
          >
            {{ $t('button.delete') }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            @click="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import Exchange from '@/store/models/Exchange'
import { Item } from '@vuex-orm/core'


@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class ExchangeDetailDialog extends Vue {
  @Prop({ default: false })
  readonly value?: boolean
  @Prop({ default: {} })
  readonly exchange!: Item<Exchange>
  @Prop({ default: false })
  readonly readOnly?: boolean
  @Prop({ default: false })
  readonly useDelete?: boolean

  loading = false
  confirmed = false

  get dialog(): boolean {
    return this.value
  }

  set dialog(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  @Emit()
  input(value: boolean): boolean {
    return value
  }

  cancel(): void {
    this.dialog = false
  }

  @Watch('confirmed')
  validateDistributionDays(): void {
    this.$validator.validate('confirmed')
  }

  async submit(): Promise<void> {
    this.loading = true
    if (!(await this.$validator.validateAll())) {
      this.loading = false
      return
    }
    try {
      const { data } = await this.$http.delete(`exchanges/${this.exchange.id}`)
      await Exchange.insertOrUpdate({ data })
    } finally {
      this.loading = false
      this.dialog = false
    }
  }
}
</script>
