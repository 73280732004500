<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-form @submit.stop.prevent="submit">
      <v-card>
        <v-card-title class="text-h5">
          メンバー削除
        </v-card-title>
        <v-card-text>
          <div v-html="$marked(message)" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click.stop="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="error"
            text
            type="submit"
          >
            {{ $t('button.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import { User } from '@/store/models/Person'
import notification from '@/utils/notification'

@Component
export default class DeleteMemberDialogComponent extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly item!: Record
  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  get user(): Record {
    return this.item
  }

  get message(): string {
    const message = []
    message.push(`\`${this.user.email}${this.user.name ? `(${this.user.name})` : ''}\`を削除します。`)
    message.push('')
    message.push(`- もらった感謝は削除されます。`)
    message.push(`- おくった感謝は差出人が不明になります。`)
    message.push('')
    message.push('復旧はできなくなりますが、よろしいですか？')
    return message.join('\n')
  }

  @Emit()
  input(value): boolean {
    return value
  }

  async delete(): Promise<void> {
    this.loading = true
    if (!(await this.$validator.validateAll())) {
      this.loading = false
      return
    }
    this.$http
      .put(['users', this.user.id, 'deletion'].join('/'))
      .then(() => {
        User.delete(this.user.id)
        notification.success(this.$t('message.deleted') as string)
      })
      .finally(() => (this.loading = false))
  }

  cancel(): void {
    this.show = false
  }

  async submit(): Promise<void> {
    await this.delete()
    this.show = false
  }
}
</script>
