<template>
  <v-menu
    v-model="datePickerDialog"
    :close-on-click="false"
    :close-on-content-click="false"
    :disabled="loading"
    min-width="290px"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="dateRangeText"
        :disabled="loading"
        :prepend-icon="icons.mdiCalendar"
        autocomplete="off"
        hide-details
        readonly
        single-line
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="pickedDates"
      :day-format="dayFormat"
      :locale="$i18n.locale"
      no-title
      range
      show-current
    >
      <v-spacer />
      <v-btn
        color="primary"
        text
        @click="cancelDateRange"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="loading || pickedDates.length !== 2"
        color="primary"
        text
        @click="changeDateRange"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { mdiCalendar } from '@mdi/js'

@Component
export default class extends Vue {
  readonly icons = {
    mdiCalendar,
  }
  @Prop({ default: null })
  readonly value?: [string, string]
  @Prop({ default: true })
  readonly loading: boolean
  dates = []
  pickedDates = []
  datePickerDialog = false

  get dateRangeText(): string {
    return [
      this.$moment(this.value[0]).format('YYYY年MM月DD日'),
      this.$moment(this.value[1]).format('YYYY年MM月DD日'),
    ].join(' ~ ')
  }

  cancelDateRange(): void {
    this.pickedDates = [this.dates[0], this.dates[1]]
    this.datePickerDialog = false
  }

  changeDateRange(): void {
    if (this.$moment(this.pickedDates[0]).isAfter(this.pickedDates[1])) {
      this.$emit('input', [this.pickedDates[1], this.pickedDates[0]])
    } else {
      this.$emit('input', [this.pickedDates[0], this.pickedDates[1]])
    }
    this.datePickerDialog = false
  }

  dayFormat(date): string {
    return this.$moment(date).format('D')
  }

  async mounted(): Promise<void> {
    this.input(this.value)
  }

  @Watch('value')
  input(value): void {
    let start = this.$moment().startOf('month').format('YYYY-MM-DD')
    let end = this.$moment().endOf('month').format('YYYY-MM-DD')
    if (value && value[0]) {
      start = this.$moment(value[0]).format('YYYY-MM-DD')
    }
    if (value && value[1]) {
      end = this.$moment(value[1]).format('YYYY-MM-DD')
    }
    this.dates = [start, end]
    this.pickedDates = [start, end]
  }
}
</script>
