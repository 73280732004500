<template>
  <div>
    <h3>{{ label }}</h3>
    <SelectCategory
      :loading="loading"
      :value="category"
      @input="changeCategory"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import SelectCategory from '@/components/organisms/categories/SelectCategory.vue'

@Component({
  components: { SelectCategory },
})
export default class extends Vue {
  @Prop({ default: '感謝タグ' })
  readonly label?: string

  @Prop({ default: null })
  readonly value?: string

  @Prop({ default: false })
  readonly loading?: boolean

  get category(): string {
    return this.value
  }

  @Emit('input')
  changeCategory(value: string): string {
    return value
  }
}
</script>
