<template>
  <v-card>
    <v-card-title class="text-h5">
      メンバー一覧
    </v-card-title>
    <v-card-text>行をクリックすることでメンバーの編集が可能です。</v-card-text>
    <v-card-text>
      <v-row
        class="ma-0 pa-0 mb-2"
      >
        <v-col>
          <h3>{{ $t('user.name') }}または{{ $t('user.email') }}で絞り込む</h3>
          <v-text-field
            :value="searchString"
            autocomplete="off"
            clearable
            hide-details
            @change="changeSearchString"
            @click:clear="clearSearchString"
          />
        </v-col>
        <v-col>
          <h3>状態で絞り込む</h3>
          <v-row class="ma-0 pa-0">
            <v-btn-toggle
              :value="status"
              clearable
              mandatory
              @change="changeStatus"
            >
              <v-btn value="all">
                全て
              </v-btn>
              <v-btn value="active">
                {{ $t('active') }}
              </v-btn>
              <v-btn value="invited">
                {{ $t('invited') }}
              </v-btn>
              <v-btn value="applied">
                {{ $t('applied') }}
              </v-btn>
              <v-btn value="deactivated">
                {{ $t('deactivated') }}
              </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        class="ma-0 pa-0 mb-2"
      >
        <v-col>
          <h3>部署で絞り込む</h3>
          <v-autocomplete
            :items="departments"
            :search-input.sync="searchDepartment"
            clearable
            hide-no-data
            hide-selected
            item-text="name"
            return-object
            @change="changeDepartment"
          />
        </v-col>
        <v-col>
          <h3>タグで絞り込む（OR条件）</h3>
          <v-chip-group
            :value="chosenTags"
            clearable
            column
            multiple
            @change="changeTags"
          >
            <v-chip
              v-for="tag in tags"
              :key="tag"
              :value="tag"
              filter
            >
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="usersPerPage"
      :loading="loading || !currentUser"
      :options="options"
      :server-items-length="totalCount"
      class="elevation-1"
      @update:options="changeOptions"
    >
      <template #top>
        <EditMemberDialog
          v-model="editDialog"
          :item="editingUser"
        />
        <ApproveDialog
          v-model="approveDialog"
          :item="editingUser"
        />
      </template>
      <template #item="{ item }">
        <tr
          class="p-member__list__row"
          @click="showDialog(item)"
        >
          <td>{{ item?.email }}</td>
          <td>{{ item?.employee_id }}</td>
          <td>{{ item?.name }}</td>
          <td>{{ item?.department?.name }}</td>
          <td>
            <TagGroup :value="item.tags" />
          </td>
          <td>
            <v-switch
              :disabled="item.id === _.get(currentUser, 'id')"
              :input-value="item.admin"
              @change="changeAdmin(item, $event)"
              @click.stop
            />
          </td>
          <td>
            {{ item?.current_sign_in_at ? $moment(item?.current_sign_in_at).format('llll') : '' }}
          </td>
          <td>
            <template v-if="item.status === 'applied'">
              <v-btn
                color="primary"
                @click.stop="showApproveDialog(item)"
              >
                {{ $t('applied') }}
              </v-btn>
            </template>
            <template v-else>
              {{ $t(item.status) }}
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import { User, UserStatus } from '@/store/models/Person'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import _forEach from 'lodash/forEach'
import _isNil from 'lodash/isNil'
import _map from 'lodash/map'
import SuspendMemberDialog from './SuspendMemberDialog.vue'
import EditMemberDialog from './EditMemberDialog.vue'
import { SearchCondition } from '@/utils/http'
import TagGroup from '@/components/organisms/TagGroup.vue'
import { mdiDelete, mdiDeleteForever, mdiDeleteRestore, mdiPencil } from '@mdi/js'
import RestoreMemberDialog from './RestoreMemberDialog.vue'
import DeleteMemberDialog from '@/components/organisms/members/DeleteMemberDialog.vue'
import notification from '@/utils/notification'
import { DataTableHeader } from 'vuetify'
import _includes from 'lodash/includes'
import { FetchResult } from '@/store/models/Model'
import { UserTag } from '@/store/models/Tag'
import ApproveDialog from '@/components/organisms/members/ApproveDialog.vue'
import Department from '@/store/models/Department'

@Component({
  components: {
    DeleteMemberDialog,
    RestoreMemberDialog,
    SuspendMemberDialog,
    EditMemberDialog,
    ApproveDialog,
    TagGroup,
  },
})
export default class MemberListComponent extends Vue {
  readonly icons = {
    mdiPencil,
    mdiDelete,
    mdiDeleteRestore,
    mdiDeleteForever,
  }
  options: Partial<{
    sortBy: string[]
    sortDesc: boolean[]
    page: number
    itemsPerPage: number
  }> = {}
  loading = true
  totalCount = 0
  editingUser: Record = {}
  editDialog = false
  approveDialog = false
  chosenDepartment: Record
  chosenTags: string[] = []
  searchString = ''
  status: 'all' | UserStatus = 'all'
  searchDepartment = null

  get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t('user.email') as string,
        value: 'email',
      },
      {
        text: this.$t('user.employeeId') as string,
        value: 'employee_id',
      },
      {
        text: this.$t('user.name') as string,
        value: 'name',
        width: '180px',
      },
      {
        text: this.$t('user.department') as string,
        value: 'department.name',
        sortable: false,
      },
      {
        text: this.$t('user.tags') as string,
        value: 'tags',
        sortable: false,
      },
      {
        text: this.$t('user.admin') as string,
        value: 'admin',
        align: 'center',
      },
      {
        text: this.$t('loginAt') as string,
        value: 'current_sign_in_at',
      },
      {
        text: this.$t('status') as string,
        value: 'status',
        align: 'center',
        sortable: false,
      },
    ]
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get departments(): Record[] {
    return Department.query().orderBy('display_order', 'asc').all()
  }

  get tags(): string[] {
    return _map(UserTag.all(), 'name')
  }

  get users(): Record[] {
    const { sortBy, sortDesc } = this.options
    const query = User.query().with(['department'])
    if (this.searchString !== '') {
      query.where((record) => {
        if (!_isNil(this.searchString) && this.searchString !== '') {
          const re = new RegExp(this.searchString.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&'))
          return re.test(record.email) || re.test(record.name)
        }
        return true
      })
    }
    if (!_isNil(this.chosenDepartment)) {
      query.where('department_id', this.chosenDepartment.id)
    }
    if (!_isNil(this.chosenTags) && this.chosenTags.length > 0) {
      query.where((record) => {
        for (const chosenTag of this.chosenTags) {
          if (_includes(record.tags, chosenTag)) return true
        }
        return false
      })
    }
    const isDeactivated = (record: Record): boolean => {
      return !_isNil(record.deleted_at)
    }
    const isInvited = (record: Record): boolean => {
      if (_isNil(record.invitation_sent_at)) return false
      return _isNil(record.invitation_accepted_at)
    }
    const isApplied = (record: Record): boolean => {
      return _isNil(record.approved_at)
    }
    const isActive = (record: Record): boolean => {
      if (isDeactivated(record)) return false
      if (isInvited(record)) return false
      if (isApplied(record)) return false
      return true
    }
    if (this.status === 'active') {
      query.where(isActive)
    }
    if (this.status === 'invited') {
      query.where((record) => !isDeactivated(record))
      query.where(isInvited)
    }
    if (this.status === 'applied') {
      query.where((record) => !isDeactivated(record))
      query.where(isApplied)
    }
    if (this.status === 'deactivated') {
      query.where(isDeactivated)
    }
    query.orderBy('deleted_at', 'desc')
    _forEach(sortBy, (key, index) => {
      query.orderBy(key, sortDesc[index] ? 'desc' : 'asc')
    })
    query.orderBy('id', 'asc')
    return query.get()
  }

  get usersPerPage(): Record[] {
    const { page, itemsPerPage } = this.options
    if (itemsPerPage > 0) {
      return this.users.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    }
    return this.users
  }

  clearSearchString(): void {
    this.options.page = 1
    this.searchString = ''
    this.loading = true
    this.fetch().finally(() => {
      this.loading = false
    })
  }

  changeDepartment(value: Record): void {
    this.searchDepartment = null
    this.options.page = 1
    this.chosenDepartment = value
    this.loading = true
    this.fetch().finally(() => {
      this.loading = false
    })
  }

  changeTags(value: string[]): void {
    this.options.page = 1
    this.chosenTags = value
    this.loading = true
    this.fetch().finally(() => {
      this.loading = false
    })
  }

  changeSearchString(value: string): void {
    this.options.page = 1
    this.searchString = value
    this.loading = true
    this.fetch().finally(() => {
      this.loading = false
    })
  }

  changeStatus(value: 'all' | UserStatus): void {
    this.options.page = 1
    this.status = value
    this.loading = true
    this.fetch().finally(() => {
      this.loading = false
    })
  }

  changeOptions(value: { sortBy: string[]; sortDesc: boolean[]; page: number; itemsPerPage: number }): void {
    this.options = value
    this.loading = true
    this.fetch().finally(() => {
      this.loading = false
    })
  }

  async fetch(): Promise<void> {
    await Promise.all([UserTag.fetchAll({ useCache: false }), Department.fetchAll({ useCache: false })])
    const { sortBy, sortDesc, page, itemsPerPage } = this.options
    const limit = itemsPerPage > 0 ? itemsPerPage : 25
    const condition: Partial<{
      email_or_name_cont: string
      tags_name_in: string[]
    }> = {}
    if (this.searchString !== '') {
      condition['email_or_name_cont'] = this.searchString
    }
    if (!_isNil(this.chosenDepartment)) {
      condition['department_id_eq'] = this.chosenDepartment.id
    }
    if (!_isNil(this.chosenTags) && this.chosenTags.length > 0) {
      condition['tags_name_in'] = this.chosenTags
    }
    if (this.status === 'invited') {
      condition['deleted_at_null'] = true
      condition['invitation_sent_at_not_null'] = true
      condition['invitation_accepted_at_null'] = true
    }
    if (this.status === 'applied') {
      condition['deleted_at_null'] = true
      condition['approved_at_null'] = true
    }
    if (this.status === 'deactivated') {
      condition['deleted_at_not_null'] = true
    }
    const params = new SearchCondition({
      condition,
      page,
      limit,
    }).toRansackParams()
    if (this.status === 'active') {
      params['q[deleted_at_null]'] = true
      params['q[g[0][m]]'] = 'or'
      params['q[g[0][g[0][invitation_sent_at_not_null]]]'] = true
      params['q[g[0][g[0][invitation_accepted_at_not_null]]]'] = true
      params['q[g[0][g[1][invitation_sent_at_null]]]'] = true
      params['q[g[0][g[1][approved_at_not_null]]'] = true
    }
    if (_isNil(params['q[s]'])) params['q[s]'] = []
    params['q[s]'].push(`deleted_at desc`)
    sortBy.forEach((key, index) => {
      params['q[s]'].push(`${key} ${sortDesc[index] ? 'desc' : 'asc'}`)
    })
    params['q[s]'].push(`id asc`)
    params['with_deleted'] = true // 削除メンバーを含む
    params['with_unaccepted'] = true // 招待に応じていないメンバーを含む
    params['with_unapproved'] = true // 承認していないメンバーを含む
    let result: FetchResult
    if (itemsPerPage === -1) {
      result = await User.fetchAll({ useCache: false, params })
    } else {
      result = await User.fetch({ useCache: false, params })
    }
    this.totalCount = result.totalCount
  }

  showDialog(item: Record): void {
    this.editingUser = item
    this.editDialog = true
  }

  showApproveDialog(item: Record): void {
    this.editingUser = item
    this.approveDialog = true
  }

  async changeAdmin(user, admin): Promise<void> {
    const { data } = await this.$http.patch(['users', user.id].join('/'), {
      admin,
    })
    User.insertOrUpdate({ data })
    notification.success(this.$t('message.updated') as string)
  }

  async mounted(): Promise<void> {
    await Promise.all([UserTag.fetchAll({ useCache: false }), Department.fetchAll({ useCache: false })])
    await Me.load()
  }
}
</script>
<style lang="scss" scoped>
.p-member {
  &__list {
    &__row {
      cursor: pointer;
    }
  }
}
</style>
