<template>
  <div>
    <h3>{{ label }}</h3>
    <v-autocomplete
      v-if="$vuetify.breakpoint.xsOnly"
      :items="items.map(value => ({value, text: $t(value)}))"
      :loading="loading"
      :search-input.sync="search"
      :value="status"
      hide-no-data
      hide-selected
      @change="changeStatus"
    />
    <v-btn-toggle
      v-else
      :loading="loading"
      :value="status"
      clearable
      mandatory
      @change="changeStatus"
    >
      <v-btn
        v-for="(status) in items"
        :key="status"
        :value="status"
      >
        {{ $t(status) }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import { UserStatus, UserStatuses } from '@/store/models/Person'

const UserStatusChoices = [
  'all',
  ...UserStatuses,
] as const

type UserStatusChoice = typeof UserStatusChoices[number]

@Component
export default class extends Vue {
  @Prop({ default: '状態' })
  readonly label?: string

  @Prop({ default: 'active' })
  readonly value?: 'all' | UserStatus

  @Prop({ default: false })
  readonly loading?: boolean

  search = null

  get status(): UserStatusChoice {
    return this.value
  }

  get items() {
    return UserStatusChoices
  }

  @Emit('input')
  changeStatus(value: UserStatusChoice): UserStatusChoice {
    console.log(value)
    this.search = null
    return value
  }
}
</script>
