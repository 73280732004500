import notification from '@/utils/notification'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import { AxiosResponse } from 'axios'

const notifyResponseError = ({ data }: AxiosResponse): void => {
  notification.error(
    _filter(
      _map(data, (value) => {
        if (typeof value === 'string') {
          return value
        }
        if (Array.isArray(value)) {
          return value.join('\n')
        }
        return null
      }),
      (value) => value !== null,
    ).join('\n'),
  )
}
export default notifyResponseError
