<template>
  <PanelBase
    :open="open"
    title="メンバー"
    @update:open="$emit('update:open', $event)"
  >
    <template #help>
      <span>閲覧しているグループに所属するメンバーの一覧です。</span>
    </template>
    <v-list>
      <v-skeleton-loader
        v-if="loading"
        type="list-item-avatar"
      />
      <v-list-item
        v-for="user in users"
        v-else
        :key="user.id"
      >
        <v-list-item-avatar size="24">
          <img :src="user.avatarImage">
        </v-list-item-avatar>
        <v-list-item-content>
          {{ user.name ? user.displayName : user.email }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template #actions>
      <v-btn
        :to="{ name: 'members' }"
        depressed
        small
      >
        <v-icon>{{ icons.mdiAccountMultiplePlusOutline }}</v-icon>
        メンバーを追加
      </v-btn>
    </template>
  </PanelBase>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Collection, Item } from '@vuex-orm/core'
import _isNil from 'lodash/isNil'
import { User, UserStatus } from '@/store/models/Person'
import { mdiAccountMultiplePlusOutline } from '@mdi/js'
import _includes from 'lodash/includes'
import { PanelBase } from '@/components/organisms/dashboard/panel'
import * as http from '@/utils/http'
import Department from '@/store/models/Department'

@Component({
  name: 'dashboard-members',
  components: {
    PanelBase,
  },
})
export default class extends Vue {
  @Prop({ default: {} })
  readonly value!: {
    dates: [string, string]
    tags: string[]
    status: 'all' | UserStatus
    department: Item<Department>
  }
  @Prop({ default: false })
  readonly loading?: boolean
  @Prop({ default: false })
  readonly open?: boolean
  readonly icons = {
    mdiAccountMultiplePlusOutline,
  }

  get dates(): [string, string] {
    return this.value.dates
  }

  get tags(): string[] {
    return this.value.tags
  }

  get status(): 'all' | UserStatus {
    return this.value.status
  }

  get department(): Item<Department> {
    return this.value.department
  }

  get users(): Collection<User> {
    const query = User.queryByStatus(this.status).where((record) => record.id > 0)
    if (!_isNil(this.tags) && this.tags.length > 0) {
      query.where((record) => {
        for (const tag of this.tags) {
          if (_includes(record.tags, tag)) return true
        }
        return false
      })
    }
    if (this.department) {
      query.where('department_id', this.department.id)
    }
    return query.get()
  }

  async fetchUsers(): Promise<void> {
    const sort: http.SearchSort = [
      { name: 'deleted_at', order: 'desc' },
      { name: 'id', order: 'asc' },
    ]
    const params = <Partial<http.RansackParams>>Object.assign(
      {},
      new http.SearchCondition({ sort }).toRansackParams(),
      User.createSearchCondition({
        departmentId: this.department?.id,
        tags: this.tags,
        status: this.status,
      }),
    )
    await User.fetchAll({ useCache: false, params })
  }

  async fetch(): Promise<void> {
    this.$emit('update:loading', true)
    try {
      await this.fetchUsers()
    } finally {
      this.$emit('update:loading', false)
    }
  }
}
</script>
