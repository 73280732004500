<template>
  <div
    class="c-messageEditor"
    @compositionend="_handleCompositionEnd"
    @compositionstart="_handleCompositionStart"
    @compositionupdate="_handleCompositionUpdate"
    @keydown.stop="_handleKeydown"
  >
    <v-sheet
      class="mb-2 flex-grow-1"
      elevation="0"
      outlined
      rounded
    >
      <v-toolbar
        class="c-messageEditor__toolbar rounded-t"
        flat
        height="42px"
      >
        <EmojiPicker
          v-model="emojiPicker"
          @select="selectEmoji"
        >
          <template #activator="{ on }">
            <v-btn
              :disabled="preview === 'preview'"
              :elevation="0"
              class="px-0 mr-1"
              height="28px"
              min-height="28px"
              min-width="28px"
              small
              text
              v-on="on"
            >
              <v-icon size="18">
                {{ icons.mdiEmoticonExcitedOutline }}
              </v-icon>
            </v-btn>
          </template>
        </EmojiPicker>
        <v-divider
          v-if="image"
          class="c-messageEditor__toolbarDivider"
          vertical
        />
        <v-btn
          v-if="image"
          :disabled="preview === 'preview'"
          :elevation="0"
          class="mr-1"
          height="28px"
          min-height="28px"
          min-width="28px"
          small
          text
          @click="$refs.imgFile.click()"
        >
          <v-icon size="18">
            {{ icons.mdiImageOutline }}
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn-toggle
          v-model="preview"
          class="rounded pa-0 ma-0"
          group
        >
          <v-btn
            :elevation="0"
            height="28px"
            min-height="28px"
            min-width="28px"
            small
            text
            value="preview"
          >
            <v-icon size="18">
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>
      <v-divider />
      <div
        :style="{ maxHeight }"
        class="c-messageEditor__main rounded-b"
      >
        <div
          v-show="preview !== 'preview'"
          class="c-messageEditor__input"
        >
          <pre><p
            v-for="(line, idx) in lines"
            :key="idx"
            v-html="line"
          /></pre>
          <AmazonesTextarea
            ref="textarea"
            v-model="text"
            :placeholder="placeholder"
            :style="textareaStyle"
            @blur="$emit('blur')"
            @focus="$emit('focus')"
            @focusin="$emit('focusin')"
            @focusout="$emit('focusout')"
            @selectionchange="_handleSelectionChange"
          />
        </div>
        <div
          v-show="preview === 'preview'"
          class="c-messageEditor__view"
          v-html="$marked(text)"
        />
      </div>
    </v-sheet>
    <ImageList
      v-if="image"
      :files="files"
      :is-mine="true"
      @delete="deleteImage"
    />
    <v-messages
      :color="validationState"
      :dark="dark"
      :light="light"
      :value="messagesToDisplay"
      class="flex-grow-0"
      role="alert"
    />
    <input
      v-if="image"
      ref="imgFile"
      accept="image/*"
      class="d-none"
      type="file"
      @change="uploadImage"
    >
    <CompletionList
      v-if="TagModel"
      :items="completionItems"
      :position-x="completionListPos.x"
      :position-y="completionListPos.y"
      :select-index="selectIndex"
      @input="selectTag"
    />
  </div>
</template>
<script lang="ts">
import Component, { mixins } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import Validatable from 'vuetify/lib/mixins/validatable'
import { InputValidationRule } from 'vuetify'
import Attachment from '@/store/models/Attachment'
import notification from '@/utils/notification'
import { Record } from '@vuex-orm/core'
import ImageList from '@/components/organisms/ImageList.vue'
import _find from 'lodash/find'
import _isNil from 'lodash/isNil'
import Tag, { TweetTag } from '@/store/models/Tag'
import { SearchCondition } from '@/utils/http'
import CompletionList from '@/components/organisms/editor/CompletionList.vue'
import { mdiEmoticonExcitedOutline, mdiEyeOutline, mdiImageOutline } from '@mdi/js'
import EmojiPicker from '@/components/organisms/EmojiPicker.vue'
import { EmojiValue as _EmojiValue } from '@/utils/emoji'
import AmazonesTextarea from '@/components/organisms/forms/AmazonesTextarea.vue'
import getCaretCoordinates from 'textarea-caret'
import Debounce from '@/decorators/vue-debounce-decorator'
import { TextSelection as _TextSelection } from '@/components/organisms/forms/types'

type EmojiValue = _EmojiValue
type TextSelection = _TextSelection

export interface MessageEditorValue {
  text: string
  attachments?: Record[]
}

const matcher = /\B#([^\s]*)$/
@Component({
  components: { ImageList, CompletionList, EmojiPicker, AmazonesTextarea },
  inheritAttrs: false,
})
export default class extends mixins(Validatable) {
  $refs: {
    imgFile: HTMLInputElement
    textarea: AmazonesTextarea
  }
  readonly icons = {
    mdiEmoticonExcitedOutline,
    mdiImageOutline,
    mdiEyeOutline,
  }
  @Prop({ default: [] })
  readonly attachments: Record[]
  @Prop({ default: '' })
  readonly value: string
  @Prop({ default: false })
  readonly image?: boolean
  @Prop({ default: null })
  readonly TagModel?: typeof Tag
  @Prop({ default: '200px' })
  readonly maxHeight?: string
  @Prop({ default: '' })
  readonly placeholder?: string
  emojiPicker = false
  completionItems: string[] = []
  completionListPos: { x: number; y: number } = { x: 0, y: 0 }
  selectIndex = 0
  preview = ''
  searchWord = ''
  textareaStyle = {}
  selection: TextSelection = { start: 0, end: 0 }
  isComposing = false

  get text(): string {
    return this.value
  }

  set text(value: string) {
    this.vInternalValue = value
  }

  get lines(): string[] {
    if (_isNil(this.text)) return []
    return this.text.split('\n').map((line) => line || '<br>')
  }

  get files(): Record[] {
    return this._attachments.map((attachment) => attachment.file)
  }

  // region Validatable
  get messagesToDisplay(): string[] {
    if (!this.vHasMessages) return []
    return this.vValidations
      .map((validation: string | InputValidationRule) => {
        if (typeof validation === 'string') return validation
        const validationResult = validation(this.vInternalValue)
        return typeof validationResult === 'string' ? validationResult : ''
      })
      .filter((message) => message !== '')
  }

  // HACK: vuetifyのmixinの変数が正しく読み込まれないのでラップする
  get vValidations(): Array<string | InputValidationRule> {
    // @ts-ignore
    return this.validations
  }

  get vHasMessages(): boolean {
    // @ts-ignore
    return this.hasMessages
  }

  get vInternalValue(): string {
    // @ts-ignore
    return this.internalValue
  }

  set vInternalValue(value: string) {
    // @ts-ignore
    this.internalValue = value
  }

  // endregion
  get _attachments(): Record[] {
    return this.attachments || []
  }

  set _attachments(value: Record[]) {
    this.changeAttachments(value)
  }

  @Watch('value')
  _valueChanged(): void {
    this.preview = ''
  }

  focus(options?: FocusOptions): void {
    this.$refs.textarea.focus(options)
  }

  selectEmoji(emoji: EmojiValue): void {
    this.emojiPicker = false
    const { start, end } = this.selection
    const before = this.text.slice(0, start) + emoji.native
    const after = this.text.slice(end)
    this.setTextAndCaret(`${before}${after}`, before.length)
  }

  selectTag(tag: string): void {
    this.clearCompletionItems()
    const { start, end } = this.selection
    const before = this.text.slice(0, start).replace(matcher, `${tag} `)
    const after = this.text.slice(end)
    this.setTextAndCaret(`${before}${after}`, before.length)
  }

  setTextAndCaret(text: string, position: number): void {
    this.text = text
    this.$nextTick(() => {
      this.$refs.textarea.setSelectionRange(position, position)
      this.focus()
    })
  }

  clearCompletionItems(): void {
    this.selectIndex = 0
    this.searchWord = ''
    this.completionItems = []
  }

  @Debounce()
  showTagList(): void {
    if (_isNil(this.TagModel)) return this.clearCompletionItems()
    if (this.isComposing) return this.clearCompletionItems()
    const { start } = this.selection
    const before = this.text.slice(0, start)
    const matches = before.match(matcher)
    if (_isNil(matches)) return this.clearCompletionItems()
    this.searchWord = matches[1]
    const beforeWord = before.replace(matcher, '')
    this.searchTags(matches[1]).then((tags) => {
      const caret = getCaretCoordinates(this.$refs.textarea.$el, beforeWord.length)
      const textRect = this.$refs.textarea.$el.getBoundingClientRect()
      this.completionListPos = {
        x: caret.left + textRect.left,
        y: caret.top + textRect.top + caret.height,
      }
      this.selectIndex = 0
      this.completionItems = tags.map((tag) => `#${tag.name}`)
    })
  }

  async searchTags(word: string): Promise<Record[]> {
    const params = new SearchCondition({
      condition: { 'name_start': word, s: 'name' },
      limit: 10,
    }).toRansackParams()
    await this.TagModel.fetch({ params, useCache: false })
    return this.TagModel.query()
      .where((record) => record.name.startsWith(word))
      .orderBy('name')
      .limit(10)
      .get()
  }

  @Emit('changeAttachments')
  changeAttachments(value: Record[]): Record[] {
    return value
  }

  _handleKeydown(event: KeyboardEvent): KeyboardEvent {
    if (this.completionItems.length > 0) {
      const specialKey = event.metaKey || event.altKey || event.shiftKey || event.ctrlKey
      if (!specialKey && event.key === 'ArrowDown') {
        event.preventDefault()
        event.stopPropagation()
        if (this.selectIndex >= this.completionItems.length - 1) return
        this.selectIndex++
        return
      }
      if (!specialKey && event.key === 'ArrowUp') {
        event.preventDefault()
        event.stopPropagation()
        if (this.selectIndex <= 0) return
        this.selectIndex--
        return
      }
      if (event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault()
        event.stopPropagation()
        this.selectTag(this.completionItems[this.selectIndex])
        return
      }
      if (event.key === 'Escape') {
        event.preventDefault()
        event.stopPropagation()
        this.completionItems = []
        return
      }
    }
    this.$emit('keydown', event)
  }

  @Emit('beginUpload')
  _beginUpload(): void {
    return
  }

  @Emit('endUpload')
  _endUpload(): void {
    return
  }

  uploadImage(event: Event): void {
    const { files } = event.target as HTMLInputElement
    if (files.length === 0 || _isNil(files[0])) {
      this.$refs.imgFile.value = null
      return
    }
    if (!files || files.length === 0) {
      this.$refs.imgFile.value = null
      return
    }
    if (this._attachments.length > 12) {
      this.$refs.imgFile.value = null
      return
    }
    const file: File = files[0]
    const megaByte = 10
    if (file.size > megaByte * 1024 * 1024) {
      notification.error(this.$t('message.fileSizeError', { megaByte }) as string)
      this.$refs.imgFile.value = null
      return
    }
    this._beginUpload()
    const index = this._attachments.push({ file: { url: '' } }) - 1
    const formData = new FormData()
    formData.append('attachment[file]', file)
    this.$http
      .post(Attachment.endpoint(), formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        this.$set(this._attachments, index, data)
      })
      .finally(() => {
        this.$refs.imgFile.value = null
        this._endUpload()
      })
  }

  async deleteImage(file: Record): Promise<void> {
    const attachment = _find(this._attachments, (attachment) => attachment.file.id === file.id)
    const { status } = await this.$http.delete(['attachments', attachment.id].join('/'))
    if (status !== 204) {
      notification.error(this.$t('message.failed') as string)
      return
    }
    this._attachments = this._attachments.filter((attachment) => attachment.file.id !== file.id)
    notification.success(this.$t('message.deleted') as string)
  }

  mounted(): void {
    TweetTag.fetchAll()
  }

  @Debounce()
  _handleSelectionChange(value: TextSelection): void {
    this.selection = value
    this.showTagList()
  }

  _handleCompositionEnd(): void {
    this.isComposing = false
    this.showTagList()
  }

  _handleCompositionStart(): void {
    this.isComposing = true
  }

  _handleCompositionUpdate(): void {
    this.isComposing = true
  }
}
</script>
<style lang="scss" scoped>
@import 'node_modules/vuetify/src/components/VInput/variables';
@import 'src/styles/variables';

.c-messageEditor {
  position: relative;
  display: flex;
  flex-direction: column;

  &__toolbar {
    // noinspection CssInvalidPseudoSelector
    > :deep(.v-toolbar__content) {
      padding: 6px;
    }

    &Divider {
      align-self: center;
      height: calc(100% - 8px);
      min-height: calc(100% - 8px);
      margin-right: 10px;
      margin-left: 10px;
    }

    .v-btn {
      padding: 0;
      margin: 0;
      color: #757575;
      background-color: #fff;

      &:not(.v-btn--round).v-size--small {
        padding: 0;
        margin: 0;
      }
    }

    .v-btn-toggle {
      .v-btn {
        border: none;
      }
    }
  }

  &__main {
    padding: 0;
    overflow-y: auto;
    background-color: #fff;
  }

  &__view {
    min-width: 100%;
    max-width: 100%;
    max-height: 400px;
    padding: 20px;
    overflow: auto;
    overflow-wrap: anywhere;
    border: none;
    outline: none;
  }

  &__input {
    position: relative;
    display: block;

    textarea,
    pre {
      box-sizing: border-box;
      padding: 20px;
      overflow: hidden;
      font-family: $body-font-family;
      font-size: $font-size-root;
      font-style: normal;
      font-weight: normal;
      line-height: $line-height-root;
      word-break: break-all;
    }

    textarea {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      resize: none;
      border: none;
      outline: none;
    }

    pre {
      margin: 0;
      white-space: pre-wrap;
      visibility: hidden;

      > p {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.error--text {
  animation: v-shake .6s map-get($transition, 'swing');
}
</style>
