<template>
  <v-card
    :class="{ 'c-dashboard-panel--opened': opened }"
    class="c-dashboard-panel"
  >
    <v-card-title class="c-dashboard-panel__header py-2 px-3">
      <span class="c-dashboard-panel__title">{{ title }}</span>
      <v-spacer />
      <Help v-if="!helpable">
        <slot name="help" />
      </Help>
      <v-btn
        v-if="openable"
        icon
        @click="toggle"
      >
        <v-icon class="c-dashboard-panel__toggle">
          {{ icons.expand }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-expand-transition>
      <div
        v-show="opened"
        class="c-dashboard-panel__body"
      >
        <v-card-text class="c-dashboard-panel__content text-body-2 pa-0">
          <slot />
        </v-card-text>
        <v-card-actions class="text-overline justify-center py-2 px-3">
          <slot name="actions" />
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Help from '@/components/organisms/help/Help.vue'
import { VuetifyIcon } from 'vuetify/types/services/icons'

@Component({
  name: 'panel-base',
  components: { Help },
})
export default class PanelBase extends Vue {
  @Prop({ default: '' })
  readonly title!: string
  @Prop({ default: undefined })
  readonly open?: boolean

  get opened(): boolean {
    if (this.open === undefined) return true
    return this.open
  }

  set opened(value: boolean) {
    this.$emit('update:open', value)
  }

  get openable(): boolean {
    return this.open !== undefined
  }

  get icons(): { [key: string]: VuetifyIcon } {
    return {
      expand: this.$vuetify.icons.values.expand,
    }
  }

  get helpable(): boolean {
    return !this.$slots['help']
  }

  toggle(): void {
    this.opened = !this.opened
  }
}
</script>
<style lang="scss" scoped>
@import 'node_modules/vuetify/src/styles/settings/index';

$border: 1px solid #dedede;

.c- {
  &dashboard-panel {
    &--opened {
      .c-dashboard-panel__toggle {
        transform: rotate(-180deg);
      }
    }

    &__content {
      height: 240px;
      max-height: 240px;
      overflow-y: auto;
    }

    &__header {
      flex-wrap: nowrap;
      font-size: 1rem;
      white-space: nowrap;
    }

    &__body {
      min-height: 100%;
    }

    &__title {
      font-size: .875rem;
    }
  }

  &help-container {
    position: relative;
  }

  &help {
    max-width: 280px;

    &__btn {
      &--absolute {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
}
</style>
