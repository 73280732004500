<template>
  <v-chip-group
    v-model="chosenTag"
    :multiple="multiple"
    column
  >
    <v-chip
      v-for="tag in tags"
      :key="tag.id"
      :disabled="loading"
      :value="tag.name"
      filter
    >
      {{ tag.name }}
    </v-chip>
  </v-chip-group>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import { UserTag } from '@/store/models/Tag'

@Component
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: string | string[]
  @Prop({ default: true })
  readonly loading: boolean
  @Prop({ default: false })
  readonly multiple?: boolean

  get chosenTag(): string | string[] {
    if (this.multiple === false) {
      return this.value
    } else {
      return this.value ? this.value : []
    }
  }

  set chosenTag(value: string | string[]) {
    this.$emit('input', value)
  }

  get tags(): Record[] {
    return UserTag.all()
  }

  mounted(): void {
    UserTag.fetchAll()
  }
}
</script>
