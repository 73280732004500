<template>
  <v-container>
    <v-row class="ma-0 pa-0">
      <v-expansion-panels class="p-dashboard__filter mx-2">
        <v-expansion-panel>
          <v-expansion-panel-header v-slot="{ open }">
            フィルター {{ open ? '' : `: ${conditionText}` }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                lg="3"
                xl="3"
              >
                <v-menu
                  v-model="monthPickerDialog"
                  :close-on-click="false"
                  :close-on-content-click="false"
                  :disabled="loading"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="month"
                      :disabled="loading"
                      :prepend-icon="icons.mdiCalendar"
                      autocomplete="off"
                      hide-details
                      readonly
                      single-line
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="pickedMonth"
                    :locale="$i18n.locale"
                    no-title
                    show-current
                    type="month"
                  >
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="cancelPickMonth"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="loading"
                      color="primary"
                      text
                      @click="changeMonth"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        lg="6"
        md="6"
        xl="6"
      >
        <PanelBase title="おくった感謝が多いメンバー">
          <AwardList
            :items="givesCount"
            :loading="loadingGives"
          />
        </PanelBase>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
        xl="6"
      >
        <PanelBase title="もらった感謝が多いメンバー">
          <AwardList
            :items="takesCount"
            :loading="loadingTakes"
          />
        </PanelBase>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        lg="6"
        md="6"
        xl="6"
      >
        <PanelBase title="おくったリアクションが多いメンバー">
          <AwardList
            :items="reactionGivesCount"
            :loading="loadingReactionGives"
          />
        </PanelBase>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
        xl="6"
      >
        <PanelBase title="もらったリアクションが多いメンバー">
          <AwardList
            :items="reactionTakesCount"
            :loading="loadingReactionTakes"
          />
        </PanelBase>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import { PanelBase } from '@/components/organisms/dashboard/panel'
import { RansackParams, SearchCondition } from '@/utils/http'
import AwardGiveCount from '@/store/models/AwardGiveCount'
import AwardTakeCount from '@/store/models/AwardTakeCount'
import AwardReactionGiveCount from '@/store/models/AwardReactionGiveCount'
import AwardReactionTakeCount from '@/store/models/AwardReactionTakeCount'
import Model from '@/store/models/Model'
import AwardList, { Item } from '@/components/organisms/award/AwardList.vue'
import _isNil from 'lodash/isNil'
import { mdiCalendar } from '@mdi/js'

@Component({
  components: {
    AwardList,
    PanelBase,
  },
})
export default class extends Vue {
  readonly icons = {
    mdiCalendar,
  }
  open = true
  loadingGives = true
  loadingTakes = true
  loadingReactionGives = true
  loadingReactionTakes = true
  condition: {
    month: string
  } = {
    month: null,
  }
  monthPickerDialog = false
  pickedMonth: string = null

  get loading(): boolean {
    return this.loadingGives || this.loadingTakes || this.loadingReactionGives || this.loadingReactionTakes
  }

  get conditionText(): string {
    const conditionTexts = []
    conditionTexts.push(this.month)
    return conditionTexts.join(', ')
  }

  get month(): string {
    if (_isNil(this.condition.month)) {
      return this.$moment().startOf('month').format('YYYY年MM月')
    }
    return this.condition.month
  }

  set month(value: string) {
    this.condition.month = value
  }

  get givesCount(): Item[] {
    return this.extractCountRanking(AwardGiveCount)
  }

  get takesCount(): Item[] {
    return this.extractCountRanking(AwardTakeCount)
  }

  get reactionGivesCount(): Item[] {
    return this.extractCountRanking(AwardReactionGiveCount)
  }

  get reactionTakesCount(): Item[] {
    return this.extractCountRanking(AwardReactionTakeCount)
  }

  cancelPickMonth(): void {
    this.pickedMonth = this.month
    this.monthPickerDialog = false
  }

  changeMonth(): void {
    this.month = this.pickedMonth
    this.monthPickerDialog = false
    this.fetch()
  }

  async fetchGivesCount(): Promise<void> {
    this.loadingGives = true
    await AwardGiveCount.fetch({
      params: this.createSearchParams({ orderBy: ['count desc'] }),
      useCache: false,
    })
    this.loadingGives = false
  }

  async fetchTakesCount(): Promise<void> {
    this.loadingTakes = true
    await AwardTakeCount.fetch({
      params: this.createSearchParams({ orderBy: ['count desc'] }),
      useCache: false,
    })
    this.loadingTakes = false
  }

  extractCountRanking(model: typeof Model): Item[] {
    return model
      .query()
      .with(['user'])
      .where(({ count }) => count > 0)
      .orderBy('count', 'desc')
      .all()
      .reduce((result: Item[], { user, count }: Record, index): Item[] => {
        const rank = ((): number => {
          if (index === 0) return 1
          const prev = result[index - 1]
          if (prev.count === count) {
            return prev.rank
          }
          return index + 1
        })()
        result.push({
          user,
          count,
          rank,
        })
        return result
      }, [])
      .filter(({ rank }) => rank <= 5)
  }

  async fetchReactionGivesCount(): Promise<void> {
    this.loadingReactionGives = true
    await AwardReactionGiveCount.fetch({
      params: this.createSearchParams({ orderBy: ['reaction_count desc'] }),
      useCache: false,
    })
    this.loadingReactionGives = false
  }

  async fetchReactionTakesCount(): Promise<void> {
    this.loadingReactionTakes = true
    await AwardReactionTakeCount.fetch({
      params: this.createSearchParams({ orderBy: ['reaction_count desc'] }),
      useCache: false,
    })
    this.loadingReactionTakes = false
  }

  createSearchParams({ orderBy }: { orderBy?: string[] }): Partial<RansackParams> {
    const condition = {}
    const params = new SearchCondition({
      page: 1,
      condition,
    }).toRansackParams()
    if (!_isNil(orderBy)) {
      if (_isNil(params['q[s]'])) params['q[s]'] = []
      orderBy.forEach((value) => {
        params['q[s]'].push(value)
      })
    }
    return {
      from: this.$moment(this.month, 'YYYY-MM').startOf('month').format(),
      to: this.$moment(this.month, 'YYYY-MM').endOf('month').format(),
      ...params,
    }
  }

  mounted(): void {
    this.pickedMonth = this.month
    this.fetch()
  }

  fetch(): void {
    Promise.all([
      this.fetchGivesCount(),
      this.fetchTakesCount(),
      this.fetchReactionGivesCount(),
      this.fetchReactionTakesCount(),
    ])
  }
}
</script>
