<template>
  <MyContainer>
    <template #title>
      コミュニティー管理
    </template>
    <MyContent>
      <v-form
        :disabled="creating"
        @submit.stop.prevent="createCommunity"
      >
        <v-card>
          <v-card-title class="text-h5">
            コミュニティー作成
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="community.name"
              v-validate="'required'"
              :error-messages="errors.collect('community.name')"
              :label="$t('community.name')"
              data-vv-name="community.name"
              required
              type="text"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="community.name.length === 0 || creating"
              :loading="creating"
              color="primary"
              type="submit"
            >
              {{ $t('button.create') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </MyContent>
    <MyContent>
      <v-card>
        <v-card-title class="text-h5">
          コミュニティー一覧
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="communities"
          :loading="loading || creating"
          class="elevation-1"
        >
          <template #[`item.name`]="{ item }">
            <a
              :href="`${communityHost}/${item.channel}`"
              rel="noopener"
              target="_blank"
            >{{ item.name }}</a>
          </template>
        </v-data-table>
      </v-card>
    </MyContent>
  </MyContainer>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import Community from '@/store/models/Community'
import notification from '@/utils/notification'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import _get from 'lodash/get'
import { DataTableHeader } from 'vuetify'
import MyContainer from '@/components/atoms/MyContainer.vue'
import CategoryList from '@/components/organisms/categories/CategoryList.vue'
import MyContent from '@/components/atoms/MyContent.vue'

@Component({
  components: { MyContent, CategoryList, MyContainer },
  $_veeValidate: {
    validator: 'new',
  },
})
export default class extends Vue {
  creating = false
  loading = true
  community: {
    name: string
  } = {
    name: '',
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get communityHost(): string {
    return _get(this.currentUser, 'communityHost')
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t('community.name') as string,
        value: 'name',
      },
    ]
  }

  get communities(): Record[] {
    return Community.query().orderBy('name').all()
  }

  async mounted(): Promise<void> {
    this.loading = true
    Community.fetchAll({ useCache: false, url: 'my/communities' }).finally(() => (this.loading = false))
  }

  async createCommunity(): Promise<void> {
    this.creating = true
    this.$http
      .post('communities', this.community)
      .then(({ data }) => {
        this.community = {
          name: '',
        }
        Community.insertOrUpdate({ data }).then(() => notification.success(this.$t('message.created') as string))
      })
      .finally(() => {
        this.creating = false
      })
  }
}
</script>
