<template>
  <v-container
    class="l-main fill-height"
    fluid
  >
    <v-row
      class="ma-0 pa-0 l-content"
    >
      <v-col class="text-center">
        <section class="p-agreement">
          <div class="p-agreement__title">
            <h1 class="c-title--screen">
              支払い方法
            </h1>
          </div>
          <section>
            <AgreementManagement />
          </section>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import AgreementManagement from '@/components/organisms/agreements/AgreementManagement.vue'

@Component({
  components: {
    AgreementManagement,
  },
})
export default class extends Vue {
}
</script>
