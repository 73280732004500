import Timeline from '@/store/models/Timeline'
import { User } from '@/store/models/Person'
import _get from 'lodash/get'
import Me from '@/store/models/Me'
import { Record } from '@vuex-orm/core'
import Comment from '@/store/models/Comment'
import Tweet from '@/store/models/Tweet'
import Reaction from '@/store/models/Reaction'
import Gift from '@/store/models/Gift'

const tenantChannels = [
  {
    name: 'TimelineChannel',
    model: Timeline,
  },
  {
    name: 'GiftChannel',
    model: Gift,
  },
  {
    name: 'TweetChannel',
    model: Tweet,
  },
  {
    name: 'ReactionChannel',
    model: Reaction,
  },
  {
    name: 'CommentChannel',
    model: Comment,
  },
  {
    name: 'AppearanceChannel',
    methods: {
      async received(data) {
        await Me.load()
        const currentUser: Record = Me.query().first()
        if (!data.online && data.id === _get(currentUser, 'id')) {
          return this.appear()
        }
        User.insertOrUpdate({ data })
      },
      appear() {
        return this.perform('appear')
      },
      disappear() {
        return this.perform('disappear')
      },
    },
  },
]

export default tenantChannels
