<template>
  <v-container
    v-if="tweet"
    class="p-tweet"
  >
    <v-container
      :class="{ 'p-tweet__list--xs-only': $vuetify.breakpoint.xsOnly }"
      class="p-tweet__list"
    >
      <TweetListItem
        v-if="tweet"
        :value="tweet"
      />
      <v-divider />
      <CommentList
        :model="model"
        :value="comments"
      />
      <v-list-item
        v-if="!$vuetify.breakpoint.xsOnly"
        class="py-4"
      >
        <PostComment
          :commentable="tweet"
          :model="model"
        />
      </v-list-item>
    </v-container>
    <v-footer
      v-if="$vuetify.breakpoint.xsOnly"
      :class="{ 'p-tweet__footer--xs-only': $vuetify.breakpoint.xsOnly }"
      absolute
      class="p-tweet__footer justify-center d-flex flex-column flex-grow-1"
    >
      <PostComment
        :commentable="tweet"
        :model="model"
      />
    </v-footer>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import TweetListItem from '@/components/organisms/tweets/TweetListItem.vue'
import CommentList from '@/components/organisms/comments/CommentList.vue'
import PostComment from '@/components/organisms/comments/PostComment.vue'
import { Record } from '@vuex-orm/core'
import Tweet from '@/store/models/Tweet'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    TweetListItem,
    CommentList,
    PostComment,
  },
})
export default class extends Vue {
  model: typeof Tweet = Tweet

  get tweetId(): number {
    return +this.$route.params.id
  }

  get tweet(): Record {
    return Tweet.query()
      .with(['comments', 'comments.user', 'comments.user.feeling', 'user', 'feeling', 'reactions', 'reactions.user'])
      .find(this.tweetId)
  }

  get comments(): Record {
    if (!this.tweet) return []
    return this.tweet.comments
  }

  mounted(): void {
    this.fetch()
  }

  @Watch('tweetId')
  fetch(): void {
    Tweet.fetch(this.tweetId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        this.$router.replace({ name: 'timeline' })
        throw err
      })
  }
}
</script>
<style lang="scss" scoped>
.p-tweet {
  position: relative;
  z-index: 0;
  max-width: 100%;
  padding: 0;

  &__list {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;

    &--xs-only {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-right: 0;
      padding-bottom: 111px;
      padding-left: 0;
    }
  }

  &__footer {
    z-index: 4;
    min-height: 111px;

    &--xs-only {
      position: fixed;
    }
  }
}
</style>
