<template>
  <v-app>
    <Navigation v-model="drawer" />
    <Header @click:menu="drawer = !drawer" />
    <v-main>
      <router-view />
    </v-main>
    <ImagePreviewDialog />
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import Header from '@/apps/general/Header.vue'
import Navigation from '@/apps/general/Navigation.vue'
import ImagePreviewDialog from '@/components/organisms/ImagePreviewDialog.vue'

@Component({
  components: {
    ImagePreviewDialog,
    Header,
    Navigation,
  },
})
export default class extends Vue {
  drawer: boolean = null
}
</script>
<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all .3s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(10px);
}
</style>
