<template>
  <v-dialog
    :value="show"
    max-width="600px"
    @input="input"
  >
    <template #activator="{ on }">
      <slot
        :on="on"
        name="activator"
      />
    </template>
    <template #default="dialog">
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          通知設定
        </v-card-title>
        <v-card-text
          v-if="permission === 'denied'"
          class="pa-4"
        >
          <v-alert
            class="ma-0"
            outlined
            type="warning"
          >
            ブラウザーの設定で「通知」が許可されていません。プッシュ通知を受け取る場合は許可してください。
          </v-alert>
        </v-card-text>
        <v-card-text>
          <v-switch
            v-model="pushNotify"
            :disabled="permission === 'denied' || changingPushNotify"
            :loading="changingPushNotify"
            label="このブラウザーでプッシュ通知を受け取る"
            @change="changePushNotify"
            @click.native.stop="clickPushNotify"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn @click.stop="dialog.value = false">
            {{ $t('button.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { deleteToken, getMessaging, getToken } from 'firebase/messaging'

@Component
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  changingPushNotify = false
  pushNotify = false
  permission: NotificationPermission = 'default'
  pushManager = {
    get isSupported(): boolean {
      return 'PushManager' in window
    },
  }

  get show(): boolean {
    return this.value
  }

  clickPushNotify(): void {
    this.changingPushNotify = true
  }

  @Emit('input')
  input(value): boolean {
    return value
  }

  @Watch('value')
  async valueChanged(value): Promise<void> {
    if (!value) return
    this.permission = Notification.permission
    const registration = await navigator.serviceWorker.getRegistration()
    const pushSubscription = await registration.pushManager.getSubscription()
    this.pushNotify = !!pushSubscription
  }

  async changePushNotify(value): Promise<void> {
    if (!this.pushManager.isSupported) return
    if (!this.$firebaseApp) return
    const messaging = getMessaging(this.$firebaseApp)
    const registration = await navigator.serviceWorker.getRegistration()
    if (!registration) return
    const permission = await Notification.requestPermission()
    if (permission === 'denied') {
      this.pushNotify = false
      this.changingPushNotify = false
      this.permission = Notification.permission
      return
    }
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.VUE_VAPID_PUBLIC_KEY,
      serviceWorkerRegistration: registration,
    })
    if (value) {
      this.$http.post('my/push_tokens', { platform: 'GCM', token: currentToken }).finally(() => {
        this.changingPushNotify = false
      })
      return
    }
    Promise.all([deleteToken(messaging), this.$http.delete(['my/push_tokens', currentToken].join('/'))]).finally(() => {
      this.changingPushNotify = false
    })
  }
}
</script>
