<template>
  <v-system-bar
    v-if="visible"
    app
    class="p-overstaying-alert"
    fixed
    height="60"
  >
    <v-icon
      class="p-overstaying-alert__icon"
      color="warning"
    >
      {{ icons.mdiExclamation }}
    </v-icon>
    <div class="p-overstaying-alert__content">
      {{ $t('plans.trial') }}期間が過ぎています。<br>
      <router-link :to="{ name: 'agreements', hash: '#card' }">
        {{ $t('label.agreements') }}
      </router-link>
      よりクレジットカードを登録してください。
    </div>
    <v-spacer />
    <v-btn
      icon
      light
      rounded
      small
      type="warning"
    >
      <v-icon
        color="warning"
        @click="dismiss"
      >
        {{ icons.mdiCloseCircle }}
      </v-icon>
    </v-btn>
  </v-system-bar>
</template>
<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Me from '@/store/models/Me'
import { Record } from '@vuex-orm/core'
import { mdiCloseCircle, mdiExclamation } from '@mdi/js'

@Component
export default class extends Vue {
  icons = { mdiCloseCircle, mdiExclamation }
  closed = false

  get currentUser(): Record {
    return Me.query().with(['tenant']).first()
  }

  get visible(): boolean {
    if (this.closed) return false
    return this.currentUser?.tenant?.overstaying || false
  }

  mounted(): void {
    Me.load()
  }

  dismiss(): void {
    this.closed = true
  }
}
</script>
<style lang="scss" scoped>
.p-overstaying-alert {
  &.v-system-bar {
    box-sizing: border-box;
    color: var(--v-warning-base);
    caret-color: var(--v-warning-base);
    background: transparent;
    outline: 1px solid var(--v-warning-base);
    outline-offset: -1px;

    &::before {
      position: absolute;
      inset: 0;
      pointer-events: none;
      content: '';
      background-color: var(--v-warning-base);
      border-radius: inherit;
      opacity: .12;
    }

    .v-btn {
      .v-icon {
        margin: 0;
      }
    }
  }

  &__content {
    flex-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
  }

  &__icon {
    &.v-icon {
      min-width: 36px;
      height: 36px;
      margin-right: 16px;
      margin-left: 8px;

      &::after {
        position: absolute;
        inset: 0;
        content: '';
        background: var(--v-warning-base);
        border-radius: 50%;
        opacity: .16;
      }
    }
  }
}
</style>
