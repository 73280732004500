<template>
  <v-btn
    small
    @click.prevent.stop="onClick"
  >
    <v-icon left>
      {{ icons.mdiCommentOutline }}
    </v-icon>
    コメント
  </v-btn>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit } from 'vue-property-decorator'
import { mdiCommentOutline } from '@mdi/js'

@Component
export default class CommentActionComponent extends Vue {
  readonly icons = {
    mdiCommentOutline,
  }

  @Emit('click')
  onClick($event): Event {
    return $event
  }
}
</script>
