<template>
  <v-list-item>
    <v-list-item-avatar class="align-self-start c-list-item__avatar">
      <Avatar :user="gift.recipient" />
      <span class="c-feelingBadge">{{ _.get(gift, 'recipient_feeling.emoji', defaultFeeling) }}</span>
    </v-list-item-avatar>
    <v-list-item-content class="flex-column align-start">
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <router-link
          :to="{ name: 'member/home', params: { id: gift.recipient_id } }"
          class="c-recipient__name"
        >
          <strong>{{ _.get(gift, 'recipient.name') }}</strong>
        </router-link>
        <div class="timeline-date ml-2">
          {{ $d($moment(gift.created_at).toDate(), 'long') }}
        </div>
        <v-spacer />
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <div>
          <router-link :to="{ name: 'member/home', params: { id: gift.sender_id } }">
            <strong>{{ _.get(gift, 'sender.name') }}</strong>
          </router-link>
          さんから
          <template v-if="gift.point >0">
            {{ gift.point }} チップ 届きました。
          </template>
          <template v-else>
            感謝が届きました。
          </template>
        </div>
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <div>
          <TagGroup :value="_.map(gift.categories, 'name')" />
        </div>
      </v-row>
      <v-row
        v-if="gift.comments.length >0"
        class="ma-0 mt-2"
      >
        <v-col>{{ gift.comments.length }}件の返信があります。</v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>
<script lang="ts">
import Vue from 'vue'
import { defaultFeeling } from '@/store/models/Feeling'
import Avatar from '@/components/organisms/Avatar.vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import Gift from '@/store/models/Gift'
import TagGroup from '@/components/organisms/TagGroup.vue'

@Component({
  components: {
    Avatar,
    TagGroup,
  },
})
export default class TimelineGiftListItemComponent extends Vue {
  readonly defaultFeeling = defaultFeeling
  @Prop({ default: {} })
  readonly value!: Record

  get gift(): Record {
    return this.item
  }

  get item(): Record {
    return Gift.query()
      .with([
        'recipient',
        'recipient_feeling',
        'sender',
        'sender_feeling',
        'categories',
        'reactions',
        'reactions.user',
        'comments',
      ])
      .find(this.value.id)
  }

  get currentUser(): Record {
    return Me.query().first()
  }
}
</script>
<style lang="scss" scoped>
.c- {
  &list-item {
    &__avatar {
      overflow: visible;
    }
  }

  &recipient {
    &__name {
      font-size: .9rem;
    }
  }
}
</style>
