import Members from '@/pages/admin/Members.vue'
import Invitation from '@/pages/admin/Invitation.vue'
import Categories from '@/pages/admin/Categories.vue'
import Settings from '@/pages/admin/Settings.vue'
import Results from '@/pages/admin/Results.vue'
import Agreements from '@/pages/admin/Agreements.vue'
import Dashboard from '@/pages/admin/Dashboard.vue'
import Communities from '@/pages/admin/Communities.vue'
import Integrations from '@/pages/admin/Integrations.vue'
import Payments from '@/pages/admin/Payments.vue'
import { RouteConfig } from 'vue-router'
import Departments from '@/pages/admin/Departments.vue'
import Points from '@/pages/admin/Points.vue'

const meta = {
  auth: true,
  admin: true,
} as const
const routes: RouteConfig[] = [
  {
    name: 'admin',
    path: '',
    redirect: { name: 'admin/dashboard' },
    meta,
  },
  {
    name: 'invitation',
    path: 'invitation',
    component: Invitation,
    meta,
  },
  {
    name: 'members',
    path: 'members',
    component: Members,
    meta,
  },
  {
    name: 'points',
    path: 'points',
    component: Points,
    meta,
  },
  {
    name: 'categories',
    path: 'categories',
    component: Categories,
    meta,
  },
  {
    name: 'settings',
    path: 'settings',
    component: Settings,
    meta,
  },
  {
    name: 'results',
    path: 'results',
    component: Results,
    meta,
  },
  {
    name: 'agreements',
    path: 'agreements',
    component: Agreements,
    meta,
  },
  {
    name: 'payments',
    path: 'payments',
    component: Payments,
    meta,
  },
  {
    name: 'admin/dashboard',
    path: 'dashboard',
    component: Dashboard,
    meta: {
      ...meta,
      unavailablePlans: ['simple'],
    },
  },
  {
    name: 'communities',
    path: 'communities',
    component: Communities,
    meta: {
      ...meta,
      unavailablePlans: ['simple'],
    },
  },
  {
    name: 'integrations',
    path: 'integrations',
    component: Integrations,
    meta,
  },
  {
    name: 'departments',
    path: 'departments',
    component: Departments,
    meta,
  },
]
export default routes
