<template>
  <v-container class="p-dashboard">
    <v-expansion-panels class="p-dashboard__filter mx-2">
      <v-expansion-panel>
        <v-expansion-panel-header v-slot="{ open }">
          フィルター {{ open ? '' : `: ${conditionText}` }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ma-0 pa-0">
            <v-col
              cols="12"
              lg="6"
              md="12"
              sm="12"
              xl="6"
            >
              <SelectDate
                :loading="loading"
                :value="dates"
                @input="changeDates"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              md="12"
              sm="12"
              xl="6"
            >
              <UserStatusFilter
                :value="status"
                label="メンバーの状態で絞り込む"
                @input="changeStatus"
              />
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col
              cols="12"
              lg="6"
              md="12"
              sm="12"
              xl="6"
            >
              <DepartmentFilter
                :value="condition.department"
                label="メンバーを部署で絞り込む"
                @input="changeDepartment"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              md="12"
              sm="12"
              xl="6"
            >
              <TagFilter
                :value="tags"
                label="メンバーをタグで絞り込む（OR条件）"
                @input="changeTags"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="mx-2 mt-3">
      <v-btn-toggle
        :mandatory="panelsStatus.length >0"
        :value="panelsStatus"
        multiple
      >
        <v-btn
          hide-details
          small
          value="all panels are opened"
          @change="openAllPanels"
        >
          すべて開く
        </v-btn>
        <v-btn
          hide-details
          small
          value="all panels are closed"
          @change="closeAllPanels"
        >
          すべて閉じる
        </v-btn>
      </v-btn-toggle>
    </div>
    <div class="mx-2">
      <v-row class="ma-0 pa-0">
        <v-col
          v-for="(panel, index) in panels"
          :key="index"
          cols="12"
          lg="3"
          md="6"
          sm="6"
          xl="3"
        >
          <component
            :is="panel.component"
            ref="panelRefs"
            :loading.sync="loadingUsers"
            :open="panel.open"
            :value="{ dates, tags, status, department }"
            @update:open="updatePanelOpen(panel, $event)"
          />
        </v-col>
      </v-row>
    </div>
    <v-sheet
      class="p-dashboard__diagram mx-2"
      elevation="2"
      rounded
    >
      <CorrelationDiagram
        ref="diagram"
        :loading="loadingCorrelationDiagramData"
      />
    </v-sheet>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import CorrelationDiagram, { Link, Node } from '@/components/organisms/diagrams/CorrelationDiagram'
import { Prop } from 'vue-property-decorator'
import Me from '@/store/models/Me'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _sumBy from 'lodash/sumBy'
import _isNil from 'lodash/isNil'
import _find from 'lodash/find'
import * as http from '@/utils/http'
import SearchCondition from '@/components/organisms/SearchCondition.vue'
import _filter from 'lodash/filter'
import GiftSummary from '@/store/models/GiftSummary'
import { Record } from '@vuex-orm/core'
import _get from 'lodash/get'
import _maxBy from 'lodash/maxBy'
import SelectDate from '@/components/organisms/date/SelectDate.vue'
import SelectCategory from '@/components/organisms/categories/SelectCategory.vue'
import SelectTag from '@/components/organisms/tags/SelectTag.vue'
import { mdiAccountMultiplePlusOutline, mdiAccountSearch, mdiHelpCircleOutline } from '@mdi/js'
import DashboardContributor from '@/components/organisms/dashboard/DashboardContributor.vue'
import DashboardProblematicPerson from '@/components/organisms/dashboard/DashboardProblematicPerson.vue'
import DashboardUnsungHero from '@/components/organisms/dashboard/DashboardUnsungHero.vue'
import DashboardMembers from '@/components/organisms/dashboard/DashboardMembers.vue'
import cookies from 'js-cookie'
import { UserTag } from '@/store/models/Tag'
import Department from '@/store/models/Department'
import DepartmentFilter from '@/components/organisms/DepartmentFilter.vue'
import TagFilter from '@/components/organisms/TagFilter.vue'
import UserStatusFilter from '@/components/organisms/UserStatusFilter.vue'

type UserStatus = 'active' | 'invited' | 'applied' | 'deactivated'

const secure = process.env.NODE_ENV === 'production'
type Panels = DashboardMembers | DashboardContributor | DashboardProblematicPerson | DashboardUnsungHero
type TypeOfPanels =
  | typeof DashboardMembers
  | typeof DashboardContributor
  | typeof DashboardProblematicPerson
  | typeof DashboardUnsungHero
type PanelInfo = {
  name: string
  component: TypeOfPanels
  open: boolean
}
@Component({
  components: {
    UserStatusFilter,
    TagFilter,
    DepartmentFilter,
    SelectTag,
    SelectCategory,
    SelectDate,
    SearchCondition,
    CorrelationDiagram,
    DashboardContributor,
    DashboardProblematicPerson,
    DashboardUnsungHero,
    DashboardMembers,
  },
})
export default class extends Vue {
  $refs!: {
    diagram: CorrelationDiagram
    panelRefs: Panels[]
  }
  readonly icons = {
    mdiHelpCircleOutline,
    mdiAccountMultiplePlusOutline,
    mdiAccountSearch,
  }
  @Prop({ default: 'took' })
  readonly type!: 'took' | 'gave'
  loadingUsers = true
  loadingCorrelationDiagramData = true
  timeoutDiagram = 0
  isDestroyed = false
  panels: PanelInfo[] = [
    {
      name: 'members.panel.dashboard.admin',
      component: DashboardMembers,
      open: undefined,
    },
    {
      name: 'contributor.panel.dashboard.admin',
      component: DashboardContributor,
      open: undefined,
    },
    {
      name: 'problematicPerson.panel.dashboard.admin',
      component: DashboardProblematicPerson,
      open: undefined,
    },
    {
      name: 'unsungHero.panel.dashboard.admin',
      component: DashboardUnsungHero,
      open: undefined,
    },
  ]
  condition: {
    dates: [string, string]
    tags: string[]
    status: 'all' | UserStatus
    department: Record
  } = {
    dates: null,
    tags: [],
    status: 'active',
    department: null,
  }
  private dashboardMembers: DashboardMembers

  get departments(): Record[] {
    return Department.query().orderBy('display_order', 'asc').all()
  }

  get loading(): boolean {
    return this.loadingUsers || this.loadingCorrelationDiagramData
  }

  get conditionText(): string {
    const conditionTexts = []
    conditionTexts.push(
      [this.$moment(this.dates[0]).format('YYYY年MM月DD日'), this.$moment(this.dates[1]).format('YYYY年MM月DD日')].join(
        ' ~ ',
      ),
    )
    conditionTexts.push(this.$t(this.status))
    if (!_isNil(this.department)) {
      conditionTexts.push(this.department?.name)
    }
    if (!_isNil(this.tags) && this.tags.length > 0) {
      conditionTexts.push(this.tags.join(' / '))
    }
    return conditionTexts.join(', ')
  }

  get sourceProp(): 'sender' | 'recipient' {
    return this.type === 'took' ? 'sender' : 'recipient'
  }

  get targetProp(): 'sender' | 'recipient' {
    return this.type === 'took' ? 'recipient' : 'sender'
  }

  get dates(): [string, string] {
    if (_isNil(this.condition.dates)) {
      return [
        this.$moment().add(-1, 'week').startOf('month').format('YYYY-MM-DD'),
        this.$moment().add(-1, 'week').endOf('month').format('YYYY-MM-DD'),
      ]
    }
    return this.condition.dates
  }

  get tags(): string[] {
    if (_isNil(this.condition.tags)) return []
    return this.condition.tags
  }

  get status(): 'all' | UserStatus {
    if (_isNil(this.condition.status)) return 'active'
    return this.condition.status
  }

  get department(): Record {
    if (_isNil(this.condition.department)) return null
    return this.condition.department
  }

  get allPanelsAreOpened(): boolean {
    return this.panels.filter(({ open }) => open === false).length === 0
  }

  get allPanelsAreClosed(): boolean {
    return this.panels.filter(({ open }) => _isNil(open) || open === true).length === 0
  }

  get panelsStatus(): string[] {
    const panelsStatus = []
    if (this.allPanelsAreOpened) {
      panelsStatus.push('all panels are opened')
    }
    if (this.allPanelsAreClosed) {
      panelsStatus.push('all panels are closed')
    }
    return panelsStatus
  }

  openAllPanels(): void {
    if (this.allPanelsAreOpened) return
    this.panels.forEach((panel) => {
      this.updatePanelOpen(panel, true)
    })
  }

  closeAllPanels(): void {
    if (this.allPanelsAreClosed) return
    this.panels.forEach((panel) => {
      this.updatePanelOpen(panel, false)
    })
  }

  updatePanelOpen(panel: PanelInfo, value: boolean): void {
    panel.open = value
    cookies.set(panel.name, value, { path: '/', secure })
  }

  changeDates(dates: [string, string]): void {
    this.condition.dates = dates
    this.search()
  }

  changeTags(tags: string[]): void {
    this.condition.tags = tags
    this.search()
  }

  changeStatus(status: 'all' | UserStatus): void {
    this.condition.status = status
    this.search()
  }

  changeDepartment(department: Record): void {
    this.condition.department = department
    this.search()
  }

  makeNodes(items: Record[]): Node[] {
    if (items.length === 0) {
      return []
    }
    const baseRadius = 10
    const nodes: Node[] = []
    const totalPoint = _sumBy(items, 'points')
    const sourceGroup = _groupBy(items, `${this.sourceProp}_id`)
    nodes.push(
      ..._map(sourceGroup, (g) => {
        const user = g[0][this.sourceProp]
        const point = _sumBy(g, 'points')
        const extendRadius = totalPoint > 0 ? (point / totalPoint) * 10 : 0
        return {
          id: user.id,
          name: user.displayName,
          image: user.avatarImage,
          radius: baseRadius + extendRadius,
        }
      }),
    )
    const targetGroup = _groupBy(
      _filter(items, (val) => !_find(nodes, { id: val[`${this.targetProp}_id`] })),
      `${this.targetProp}_id`,
    )
    nodes.push(
      ..._map(targetGroup, (g) => {
        const user = g[0][this.targetProp]
        return {
          id: user.id,
          name: user.displayName,
          image: user.avatarImage,
          radius: baseRadius,
        }
      }),
    )
    return nodes
  }

  makeLinks(items: Record[]): Link[] {
    const baseWidth = 1.2
    const group = _groupBy(items, (val) => {
      return val[`${this.sourceProp}_id`] + '-' + val[`${this.targetProp}_id`]
    })
    return _map(group, (g, k) => {
      const keys = k.split('-')
      const latest = this.$moment(_get(_maxBy(g, 'last_occurred_at'), 'last_occurred_at'), 'YYYY-MM-DD').unix()
      const min = this.$moment(this.dates[0], 'YYYY-MM-DD').unix()
      let max: number
      if (this.$moment().isBefore(this.dates[1])) {
        max = this.$moment(this.$moment().format('YYYY-MM-DD')).unix()
      } else {
        max = this.$moment(this.dates[1], 'YYYY-MM-DD').unix()
      }
      const rate = latest === max ? 1 : (latest - min) / (max - min)
      const red = 255
      const green = Math.floor(200 * (1 - rate))
      const blue = Math.floor(200 * (1 - rate))
      return {
        source: keys[0],
        target: keys[1],
        width: baseWidth * _sumBy(g, 'count'),
        color: `rgba(${red}, ${green}, ${blue}, 1)`,
      }
    })
  }

  async mounted(): Promise<void> {
    this.panels.forEach((panel) => {
      const open = cookies.get(panel.name)
      panel.open = _isNil(open) || open.toLowerCase() === 'true'
    })
    this.isDestroyed = false
    await Promise.all([UserTag.fetchAll({ useCache: false }), Department.fetchAll({ useCache: false })])
    await Me.load()
    this.$nextTick(() => {
      this.search()
    })
    this.$refs.panelRefs.some((panel) => {
      if (panel instanceof DashboardMembers) {
        this.dashboardMembers = panel
        return true
      }
    })
  }

  beforeDestroy(): void {
    this.isDestroyed = true
  }

  clearTimeoutDiagram(): void {
    clearInterval(this.timeoutDiagram)
    this.timeoutDiagram = 0
  }

  search(): void {
    this.loadingUsers = true
    this.loadingCorrelationDiagramData = true
    Promise.resolve()
      .then(async () => {
        this.$refs.panelRefs.filter((panel) => !(panel instanceof DashboardMembers)).forEach((panel) => panel.fetch())
        try {
          return await this.dashboardMembers.fetch()
        } finally {
          this.loadingUsers = false
        }
      })
      .then(() => this.fetchCorrelationDiagramData())
      .then(() => {
        // NOTE: this.$refs.diagramが取得できるまで待つ
        return new Promise((resolve) => {
          this.timeoutDiagram = window.setInterval(() => {
            if (this.isDestroyed) {
              this.clearTimeoutDiagram()
              resolve({ cancel: true })
            }
            if (this.$refs.diagram) {
              this.clearTimeoutDiagram()
              resolve({ cancel: false })
            }
          }, 100)
        })
      })
      .then(({ cancel }) => {
        if (cancel) return
        const items = GiftSummary.query().with(['sender', 'recipient']).orderBy('count', 'desc').get()
        const nodes = this.makeNodes(items)
        const links = this.makeLinks(items)
        const target = _get(_maxBy(nodes, 'radius'), 'id')
        const markerStart = this.type === 'gave'
        const markerEnd = this.type === 'took'
        this.$refs.diagram.draw({ target, nodes, links }, markerStart, markerEnd)
      })
      .finally(() => {
        this.loadingUsers = false
        this.loadingCorrelationDiagramData = false
      })
  }

  async fetchCorrelationDiagramData(): Promise<void> {
    await GiftSummary.truncate()
    const condition: {
      'user_tags_name_in'?: string[]
      'sender_tags_name_in'?: string[]
      'categories_id_in'?: number[]
      'created_at_gteq'?: string
      'created_at_lteq'?: string
      'user_deleted_at_null'?: boolean
      'sender_deleted_at_null'?: boolean
      'user_deleted_at_not_null'?: boolean
      'sender_deleted_at_not_null'?: boolean
    } = {
      'created_at_gteq': this.$moment(this.dates[0]).startOf('day').format(),
      'created_at_lteq': this.$moment(this.dates[1]).endOf('day').format(),
    }
    if (this.tags.length > 0) {
      condition['user_tags_name_in'] = this.tags
      condition['sender_tags_name_in'] = this.tags
    }

    if (!_isNil(this.department)) {
      condition['sender_department_id_eq'] = this.department.id
    }
    const params = new http.SearchCondition({
      condition,
    }).toRansackParams()

    if (this.status !== 'all') {
      params['q[g[0][m]]'] = 'or'
      this.dashboardMembers.users.map(({ id }, index) => {
        params[`q[g[0][g[${index}][sender_id_eq]]`] = id
      })
    }

    await GiftSummary.fetchAll({ params })
    return Promise.resolve()
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/index';

$border: 1px solid #dedede;

.v-skeleton-loader {
  // noinspection CssInvalidPseudoSelector
  :deep(.v-skeleton-loader__avatar) {
    width: 24px;
    height: 24px;
  }
}

.p- {
  &dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    height: 100%;

    &__diagram {
      display: flex;
      flex-grow: 1;
      min-height: 600px;
    }

    &__filter {
      width: auto;
    }
  }
}
</style>
