<template>
  <v-dialog
    v-model="dialog"
    content-class="rounded-0"
    fullscreen
    persistent
    scrollable
  >
    <v-card rounded="0">
      <v-card-title
        class="text-h5 grey lighten-2"
        primary-title
      >
        {{ user?.name }}のチップ利用履歴
        <v-spacer />
        <v-btn @click="cancel">
          {{ $t('button.close') }}
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-form @submit.stop.prevent="submit">
          <v-card outlined>
            <v-card-title>利用登録</v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="ma-0 pa-0">
                  <v-col
                    cols="12"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      :label="$t('user.keeps')"
                      :value="user?.keeps"
                      autocomplete="off"
                      readonly
                      type="text"
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col
                    cols="12"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      v-model="exchange.point"
                      v-validate="rules.point"
                      :disabled="loading"
                      :error-messages="errors.collect('exchange.point')"
                      :label="$t('exchange.point')"
                      autocomplete="off"
                      data-vv-name="exchange.point"
                      required
                      type="text"
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col cols="12">
                    <v-textarea
                      v-model="exchange.memo"
                      v-validate="rules.memo"
                      :disabled="loading"
                      :error-messages="errors.collect('exchange.memo')"
                      :label="$t('exchange.memo')"
                      data-vv-name="exchange.memo"
                      required
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                :disabled="loading"
                :loading="loading"
                color="primary"
                type="submit"
              >
                {{ $t('button.register') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-card
          class="mt-5"
          outlined
        >
          <v-card-title>利用履歴</v-card-title>
          <v-card-text>
            <ExchangeTable
              :headers="headers"
              :items="itemsPerPage"
              :loading="loading"
              :options="options"
              :server-items-length="totalCount"
              @update:options="changeOptions"
            >
              <template #top>
                <ExchangeDetailDialog
                  v-model="detail.show"
                  :exchange="detail.exchange"
                  :read-only="detail.readOnly"
                  :use-delete="detail.useDelete"
                />
              </template>
              <template #item="{ item }">
                <tr>
                  <td class="d-flex align-center">
                    <v-chip
                      v-if="item?.deleted_at"
                      class="mr-1"
                      filter
                      small
                    >
                      削除済
                    </v-chip>
                    {{ item?.created_at ? $moment(item?.created_at).format('llll') : '' }}
                  </td>
                  <td>{{ item?.point }}</td>
                  <td class="flex-grow-1">
                    {{ item?.memo }}
                  </td>
                  <td class="text-center">
                    <IconButton
                      v-if="item?.deleted_at"
                      :icon="icons.mdiMagnify"
                      @click="showDetail({ exchange: item, readOnly: true })"
                    />
                    <IconButton
                      v-else
                      :icon="icons.mdiDeleteForever"
                      @click="showDetail({ exchange: item, useDelete: true })"
                    />
                  </td>
                </tr>
              </template>
            </ExchangeTable>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import MyDataTable from '@/components/atoms/MyDataTable.vue'
import Exchange from '@/store/models/Exchange'
import { User } from '@/store/models/Person'
import { Collection, Item } from '@vuex-orm/core'
import { DataOptions, DataTableHeader } from 'vuetify'
import { SearchCondition, SearchSort } from '@/utils/http'
import { FetchResult } from '@/store/models/Model'
import { mdiDeleteForever, mdiMagnify } from '@mdi/js'
import ExchangeDetailDialog from '@/components/organisms/exchanges/ExchangeDetailDialog.vue'
import IconButton from '@/components/atoms/IconButton.vue'

const ExchangeTable = MyDataTable<Exchange>
@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    IconButton,
    ExchangeDetailDialog,
    ExchangeTable,
  },
})
export default class ExchangeHistoryDialog extends Vue {
  @Prop({ default: null })
  readonly value!: boolean
  @Prop({ default: null })
  readonly user!: Item<User>

  readonly icons = {
    mdiDeleteForever,
    mdiMagnify,
  }

  loading = false
  totalCount = 0
  options: Partial<DataOptions> = {
    sortBy: ['created_at'],
    sortDesc: [true],
  }

  exchange: {
    point: number
    memo: string
  } = {
    point: 0,
    memo: '',
  }

  detail: {
    readOnly?: boolean
    useDelete?: boolean
    exchange?: Item<Exchange>
    show: boolean
  } = {
    show: false,
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t('createdAt') as string,
        value: 'created_at',
      },
      {
        text: this.$t('exchange.point') as string,
        value: 'point',
      },
      {
        text: this.$t('exchange.memo') as string,
        value: 'memo',
      },
      {
        text: this.$t('label.delete') as string,
        value: 'deleted_at',
        sortable: false,
        width: '80px',
        align: 'center',
      },
    ]
  }

  get rules(): {
    point: string
    memo: string
  } {
    return {
      point: `required|min_value:1|max_value:${this.user?.keeps || 0}`,
      memo: 'required',
    }
  }

  get itemsPerPage(): Collection<Exchange> {
    const { page, itemsPerPage } = this.options
    if (itemsPerPage > 0) {
      return this.exchanges.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    }
    return this.exchanges
  }

  get exchanges(): Collection<Exchange> {
    if (!this.user) return []
    const { sortBy = [], sortDesc = [] } = this.options
    const query = Exchange.query()
    query.where('user_id', this.user.id)
    sortBy.forEach((key, index) => {
      query.orderBy(key, sortDesc[index] ? 'desc' : 'asc')
    })
    query.orderBy('id', 'desc')
    return query.get()
  }

  get dialog(): boolean {
    return this.value
  }

  set dialog(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  @Emit()
  input(value: boolean): boolean {
    return value
  }

  @Watch('value')
  async watchValue(): Promise<void> {
    if (this.value) {
      console.log('watchValue')
      this.$validator.reset()
      this.loading = true
      await this.fetch()
      this.loading = false
    }
  }

  cancel(): void {
    this.dialog = false
  }

  async changeOptions(value: {
    sortBy: string[]
    sortDesc: boolean[]
    page: number
    itemsPerPage: number
  }): Promise<void> {
    this.options = value
    this.loading = true
    await this.fetch()
    this.loading = false
  }

  async submit(): Promise<void> {
    if (!(await this.$validator.validateAll())) {
      return
    }
    this.loading = true
    try {
      const { data } = await this.$http.post(`users/${this.user.id}/exchanges`, this.exchange)
      await Exchange.insertOrUpdate({ data })
      this.totalCount = this.exchanges.length
    } finally {
      this.loading = false
      this.$validator.reset()
    }
  }

  showDetail(detail: Omit<typeof this.detail, 'show'>) {
    this.detail = {
      ...detail,
      show: true,
    }
  }

  private async fetch(): Promise<void> {
    if (!this.user) return
    const { sortBy = [], sortDesc = [], page = 1, itemsPerPage = 25 } = this.options
    const limit = itemsPerPage > 0 ? itemsPerPage : 25
    const sort: SearchSort = []
    sortBy.forEach((key, index) => {
      sort.push({ name: key, order: sortDesc[index] ? 'desc' : 'asc' })
    })
    sort.push({ name: 'id', order: 'desc' })
    const params = Object.assign({}, new SearchCondition({ page, limit, sort }).toRansackParams(), {
      'q[user_id_eq]': this.user.id,
      'with_deleted': true,
    })
    const { totalCount }: FetchResult = await (() => {
      if (itemsPerPage === -1) {
        return Exchange.fetchAll({ useCache: false, params })
      }
      return Exchange.fetch({ useCache: false, params })
    })()
    this.totalCount = totalCount
  }
}
</script>
