<template>
  <MyContainer>
    <template #title>
      感謝タグ管理
    </template>
    <template #description>
      この画面では感謝タグ管理ができます
    </template>
    <MyContent>
      <CategoryList />
    </MyContent>
  </MyContainer>
</template>
<script lang="ts">
import Vue from 'vue'
import CategoryList from '@/components/organisms/categories/CategoryList.vue'
import EditCategoryDialog from '@/components/organisms/categories/EditCategoryDialog.vue'
import Component from 'vue-class-component'
import MyContainer from '@/components/atoms/MyContainer.vue'
import MyContent from '@/components/atoms/MyContent.vue'

@Component({
  components: {
    MyContent,
    MyContainer,
    CategoryList,
    EditCategoryDialog,
  },
})
export default class extends Vue {
}
</script>
