<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-tabs
      v-model="tab"
      class="elevation-2 fill-height d-flex flex-column"
    >
      <v-tab href="#took">
        もらった
      </v-tab>
      <v-tab href="#gave">
        おくった
      </v-tab>
      <v-tabs-items
        v-model="tab"
        class="p-dashboard__tabs-items flex-grow-1 fill-height"
      >
        <v-tab-item
          class="fill-height p-dashboard__tab-item"
          value="took"
        >
          <MyCorrelationDiagram type="took" />
        </v-tab-item>
        <v-tab-item
          class="fill-height p-dashboard__tab-item"
          value="gave"
        >
          <MyCorrelationDiagram type="gave" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import MyCorrelationDiagram from '@/components/organisms/gifts/MyCorrelationDiagram.vue'

@Component({
  components: { MyCorrelationDiagram },
  metaInfo() {
    return {
      title: '相関図',
    }
  },
})
export default class extends Vue {
  tab = 'took'

  async mounted(): Promise<void> {
    this.tab = this.$route.hash ? this.$route.hash.replace(/^#/, '') : 'took'
  }
}
</script>
<style lang="scss" scoped>
.p-dashboard {
  &__tabs-items {
    height: 100%;
  }

  &__tab-item {
    display: flex;
    flex-direction: column;
  }
}
</style>
