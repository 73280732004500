<template>
  <v-container fluid>
    <section>
      <InviteMember />
    </section>
    <section>
      <InviteBulkMember />
    </section>
    <section>
      <SignUpInfo />
    </section>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import InviteMember from '@/components/organisms/members/InviteMember.vue'
import InviteBulkMember from '@/components/organisms/members/InviteBulkMember.vue'
import Component from 'vue-class-component'
import SignUpInfo from '@/components/organisms/members/SignUpInfo.vue'

@Component({
  components: {
    InviteMember,
    InviteBulkMember,
    SignUpInfo,
  },
  metaInfo() {
    return {
      title: 'メンバー招待',
    }
  },
})
export default class extends Vue {
}
</script>
