<template>
  <v-list>
    <template v-for="item of followings">
      <v-list-item
        :key="item.id"
        :to="`/members/${item.id}`"
      >
        <v-list-item-avatar
          class="p-followings__avatar"
          size="32px"
        >
          <Avatar
            :user="item"
            size="32px"
          />
          <span class="c-feelingBadge">{{ _.get(item, 'feeling.emoji', defaultFeeling) }}</span>
        </v-list-item-avatar>
        <v-list-item-content class="py-2">
          <div class="d-flex align-center p-followings__member">
            <strong class="p-followings__member__name">{{ item.name }}</strong>
            <div class="d-inline-flex ml-4">
              <v-icon
                v-if="item.online"
                color="#99d04a"
                x-small
              >
                {{ icons.mdiCircle }}
              </v-icon>
              <v-icon
                v-else
                color="#d3d4d3"
                x-small
              >
                {{ icons.mdiCircleOutline }}
              </v-icon>
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>
<script lang="ts">
import Vue from 'vue'
import { defaultFeeling } from '@/store/models/Feeling'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import { User } from '@/store/models/Person'
import Me from '@/store/models/Me'
import { mdiCircle, mdiCircleOutline } from '@mdi/js'
import Avatar from '@/components/organisms/Avatar.vue'

@Component({
  components: {
    Avatar,
  },
  metaInfo() {
    return {
      title: 'フォロー中のメンバー',
    }
  },
})
export default class extends Vue {
  readonly defaultFeeling = defaultFeeling
  readonly icons = { mdiCircle, mdiCircleOutline }

  get my(): Record {
    return Me.query().first()
  }

  get followings(): Record[] {
    return User.query()
      .with(['feeling'])
      .whereIdIn(this.my.followings.map((following) => following.id))
      .get()
  }
}
</script>
<style lang="scss" scoped>
.p-followings {
  &__avatar {
    overflow: visible;
  }

  &__member {
    min-width: 0;
    line-height: unset;

    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
