<template>
  <v-list-item>
    <v-list-item-avatar class="align-self-start c-list-item__avatar">
      <Avatar :user="gift.sender" />
      <span class="c-feelingBadge">{{ _.get(gift, 'sender_feeling.emoji', defaultFeeling) }}</span>
    </v-list-item-avatar>
    <v-list-item-content class="flex-column align-start">
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <router-link
          :to="{ name: 'member/home', params: { id: gift.sender_id } }"
          class="c-sender__name"
        >
          <strong>{{ _.get(gift, 'sender.name') }}</strong>
        </router-link>
        <div class="timeline-date ml-2">
          {{ $d($moment(gift.created_at).toDate(), 'long') }}
        </div>
        <v-spacer />
      </v-row>
      <v-row class="ma-0 pa-0">
        <div
          :class="{ 'c-gift__message--edited': isEdited }"
          class="c-gift__message"
          v-html="$marked(gift.message)"
        />
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <ReactionChipGroup
          :loading="reactionLoading"
          :value="reactions"
          @click:reaction="selectReaction"
        />
      </v-row>
    </v-list-item-content>
    <Actions
      :absolute="true"
      :show="showActions || showReaction"
    >
      <ReactionAction
        @hidden="showReaction = false"
        @shown="showReaction = true"
        @select:reaction="selectReaction"
      />
    </Actions>
  </v-list-item>
</template>
<script lang="ts">
import { defaultFeeling } from '@/store/models/Feeling'
import Avatar from '@/components/organisms/Avatar.vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Gift from '@/store/models/Gift'
import Actable from '@/mixins/actable'
import Reactable from '@/mixins/reactable'
import _get from 'lodash/get'

@Component({
  components: {
    Avatar,
  },
})
export default class extends mixins(Actable, Reactable) {
  readonly defaultFeeling = defaultFeeling
  readonly reactableModel = Gift
  readonly model = Gift
  @Prop({ default: {} })
  readonly value!: Record

  get gift(): Record {
    return this.item
  }

  get item(): Record {
    return Gift.query()
      .with(['recipient', 'recipient_feeling', 'sender', 'sender_feeling', 'categories', 'reactions', 'reactions.user'])
      .find(this.value.id)
  }

  get isEdited(): boolean {
    return _get(this.gift, 'edited')
  }
}
</script>
<style lang="scss" scoped>
.c- {
  &list-item {
    &__avatar {
      overflow: visible;
    }
  }

  &gift {
    &__message {
      word-break: break-all;
      word-wrap: break-word;

      &--edited {
        // noinspection CssInvalidPseudoSelector
        :deep(.markdown-body) {
          &::after {
            display: inline-table;
            margin-left: 4px;
            color: #d3d4d3;
            content: '(edited)';
          }

          > :not(ol, ul, pre, blockquote):last-child {
            display: inline;
          }
        }
      }
    }
  }

  &sender {
    &__name {
      font-size: .9rem;
    }
  }
}
</style>
