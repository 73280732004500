<template>
  <MyContainer>
    <template #title>
      部署管理
    </template>
    <template #description>
      この画面では部署を管理することができます
    </template>
    <MyContent>
      <DepartmentList />
    </MyContent>
  </MyContainer>
</template>
<script lang="ts">
import Vue from 'vue'
import DepartmentList from '@/components/organisms/departments/DepartmentList.vue'
import EditDepartmentDialog from '@/components/organisms/departments/EditDepartmentDialog.vue'
import Component from 'vue-class-component'
import MyContainer from '@/components/atoms/MyContainer.vue'
import CategoryList from '@/components/organisms/categories/CategoryList.vue'
import MyContent from '@/components/atoms/MyContent.vue'

@Component({
  components: {
    CategoryList,
    MyContent,
    MyContainer,
    DepartmentList,
    EditDepartmentDialog,
  },
})
export default class extends Vue {
}
</script>
