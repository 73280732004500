<template>
  <v-menu
    ref="menu"
    v-model="value"
    :position-x="positionX"
    :position-y="positionY"
    absolute
    content-class="c-completionList"
    min-width="100px"
    z-index="100"
  >
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :input-value="index === selectIndex"
        class="c-completionList__item"
        selectable
        @click="select(item)"
      >
        {{ item }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: [] })
  readonly items!: string[]
  @Prop({ default: 0 })
  readonly positionX?: number
  @Prop({ default: 0 })
  readonly positionY?: number
  @Prop({ default: 0 })
  readonly selectIndex?: number
  value = false

  @Watch('items')
  watchItems(): void {
    this.value = this.items.length > 0
  }

  select(item): void {
    this.$emit('input', item)
  }
}
</script>
<style lang="scss" scoped>
.c-completionList {
  max-width: unset;

  &__item {
    white-space: nowrap;
  }
}
</style>
