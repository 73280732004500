<template>
  <v-list-item>
    <v-list-item-avatar class="align-self-start c-list-item__avatar">
      <Avatar :user="sender" />
      <span class="c-feelingBadge">{{ _.get(gift, 'sender_feeling.emoji', defaultFeeling) }}</span>
    </v-list-item-avatar>
    <v-list-item-content class="flex-column align-start">
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <router-link
          v-if="gift.sender_id >0"
          :to="{ name: 'member/home', params: { id: gift.sender_id } }"
          class="c-sender__name"
        >
          <strong>{{ senderName }}</strong>
        </router-link>
        <strong v-else>{{ senderName }}</strong>
        <div class="timeline-date ml-2">
          {{ $d($moment(gift.created_at).toDate(), 'long') }}
        </div>
        <v-spacer />
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <div>
          <template v-if="gift.point >0">
            {{ gift.point }} チップ 受け取りました。
          </template>
          <template v-else>
            感謝を受け取りました。
          </template>
        </div>
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <div>
          <TagGroup :value="_.map(gift.categories, 'name')" />
        </div>
      </v-row>
      <v-row class="ma-0 pa-0">
        <div
          class="c-gift__message"
          v-html="$marked(gift.message)"
        />
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <ReactionChipGroup
          :loading="reactionLoading"
          :value="reactions"
          @click:reaction="selectReaction"
        />
      </v-row>
      <v-row
        v-if="gift.comments.length >0"
        class="ma-0 pa-0 mt-2"
      >
        <v-col>
          {{ gift.comments.length }}件の返信があります。
          <a
            v-if="commentable"
            href="#"
            @click.stop.prevent="moveToThread"
          >スレッドを開く</a>
        </v-col>
      </v-row>
    </v-list-item-content>
    <Actions
      :absolute="true"
      :show="showActions || showReaction"
    >
      <ReactionAction
        @hidden="showReaction = false"
        @shown="showReaction = true"
        @select:reaction="selectReaction"
      />
      <CommentAction
        v-if="commentable"
        @click.stop.prevent="moveToThread"
      />
    </Actions>
  </v-list-item>
</template>
<script lang="ts">
import { defaultFeeling } from '@/store/models/Feeling'
import Avatar from '@/components/organisms/Avatar.vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import Gift from '@/store/models/Gift'
import Actable from '@/mixins/actable'
import Reactable from '@/mixins/reactable'
import TagGroup from '@/components/organisms/TagGroup.vue'
import _get from 'lodash/get'
import CommentAction from '@/components/organisms/actions/CommentAction.vue'

@Component({
  components: {
    Avatar,
    TagGroup,
    CommentAction,
  },
  $_veeValidate: {
    validator: 'new',
  },
})
export default class TakeListItemComponent extends mixins(Actable, Reactable) {
  readonly defaultFeeling = defaultFeeling
  readonly reactableModel = Gift
  @Prop({ default: {} })
  readonly value!: Record
  @Prop({ default: false })
  readonly commentable?: boolean

  get sender(): Record {
    return _get(this.gift, 'sender')
  }

  get senderName(): string {
    return this.gift.senderName
  }

  get gift(): Record {
    return this.item
  }

  get item(): Record {
    return Gift.query()
      .with([
        'recipient',
        'recipient_feeling',
        'sender',
        'sender_feeling',
        'categories',
        'reactions',
        'reactions.user',
        'comments',
      ])
      .find(this.value.id)
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get visibleActions(): boolean {
    return this.showActions || this.showReaction
  }

  mounted(): void {
    this.receive()
  }

  async receive(): Promise<void> {
    if (this.currentUser.id !== this.gift.recipient_id) return
    if (this.gift.accepted_at) return
    const { data } = await this.$http.patch(['takes', this.gift.id].join('/'))
    Gift.insertOrUpdate({ data })
  }

  moveToThread(): void {
    this.$router.push({
      name: 'takes',
      params: {
        id: this.gift.id,
      },
    })
  }
}
</script>
<style lang="scss" scoped>
.c-gift {
  &__message {
    word-break: break-all;
    word-wrap: break-word;
  }
}

.c-sender {
  &__name {
    font-size: .9rem;
  }
}

.c- {
  &list-item {
    &__avatar {
      overflow: visible;
    }
  }
}
</style>
