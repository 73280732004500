import { createDecorator, VueDecorator } from 'vue-class-component'
import _debounce from 'lodash/debounce'
import Vue, { ComponentOptions } from 'vue'

const Debounce = (): VueDecorator => {
  return createDecorator((options: ComponentOptions<Vue>, key: string) => {
    options.methods[key] = _debounce(options.methods[key])
  })
}
export default Debounce
