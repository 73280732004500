<template>
  <v-dialog
    v-model="show"
    max-width="320"
    persistent
  >
    <v-form @submit.stop.prevent="submit">
      <v-card>
        <v-card-title class="text-h5">
          メンバー承認
        </v-card-title>
        <v-card-text>{{ user.name }}（{{ user.email }}） さんが参加を希望しています。</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary"
            text
            type="submit"
          >
            {{ $t('button.approve') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import { User } from '@/store/models/Person'
import notification from '@/utils/notification'

@Component
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly item!: Record
  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  get user(): Record {
    return this.item
  }

  @Emit()
  input(value): boolean {
    return value
  }

  cancel(): void {
    this.show = false
  }

  async approve(): Promise<void> {
    this.loading = true
    if (!(await this.$validator.validateAll())) {
      this.loading = false
      return
    }
    this.$http
      .put(['users', this.user.id, 'approval'].join('/'))
      .then(({ data }) => {
        User.update({ data })
        notification.success(this.$t('message.approved') as string)
      })
      .finally(() => (this.loading = false))
  }

  async submit(): Promise<void> {
    await this.approve()
    this.show = false
  }
}
</script>
