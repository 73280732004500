<template>
  <v-row class="ma-0 pa-0">
    <v-col
      v-if="loading"
      cols="12"
    >
      <v-skeleton-loader
        :types="skeletonTypes"
        type="avatar-text"
      />
    </v-col>
    <v-col
      v-else
      cols="12"
    >
      <v-avatar
        class="mr-4"
        size="36px"
      >
        <img
          v-if="avatar"
          :src="avatar"
        >
      </v-avatar>
      <span>{{ name }}</span>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class PanelUser extends Vue {
  @Prop({ default: null })
  readonly name!: string
  @Prop({ default: null })
  readonly avatar!: string
  @Prop({ default: false })
  readonly loading?: boolean
  readonly skeletonTypes = {
    'avatar-text': 'avatar, text',
  }
}
</script>
<style lang="scss" scoped>
.v-skeleton-loader {
  // noinspection CssInvalidPseudoSelector
  :deep(.v-skeleton-loader__avatar-text) {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }

  // noinspection CssInvalidPseudoSelector
  :deep(.v-skeleton-loader__avatar) {
    width: 36px;
    height: 36px;
    margin-right: 16px;
  }

  // noinspection CssInvalidPseudoSelector
  :deep(.v-skeleton-loader__text) {
    margin-bottom: 0;
  }
}
</style>
