<template>
  <section class="p-agreement__management">
    <div class="p-agreement__management__head">
      <h3>クレジットカード</h3>
      <v-img
        v-for="{ src, key } in _.map(cardLogos, (src, key) =>{
          return { src, key }
        })"
        :key="key"
        :alt="key"
        :src="src"
        class="mr-1"
        max-width="39px"
      />
    </div>
    <v-radio-group
      v-if="card"
      value="1"
    >
      <div class="p-agreement__management__radiobox">
        <v-radio
          class="p-agreement__management__radiobox--top"
          color="red"
          label="現在、お支払いに設定いただいているカードです"
          value="1"
        />
        <div class="p-agreement__management__box">
          <v-card
            :elevation="0"
            class="p-agreement__management__card"
          >
            <div class="p-agreement__management__item">
              <strong class="p-agreement__management__item--name">カードブランド</strong>
              <div class="p-agreement__management__item--block p-agreement__management__item--block-brand">
                <img
                  :alt="card.typeInfo.niceType"
                  :src="_.get(cardLogos, card.typeInfo.type, cardLogos.none)"
                  class="p-agreement__management__item--img"
                  max-width="39px"
                >
                <span>{{ card.typeInfo.niceType }}</span>
              </div>
            </div>
            <div class="p-agreement__management__item">
              <strong class="p-agreement__management__item--name">カード番号</strong>
              <div class="p-agreement__management__item--block">
                <span class="p-agreement__management__item--text">下4桁 {{ card.last_digits }}</span>
              </div>
            </div>
            <div class="p-agreement__management__item">
              <strong class="p-agreement__management__item--name">有効期限</strong>
              <div class="p-agreement__management__item--block">
                <span
                  v-if="!card.expired"
                  class="p-agreement__management__item--text"
                >{{ `0${card.expiration_month}`.slice(-2) }} / {{ `${card.expiration_year}`.slice(-2) }}</span>
                <span
                  v-else
                  class="p-agreement__management__item--text p-agreement__management__item--text--expired"
                >有効期限切れ</span>
              </div>
            </div>
            <div class="p-agreement__management__item">
              <strong
                class="p-agreement__management__item--name p-agreement__management__item--name-agree"
              >ご契約者名</strong>
              <div class="p-agreement__management__item--block">
                <span class="p-agreement__management__item--text">{{ card.name }}</span>
              </div>
            </div>

            <div class="p-agreement__management__item">
              <strong class="p-agreement__management__item--name">お支払い方法</strong>
              <div class="p-agreement__management__item--block">
                <span class="p-agreement__management__item--text">一括払い</span>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-radio-group>
    <v-card
      :elevation="0"
      class="p-agreement__management__verification"
    >
      <div class="p-agreement__management__add">
        <span>新しくクレジットカードを追加する</span>
      </div>
      <p class="p-agreement__management__caption">
        お支払いにご利用されるクレジットカードの情報をお間違いのないようご入力ください
      </p>
      <v-alert
        :value="!!errorMessage"
        dismissible
        type="error"
      >
        {{ errorMessage }}
      </v-alert>
      <v-fade-transition
        group
        hide-on-leave
      >
        <v-form
          v-if="step === 'input'"
          key="input"
          v-model="valid"
        >
          <div class="p-agreement__management__registration">
            <table class="mb-5">
              <tbody>
                <tr>
                  <td>カードブランド</td>
                  <td>
                    <img
                      :alt="ccType.niceType"
                      :src="_.get(cardLogos, ccType.type, cardLogos.none)"
                      class="p-agreement__management__item--img"
                      max-width="39px"
                    >
                  </td>
                </tr>
                <tr>
                  <td>カード番号<span class="c-required ml-2">必須</span></td>
                  <td class="c-form">
                    <v-row class="ma-0 pa-0">
                      <div class="d-flex align-center">
                        <v-text-field
                          v-model="ccNumber1"
                          v-validate="'required|numeric'"
                          :error-messages="$validator.errors.collect('creditCard.number1')"
                          class="p-agreement__management__form--card"
                          data-vv-name="creditCard.number1"
                          data-vv-scope="cc-number"
                          hide-details
                          maxlength="4"
                          placeholder="1234"
                          required
                          type="tel"
                        />
                        <v-text-field
                          v-model="ccNumber2"
                          v-validate="'required|numeric'"
                          :error-messages="$validator.errors.collect('creditCard.number2')"
                          class="p-agreement__management__form--card"
                          data-vv-name="creditCard.number2"
                          data-vv-scope="cc-number"
                          hide-details
                          maxlength="4"
                          placeholder="5678"
                          required
                          type="tel"
                        />
                        <v-text-field
                          v-model="ccNumber3"
                          v-validate="'required|numeric'"
                          :error-messages="$validator.errors.collect('creditCard.number3')"
                          class="p-agreement__management__form--card"
                          data-vv-name="creditCard.number3"
                          data-vv-scope="cc-number"
                          hide-details
                          maxlength="4"
                          placeholder="9012"
                          required
                          type="tel"
                        />
                        <v-text-field
                          v-model="ccNumber4"
                          v-validate="'required|numeric'"
                          :error-messages="$validator.errors.collect('creditCard.number4')"
                          class="p-agreement__management__form--card"
                          data-vv-name="creditCard.number4"
                          data-vv-scope="cc-number"
                          hide-details
                          maxlength="4"
                          placeholder="3456"
                          required
                          type="tel"
                        />
                      </div>
                      <v-messages
                        v-show="$validator.errors.all('cc-number').length >0"
                        :value="$validator.errors.all('cc-number')"
                        color="error"
                      />
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td>有効期限<span class="c-required ml-2">必須</span></td>
                  <td class="c-form align-center">
                    <v-row class="ma-0 pa-0">
                      <div class="d-flex align-center">
                        <v-combobox
                          v-model="ccExpMonth"
                          v-validate="'required'"
                          :error-messages="$validator.errors.collect('creditCard.expMonth')"
                          :items="months"
                          autocomplete="cc-exp-month"
                          class="p-agreement__management__form--period"
                          data-vv-name="creditCard.expMonth"
                          data-vv-scope="cc-exp"
                          hide-details
                          label="選択"
                          solo
                        />
                        <span>月</span>
                        <v-combobox
                          v-model="ccExpYear"
                          v-validate="'required'"
                          :error-messages="$validator.errors.collect('creditCard.expYear')"
                          :items="years"
                          autocomplete="cc-exp-year"
                          class="p-agreement__management__form--period"
                          data-vv-name="creditCard.expYear"
                          data-vv-scope="cc-exp"
                          hide-details
                          label="選択"
                          solo
                        />
                        <span>年</span>
                      </div>
                      <v-messages
                        v-show="$validator.errors.all('cc-exp').length >0"
                        :value="$validator.errors.all('cc-exp')"
                        color="error"
                      />
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td>ご契約者様名<span class="c-required ml-2">必須</span></td>
                  <td class="c-form align-center">
                    <v-row class="ma-0 pa-0">
                      <div class="d-flex align-center">
                        <v-text-field
                          v-model="ccGivenName"
                          v-validate="'required'"
                          :error-messages="$validator.errors.collect('creditCard.givenName')"
                          autocomplete="cc-given-name"
                          class="p-agreement__management__form--contractor"
                          data-vv-name="creditCard.givenName"
                          data-vv-scope="cc-name"
                          hide-details
                          placeholder="Taro"
                          required
                        />
                        <v-text-field
                          v-model="ccFamilyName"
                          v-validate="'required'"
                          :error-messages="$validator.errors.collect('creditCard.familyName')"
                          autocomplete="cc-family-name"
                          class="p-agreement__management__form--contractor"
                          data-vv-name="creditCard.familyName"
                          data-vv-scope="cc-name"
                          hide-details
                          placeholder="Agelu"
                          required
                        />
                        <span
                          class="p-agreement__management__form--contractor__note"
                        >※アルファベットでカードの表示どおりにご入力ください</span>
                      </div>
                      <v-messages
                        v-show="$validator.errors.all('cc-name').length >0"
                        :value="$validator.errors.all('cc-name')"
                        color="error"
                      />
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td>お支払い区分</td>
                  <td>一括払い</td>
                </tr>
                <tr>
                  <td>セキュリティコード<span class="c-required ml-2">必須</span></td>
                  <td class="c-form">
                    <v-row class="ma-0 pa-0">
                      <div class="d-flex align-center">
                        <v-text-field
                          v-model="ccCsc"
                          v-validate="'required|numeric'"
                          :error-messages="$validator.errors.collect('creditCard.csc')"
                          autocomplete="off"
                          class="p-agreement__management__form--code"
                          data-vv-name="creditCard.csc"
                          data-vv-scope="cc-csc"
                          hide-details
                          maxlength="4"
                          placeholder="1234"
                          required
                          type="tel"
                        />
                      </div>
                      <v-messages
                        v-show="$validator.errors.all('cc-csc').length >0"
                        :value="$validator.errors.all('cc-csc')"
                        color="error"
                      />
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="p-agreement__management__bg c-bg mb-8">
              <v-btn
                :disabled="!valid"
                :elevation="6"
                class="c-btn c-btn-pink p-agreement__management__btn pt-2 pb-2"
                color="primary"
                @click.stop="confirm"
              >
                入力内容を確認する
              </v-btn>
            </div>
          </div>
        </v-form>
        <v-form
          v-if="step === 'confirm'"
          key="confirm"
          v-model="valid"
        >
          <div class="p-agreement__management__confirmation">
            <table class="mb-5">
              <tbody>
                <tr>
                  <td>ご利用可能なカード</td>
                  <td>
                    <img
                      :alt="ccType.niceType"
                      :src="_.get(cardLogos, ccType.type, cardLogos.none)"
                      class="p-agreement__management__item--img"
                      max-width="39px"
                    >
                  </td>
                </tr>
                <tr>
                  <td>カード番号<span class="c-required ml-2">必須</span></td>
                  <td>下４桁を表示 {{ ccNumber4 }}</td>
                </tr>
                <tr>
                  <td>有効期限<span class="c-required ml-2">必須</span></td>
                  <td>{{ ccExpMonth.text }} 月 / {{ ccExpYear.text }} 年</td>
                </tr>
                <tr>
                  <td>ご契約者様名<span class="c-required ml-2">必須</span></td>
                  <td>{{ ccGivenName }} {{ ccFamilyName }}</td>
                </tr>
                <tr>
                  <td>お支払い区分</td>
                  <td>一括払い</td>
                </tr>
                <tr>
                  <td>セキュリティコード<span class="c-required ml-2">必須</span></td>
                  <td>{{ ccCsc.replace(/./g, '*') }}</td>
                </tr>
              </tbody>
            </table>
            <div class="p-agreement__management__bg c-bg">
              <strong class="c-bg__ques mb-4">この内容でお間違いはありませんでしょうか？ </strong>
              <p class="c-bg__caption mb-4">
                間違いなければこの内容で登録します。<br>
                下記をクリックしてクレジットカードを追加してください。
              </p>
              <v-btn
                :disabled="saving"
                :elevation="6"
                :loading="saving"
                class="c-btn c-btn-pink p-agreement__management__btn pt-2 pb-2 mb-4"
                color="primary"
                @click="addCreditCard"
              >
                新しいクレジットカードを追加する
              </v-btn>
              <v-btn
                :disabled="saving"
                :elevation="3"
                class="c-btn c-btn-revell c-btn-cancel pt-1 pb-1"
                @click="move('input')"
              >
                前のページへ戻り修正する
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-fade-transition>
    </v-card>
    <v-dialog
      v-model="addedDialog"
      max-width="580"
    >
      <v-card class="l-dialog">
        <v-card-title class="text-h5 grey lighten-2">
          新しいクレジットカードを追加する
          <span
            class="v-card__close"
            @click.stop="addedDialog = false"
          />
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row
              class="ma-0 pa-0 c-dialog"
              dense
            >
              <p class="c-dialog__cancellation--title">
                新しいクレジットカードを追加を完了しました。
              </p>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import cardLogos from '@/img/cardLogos'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _isNil from 'lodash/isNil'
import _cloneDeep from 'lodash/cloneDeep'
import creditCardType from 'credit-card-type'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class AgreementManagement extends Vue {
  @Prop({ default: null })
  readonly active?: boolean
  readonly cardLogos = cardLogos
  saving = false
  errorMessage = ''
  valid = false
  ccNumber1 = ''
  ccNumber2 = ''
  ccNumber3 = ''
  ccNumber4 = ''
  ccExpYear: {
    text: string
    value: string
  } | null = null
  ccExpMonth: {
    text: string
    value: string
  } | null = null
  ccFamilyName = ''
  ccGivenName = ''
  ccCsc = ''
  step = 'input'
  addedDialog = false

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get ccType(): any {
    const types = creditCardType(this.ccNumber1)
    if (types.length !== 1) return ''
    return types[0]
  }

  get years(): { text: string; value: string }[] {
    const thisYear = this.$moment().year()
    return Array.from({ length: 20 }, (_, idx) => {
      const year = this.$moment(idx + thisYear, 'YYYY')
      return {
        text: year.format('YY'),
        value: year.format('YYYY'),
      }
    })
  }

  get months(): { text: string; value: string }[] {
    return Array.from({ length: 12 }, (_, idx) => {
      const month = this.$moment(idx + 1, 'M')
      return {
        text: month.format('MM'),
        value: month.format('MM'),
      }
    })
  }

  get tenant(): Record {
    const currentUser: Record = Me.query().with('tenant').first()
    return _get(currentUser, 'tenant')
  }

  get payment(): Record {
    return _get(this.tenant, 'payment')
  }

  get card(): Record {
    const card = _get(this.tenant, 'card')
    if (_isNil(card)) return null
    return {
      ..._cloneDeep(card),
      typeInfo: creditCardType.getTypeInfo(this.cardBrand(card.brand)),
      expired: this.$moment(`${card.expiration_year} ${card.expiration_month}`, 'YYYY MM').isBefore(
        this.$moment(),
        'month',
      ),
    }
  }

  cardBrand(brand: string): string {
    const _brand = brand.toLowerCase()
    switch (_brand) {
      case 'visa':
      case 'mastercard':
      case 'jcb': {
        return _brand
      }
      case 'amex':
      case 'american express':
      case 'american-express': {
        return 'american-express'
      }
      case 'diners':
      case 'diners club':
      case 'diners-club': {
        return 'diners-club'
      }
    }
  }

  @Watch('active')
  watchActive(value): void {
    if (value) {
      this.$validator.reset()
    }
  }

  async confirm(): Promise<void> {
    if (typeof this.ccExpYear === 'string') {
      this.ccExpYear = _find(this.years, { text: this.ccExpYear })
    }
    if (typeof this.ccExpMonth === 'string') {
      this.ccExpMonth = _find(this.months, { text: this.ccExpMonth })
    }
    if (!(await this.$validator.validate())) {
      return
    }
    this.move('confirm')
  }

  move(step): void {
    this.step = step
  }

  async addCreditCard(): Promise<void> {
    this.errorMessage = ''
    this.saving = true
    const { status, response } = await this.createToken()
    if (status !== 200) {
      // TODO: response.codeとresponse.messageをわかりやすく画面に表示する
      this.errorMessage = `${response.code}: ${response.message}`
      this.saving = false
      return
    }
    const method = _isNil(this.payment) ? 'post' : 'patch'
    await this.$http[method]('our/payment', {
      token: response.id,
    })
    this.saving = false
    this.addedDialog = true
  }

  @Watch('addedDialog')
  watchAddedDialog(value): void {
    if (!value) {
      this.added()
    }
  }

  added(): void {
    Me.load({ useCache: false })
    this.ccNumber1 = ''
    this.ccNumber2 = ''
    this.ccNumber3 = ''
    this.ccNumber4 = ''
    this.ccExpYear = null
    this.ccExpMonth = null
    this.ccFamilyName = ''
    this.ccGivenName = ''
    this.ccCsc = ''
    this.$validator.reset()
    this.step = 'input'
  }

  private createToken(): Promise<{ status: number; response }> {
    return new Promise((resolve) => {
      this.$omise.createToken(
        'card',
        {
          name: `${this.ccGivenName} ${this.ccFamilyName}`,
          number: `${this.ccNumber1}${this.ccNumber2}${this.ccNumber3}${this.ccNumber4}`,
          'expiration_month': this.ccExpMonth.value,
          'expiration_year': this.ccExpYear.value,
          'security_code': this.ccCsc,
        },
        (status, response) => {
          resolve({
            status,
            response,
          })
        },
      )
    })
  }
}
</script>
<style lang="scss" scoped>
.c- {
  &dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0 10px;
    text-align: center;

    &__cancellation {
      &--title {
        font-size: 16px;
        color: #000;
        text-align: center;
      }
    }
  }
}

.l-content {
  .c- {
    &btn {
      &:last-child {
        margin-bottom: 0;
      }

      &-cancel {
        font-size: 12px;
      }
    }
  }
}
</style>
