import Vue from 'vue'
import Router, { Route, RouterOptions } from 'vue-router'
import routes from '@/apps/routes'
import storage from '@/utils/AmazonesStorage'
import Me from '@/store/models/Me'
import _isNil from 'lodash/isNil'
import _includes from 'lodash/includes'
import _get from 'lodash/get'
import { Record } from '@vuex-orm/core'
import { User } from '@/store/models/Person'
import config from '@/config'
import store from '@/store'
import devise from '@/utils/auth/DeviseAuthToken'
import Notice from '../store/models/Notice'
import Community from '../store/models/Community'
import _find from 'lodash/find'

Vue.use(Router)
const options: RouterOptions = {
  mode: 'history',
  base: '/',
  routes,
}
const router = new Router(options)

function loadGeneralData(): void {
  Notice.fetchAll()
  Me.load().then(() => Community.fetchAll({ url: 'my/communities' }))
}

export async function guardByProfile({ to }: { to: Route }): Promise<void> {
  await Me.load()
  const currentUser: Record = Me.query().with(['tenant']).first()
  if (_includes(to.meta.unavailablePlans, currentUser.tenant.plan)) {
    store.dispatch('screen/showError', { status: 403 })
  }
  if (to.meta.admin) {
    if (!currentUser.admin) {
      store.dispatch('screen/showError', { status: 403 })
    }
  }
}

export async function guardMember({ to, from }: { to: Route; from: Route }): Promise<void> {
  const targets = ['member/home', 'member/tweets', 'member/takes', 'member/gives', 'member/followings']
  if (!_includes(targets, to.name)) return
  const fromId = _includes(targets, from.name) ? +from.params.id : null
  const userId = +to.params.id
  if (userId === fromId) return
  await User.fetch(userId)
  const user = User.available().where('id', userId).first()
  if (_isNil(user)) {
    store.dispatch('screen/showError', { status: 404 })
  }
  if (to.meta.own) {
    await Me.load()
    const my: Record = Me.query().first()
    if (my.id !== userId) {
      store.dispatch('screen/showError', { status: 404 })
    }
  }
}

router.beforeEach(async (to, from, next) => {
  if (to?.meta?.auth) {
    storage.currentUrl = location.origin + options.base.replace(/\/$/, '') + to.fullPath
  }
  let path: Route = undefined
  if (devise.isAuthenticated) {
    const subdomain = _get(config.excludedSubdomains, location.host.split('.')[0])
    if (subdomain) {
      location.replace(subdomain.path)
      next(false)
      return
    }
  } else {
    if (to?.meta?.auth) {
      store.dispatch('screen/showError', { status: 403 })
    }
  }
  if (!to?.meta?.auth) {
    next(path)
    return
  }
  loadGeneralData()
  await Me.load()
  const currentUser: Record = Me.query().with(['tenant']).first()
  await guardByProfile({ to: path ? path : to })
  if (to.name !== 'wizard') {
    if (currentUser.admin && !currentUser.tenant.display_name) {
      path = _find(routes, { name: 'wizard' }) as Route
      path.query = to.query
      path.params = to.params
      path.params['loggedIn'] = '1'
    }
  }
  await guardMember({ to: path ? path : to, from })
  if (!path) {
    if (to.query['loggedIn'] !== undefined) {
      path = to
      delete path.query['loggedIn']
    }
  }
  next(path)
})
export default router
