<template>
  <div>
    <h3>{{ label }}</h3>
    <v-autocomplete
      :items="departments"
      :loading="loading"
      :search-input.sync="search"
      clearable
      hide-no-data
      hide-selected
      item-text="name"
      return-object
      @change="changeDepartment"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import { Item } from '@vuex-orm/core'
import Department from '@/store/models/Department'
import Collection from '@vuex-orm/core/dist/src/data/Collection'

@Component
export default class extends Vue {
  @Prop({ default: '部署' })
  readonly label?: string

  @Prop({ default: [] })
  readonly value?: Item<Department>

  @Prop({ default: false })
  readonly loading?: boolean

  search = null

  get departments(): Collection<Department> {
    return Department.query().orderBy('display_order', 'asc').all()
  }

  @Emit('input')
  changeDepartment(value: Item<Department>): Item<Department> {
    this.search = null
    return value
  }
}
</script>
