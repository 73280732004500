<template>
  <div class="p-giftResult d-flex flex-column">
    <v-expansion-panels
      v-model="panel"
      class="p-giftResult__expansionPanels ma-2"
    >
      <v-expansion-panel>
        <v-expansion-panel-header v-slot="{ open }">
          フィルター {{ open ? '' : `: ${conditionText}` }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SearchCondition
            :loading="loading || downloading"
            :value="searchCondition"
            @input="changeSearchCondition"
          >
            <template #after>
              <v-row class="ma-0 pa-0">
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                >
                  <UserStatusFilter
                    :loading="loading || downloading"
                    :value="status"
                    label="メンバーの状態で絞り込む"
                    @input="changeStatus"
                  />
                </v-col>
              </v-row>
            </template>
          </SearchCondition>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="itemsPerPage"
      :loading="loading"
      :options="options"
      :server-items-length="totalCount"
      class="elevation-1"
      @update:options="changeOptions"
    >
      <template #top>
        <v-toolbar flat>
          <v-spacer />
          <v-btn
            :disabled="loading || downloading"
            :loading="loading || downloading"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiDownload }}
            </v-icon>
            ダウンロード
          </v-btn>
        </v-toolbar>
      </template>
      <template #[`item.user.tags`]="{ item }">
        <TagGroup :value="_.get(item, 'user.tags')" />
      </template>
      <template #[`item.sum`]="{ item }">
        {{ (+item.sum).toLocaleString() }}
      </template>
      <template #[`item.count`]="{ item }">
        {{ (+item.count).toLocaleString() }}
      </template>
    </v-data-table>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import { Prop } from 'vue-property-decorator'
import _isNil from 'lodash/isNil'
import _map from 'lodash/map'
import { downloadCsv } from '@/utils/download'
import Take from '@/store/models/Take'
import Give from '@/store/models/Give'
import Category from '@/store/models/Category'
import { mdiCalendar, mdiDownload } from '@mdi/js'
import SearchCondition, { SearchValue } from '@/components/organisms/SearchCondition.vue'
import * as http from '@/utils/http'
import { RansackParams } from '@/utils/http'
import { DataTableHeader } from 'vuetify'
import { FetchResult } from '@/store/models/Model'
import TagGroup from '@/components/organisms/TagGroup.vue'
import { User, UserStatus } from '@/store/models/Person'
import { UserTag } from '@/store/models/Tag'
import Department from '@/store/models/Department'
import UserStatusFilter from '@/components/organisms/UserStatusFilter.vue'

const model = {
  took: Take,
  gave: Give,
} as const
type Type = keyof typeof model
@Component({
  components: {
    UserStatusFilter,
    SearchCondition,
    TagGroup,
  },
})
export default class GiftResultComponent extends Vue {
  @Prop({ default: 'took' })
  readonly type!: Type
  readonly icons = {
    mdiDownload,
    mdiCalendar,
  }
  status: 'all' | UserStatus = 'all'
  options: Partial<{
    sortBy: string[]
    sortDesc: boolean[]
    page: number
    itemsPerPage: number
  }> = {
    sortBy: ['sum'],
    sortDesc: [true],
    itemsPerPage: -1,
  }
  loading = true
  downloading = false
  totalCount = 0
  panel = []
  searchCondition: Partial<SearchValue> = {}

  get conditionText(): string {
    const conditionTexts = []
    if (this.searchCondition.from && this.searchCondition.to) {
      conditionTexts.push(
        `${this.$moment(this.searchCondition.from).format('YYYY年MM月DD日')} ~ ${this.$moment(this.searchCondition.to).format('YYYY年MM月DD日')}`,
      )
    }
    if (this.searchCondition.category) {
      const category: Record = Category.find(this.searchCondition.category)
      conditionTexts.push(category.name)
    }
    if (this.searchCondition.department) {
      const department: Record = Department.find(this.searchCondition.department)
      conditionTexts.push(department.name)
    }
    if (!_isNil(this.searchCondition.tag)) {
      if (typeof this.searchCondition.tag === 'string') {
        conditionTexts.push(this.searchCondition.tag)
      } else {
        if (this.searchCondition.tag.length > 0) {
          conditionTexts.push(this.searchCondition.tag.join(' / '))
        }
      }
    }
    return conditionTexts.join(', ')
  }

  get model(): typeof Take | typeof Give {
    return model[this.type]
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t('user.name') as string,
        value: 'name',
        width: '20%',
      },
      {
        text: this.$t('user.employeeId') as string,
        value: 'employee_id',
        width: '10%',
      },
      {
        text: this.$t('user.email') as string,
        value: 'email',
        width: '20%',
      },
      {
        text: this.$t('user.department') as string,
        value: 'department.name',
        width: '10%',
      },
      {
        text: this.$t('user.tags') as string,
        value: 'user.tags',
        width: '20%',
        sortable: false,
      },
      {
        text: this.$t('results.total', {
          type: this.$t(this.type),
          name: this.$t('tips'),
        }) as string,
        value: 'sum',
        width: '10%',
        align: 'end',
      },
      {
        text: this.$t('results.count', {
          type: this.$t(this.type),
          name: this.$t('thanks'),
        }) as string,
        value: 'count',
        width: '10%',
        align: 'end',
      },
    ]
  }

  get categories(): Record[] {
    return Category.query().orderBy('display_order', 'asc').orderBy('id', 'asc').all()
  }

  get tags(): Record[] {
    return UserTag.all()
  }

  get items(): Record[] {
    const { sortBy, sortDesc } = this.options
    const query = this.model.query().with(['user', 'department'])
    sortBy.forEach((key, index) => {
      query.orderBy(key, sortDesc[index] ? 'desc' : 'asc')
    })
    return query.get()
  }

  get itemsPerPage(): Record[] {
    const { page, itemsPerPage } = this.options
    if (itemsPerPage > 0) {
      return this.items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    }
    return this.items
  }

  get fetchParams(): Partial<RansackParams> {
    const { sortBy, sortDesc, page, itemsPerPage } = this.options
    const limit = itemsPerPage > 0 ? itemsPerPage : 50
    const condition: {
      tags_name_in?: string[]
    } = {}
    if (!_isNil(this.searchCondition.tag)) {
      if (typeof this.searchCondition.tag === 'string') {
        condition['tags_name_in'] = [this.searchCondition.tag]
      } else {
        if (this.searchCondition.tag.length > 0) {
          condition['tags_name_in'] = this.searchCondition.tag
        }
      }
    }
    if (this.searchCondition.department) {
      condition['department_id_eq'] = this.searchCondition.department
    }
    const params = new http.SearchCondition({
      condition,
      page,
      limit,
    }).toRansackParams()
    if (this.status === 'active') {
      params['q[deleted_at_null]'] = true
      params['q[g[0][m]]'] = 'or'
      params['q[g[0][g[0][invitation_sent_at_not_null]]]'] = true
      params['q[g[0][g[0][invitation_accepted_at_not_null]]]'] = true
      params['q[g[0][g[1][invitation_sent_at_null]]]'] = true
      params['q[g[0][g[1][approved_at_not_null]]'] = true
    }
    if (_isNil(params['q[s]'])) params['q[s]'] = []
    sortBy.forEach((key, index) => {
      params['q[s]'].push(`${key} ${sortDesc[index] ? 'desc' : 'asc'}`)
    })
    if (!_isNil(this.searchCondition.category)) {
      params['categories'] = this.searchCondition.category
    }
    return {
      ...params,
      from: this.$moment(this.searchCondition.from).startOf('day').format(),
      to: this.$moment(this.searchCondition.to).endOf('day').format(),
    }
  }

  changeStatus(value: 'all' | UserStatus): void {
    this.status = value
    this.loading = true
    this.model
      .deleteAll()
      .then(() => this.fetch())
      .finally(() => {
        this.loading = false
      })
  }

  changeSearchCondition(value: SearchValue): void {
    this.searchCondition = value
    this.loading = true
    this.model
      .deleteAll()
      .then(() => this.fetch())
      .finally(() => {
        this.loading = false
      })
  }

  changeOptions(value: { sortBy: string[]; sortDesc: boolean[]; page: number; itemsPerPage: number }): void {
    this.options = value
    this.loading = true
    this.fetch().finally(() => {
      this.loading = false
    })
  }

  async fetch(): Promise<void> {
    if (_isNil(this.searchCondition.from)) return
    if (_isNil(this.searchCondition.to)) return
    const userParams = {
      'with_unaccepted': true, // 招待に応じていないメンバーを含む
      'with_deleted': true, // 削除メンバーを含む
      'with_unapproved': true, // 承認していないメンバーを含む
    }
    const fetch = (): Promise<FetchResult> => {
      if (this.options.itemsPerPage === -1) {
        return Promise.all([
          this.model.fetchAll({ params: this.fetchParams, useCache: false }),
          User.fetchAll({ useCache: false, params: userParams }),
          Department.fetchAll({ useCache: false }),
        ]).then(([results]) => results)
      } else {
        return Promise.all([
          this.model.fetch({ params: this.fetchParams }),
          User.fetchAll({ useCache: false, params: userParams }),
          Department.fetchAll({ useCache: false }),
        ]).then(([results]) => results)
      }
    }
    const { totalCount } = await fetch()
    this.totalCount = totalCount
  }

  async mounted(): Promise<void> {
    await Promise.all([Category.fetchAll(), UserTag.fetchAll()])
    this.searchCondition = {
      from: this.$moment().add(-1, 'week').startOf('month').format('YYYY-MM-DD'),
      to: this.$moment().add(-1, 'week').endOf('month').format('YYYY-MM-DD'),
    }
    this.loading = true
    this.fetch().finally(() => {
      this.loading = false
    })
  }

  async download(): Promise<void> {
    this.downloading = true
    if (this.options.itemsPerPage > 0) {
      this.loading = true
      const userParams = {
        'with_unaccepted': true, // 招待に応じていないメンバーを含む
        'with_deleted': true, // 削除メンバーを含む
      }
      const { totalCount } = await Promise.all([
        this.model.fetchAll({ params: this.fetchParams, useCache: false }),
        User.fetchAll({ useCache: false, params: userParams }),
      ]).then(([results]) => results)
      this.totalCount = totalCount
      this.loading = false
    }
    const rows: string[] = _map(this.items, (item) => {
      return `"${item?.name || ''}","${item?.employee_id || ''}","${item?.email || ''}","${item?.department?.name || ''}","${item?.user?.tags?.join(',') || ''}",${item?.sum || 0},${item?.count || 0}`
    })
    const headers: string[] = [
      this.$t('user.name') as string,
      this.$t('user.employeeId') as string,
      this.$t('user.email') as string,
      this.$t('user.department') as string,
      this.$t('user.tags') as string,
      this.$t('results.total', {
        type: this.$t(this.type),
        name: this.$t('tips'),
      }) as string,
      this.$t('results.count', {
        type: this.$t(this.type),
        name: this.$t('thanks'),
      }) as string,
    ]
    downloadCsv(
      [headers.join(','), ...rows],
      `${this.$t(this.type)}(${[this.searchCondition.from, this.searchCondition.to].join('-')}).csv`,
    )
    this.downloading = false
  }
}
</script>
<style lang="scss" scoped>
.p-giftResult {
  &__expansionPanels {
    width: auto;
  }
}
</style>
