import visa from '@/img/visa.png'
import mc from '@/img/mastercard.png'
import jcb from '@/img/jcb.gif'
import amex from '@/img/amex.gif'
import diners from '@/img/diners.gif'
import card from '@/img/card.png'

export default {
  get none() {
    return card
  },
  get visa() {
    return visa
  },
  get mastercard() {
    return mc
  },
  get jcb() {
    return jcb
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  get 'american-express'() {
    return amex
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  get 'diners-club'() {
    return diners
  },
}
