<template>
  <textarea
    v-model="text"
    spellcheck="false"
    v-bind="$attrs"
    @click="_handleSelectionChange"
    @cut="_handleSelectionChange"
    @focus="_handleSelectionChange"
    @input="_handleSelectionChange"
    @keyup="_handleSelectionChange"
    @mouseup="_handleSelectionChange"
    @paste="_handleSelectionChange"
    @select="_handleSelectionChange"
    v-on="listeners"
  />
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import Debounce from '@/decorators/vue-debounce-decorator'
import { Emit, Prop } from 'vue-property-decorator'
import { TextSelection as _TextSelection } from '@/components/organisms/forms/types'

type TextSelection = _TextSelection
@Component({
  inheritAttrs: false,
})
export default class extends Vue {
  @Prop({ default: '' })
  value: string
  _selectionStart = 0
  _selectionEnd = 0
  $el: HTMLTextAreaElement

  get text(): string {
    return this.value
  }

  set text(value: string) {
    this._input(value)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  get listeners(): Record<string, Function | Function[]> {
    // HACK: v-on="$listeners" で ｀input` が正常に動作しないので `input` を削除
    const listeners = this.$listeners
    delete listeners['input']
    return listeners
  }

  @Emit('input')
  _input(value: string): string {
    return value
  }

  @Emit('selectionchange')
  @Debounce()
  _selectionchange(value: TextSelection): TextSelection {
    return value
  }

  @Debounce()
  _handleSelectionChange(event: Event): void {
    const target = event.target as HTMLTextAreaElement
    const { selectionStart: start, selectionEnd: end } = target
    if (event.type !== 'focus' && start === this._selectionStart && end === this._selectionEnd) return
    this._selectionStart = start
    this._selectionEnd = end
    this._selectionchange({ start, end })
  }

  setSelectionRange(start: number, end: number, direction?: 'forward' | 'backward' | 'none'): void {
    this.$el.setSelectionRange(start, end, direction)
  }

  focus(options?: FocusOptions): void {
    this.$el.focus(options)
  }

  getBoundingClientRect(): DOMRect {
    return this.$el.getBoundingClientRect()
  }
}
</script>
