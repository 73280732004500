<template>
  <v-list-item
    :class="{ 'p-gift-list-item--editing': editingGift }"
    class="p-gift-list-item"
  >
    <v-list-item-avatar class="align-self-start c-list-item__avatar">
      <Avatar :user="recipient" />
      <span class="c-feelingBadge">{{ _.get(gift, 'recipient_feeling.emoji', defaultFeeling) }}</span>
    </v-list-item-avatar>
    <v-list-item-content
      :class="{ 'overflow-visible': editingGift }"
      class="flex-column align-start"
    >
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <router-link
          v-if="gift.recipient_id >0"
          :to="{ name: 'member/home', params: { id: gift.recipient_id } }"
          class="c-recipient__name"
        >
          <strong>{{ recipientName }}</strong>
        </router-link>
        <strong v-else>{{ recipientName }}</strong>
        <div class="timeline-date ml-2">
          {{ $d($moment(gift.created_at).toDate(), 'long') }}
        </div>
        <v-spacer />
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <div>
          <router-link
            v-if="gift.sender_id >0"
            :to="{ name: 'member/home', params: { id: gift.sender_id } }"
          >
            <strong>{{ senderName }}</strong>
          </router-link>
          <strong v-else>{{ senderName }}</strong>
          さんから感謝が届きました。
          <v-icon
            v-if="gift.accepted_at"
            color="#3a9bfc"
            small
          >
            {{ icons.mdiCheckboxMarkedCircle }}
          </v-icon>
          <v-icon
            v-else
            color="#d3d4d3"
            small
          >
            {{ icons.mdiCircleOutline }}
          </v-icon>
        </div>
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <div>
          <TagGroup :value="_.map(gift.categories, 'name')" />
        </div>
      </v-row>
      <v-form
        v-show="editingGift"
        class="c-gift__body"
        @submit.stop.prevent="save"
      >
        <MessageEditor
          v-validate="'required|max:500'"
          :attachments="editingGift && editingGift.attachments"
          :error-messages="$validator.errors.collect('message')"
          :value="editingGift && editingGift.text"
          class="c-gift__message--editing"
          data-vv-name="message"
          data-vv-validate-on="blur|input"
          @changeAttachments="editingGift.attachments = $event"
          @input="editingGift.text = $event"
          @keydown.alt.enter.stop.prevent.capture="save"
          @keydown.ctrl.enter.stop.prevent.capture="save"
          @keydown.shift.enter.stop.prevent.capture="save"
          @keydown.meta.enter.stop.prevent.capture="save"
          @keydown.esc.stop.prevent.capture="cancel"
        />
        <v-row class="ma-0 pa-0">
          <v-btn
            class="mr-2"
            @click="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="isInvalid"
            color="primary"
            type="submit"
          >
            {{ $t('button.save') }}
          </v-btn>
          <v-spacer />
        </v-row>
      </v-form>
      <v-row
        v-show="!editingGift"
        class="ma-0 pa-0 c-gift__body"
      >
        <Avatar
          :user="sender"
          class="mr-2"
          size="24px"
        />
        <span
          :class="{ 'c-gift__message--edited': isEdited }"
          class="c-gift__message flex-grow-1"
          v-html="$marked(gift.message)"
        />
        <v-row
          v-if="isOwn"
          align-content="start"
          class="ma-0 pa-0 flex-grow-0 flex-shrink-0 ml-auto"
        >
          <v-spacer />
          <v-btn
            :disabled="disabled"
            icon
            small
            tile
            @click.capture="edit"
          >
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
        </v-row>
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <ReactionChipGroup
          :loading="reactionLoading"
          :value="reactions"
          @click:reaction="selectReaction"
        />
      </v-row>
      <v-row
        v-if="gift.comments.length >0"
        class="ma-0 pa-0 mt-2"
      >
        <v-col>
          {{ gift.comments.length }}件の返信があります。
          <a
            v-if="commentable"
            href="#"
            @click.stop.prevent="moveToThread"
          >スレッドを開く</a>
        </v-col>
      </v-row>
    </v-list-item-content>
    <Actions
      :absolute="true"
      :show="!editingGift && (showActions || showReaction)"
    >
      <ReactionAction
        @hidden="showReaction = false"
        @shown="showReaction = true"
        @select:reaction="selectReaction"
      />
      <CommentAction
        v-if="commentable"
        @click.stop.prevent="moveToThread"
      />
    </Actions>
  </v-list-item>
</template>
<script lang="ts">
import { defaultFeeling } from '@/store/models/Feeling'
import Avatar from '@/components/organisms/Avatar.vue'
import Component, { mixins } from 'vue-class-component'
import Me from '@/store/models/Me'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Gift from '@/store/models/Gift'
import Actable from '@/mixins/actable'
import Reactable from '@/mixins/reactable'
import TagGroup from '@/components/organisms/TagGroup.vue'
import { mdiCheckboxMarkedCircle, mdiCircleOutline, mdiPencil } from '@mdi/js'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import MessageEditor, { MessageEditorValue } from '@/components/organisms/editor/MessageEditor.vue'
import notifyResponseError from '@/utils/notifyResponseError'
import CommentAction from '@/components/organisms/actions/CommentAction.vue'

@Component({
  components: {
    Avatar,
    TagGroup,
    MessageEditor,
    CommentAction,
  },
})
export default class GiftListItemComponent extends mixins(Actable, Reactable) {
  readonly defaultFeeling = defaultFeeling
  readonly reactableModel = Gift
  readonly icons = {
    mdiCheckboxMarkedCircle,
    mdiCircleOutline,
    mdiPencil,
  }
  @Prop({ default: {} })
  readonly value!: Record
  @Prop({ default: false })
  readonly disabled!: boolean
  @Prop({ default: false })
  readonly commentable?: boolean
  editingGift: MessageEditorValue = null

  get recipient(): Record {
    return _get(this.gift, 'recipient')
  }

  get recipientName(): string {
    return this.gift.recipientName
  }

  get sender(): Record {
    return _get(this.gift, 'sender')
  }

  get senderName(): string {
    return this.gift.senderName
  }

  get gift(): Record {
    return this.item
  }

  get item(): Record {
    if (_isNil(this.value)) return null
    return Gift.query()
      .with([
        'recipient',
        'recipient_feeling',
        'sender',
        'sender_feeling',
        'categories',
        'reactions',
        'reactions.user',
        'comments',
      ])
      .find(this.value.id)
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get isOwn(): boolean {
    return this.gift.sender_id === _get(this.currentUser, 'id')
  }

  get isEdited(): boolean {
    return _get(this.gift, 'edited')
  }

  get isInvalid(): boolean {
    if (_isNil(this.editingGift)) return true
    if (this.editingGift.text.replace(/[\s\t\n]+/g, '') === '') return true
    return this.$validator.errors && this.$validator.errors.count() > 0
  }

  @Emit('startEditing')
  startEditing(): void {
    return void 0
  }

  @Emit('finishEditing')
  finishEditing(): void {
    return void 0
  }

  edit(): void {
    this.showActions = false
    this.showReaction = false
    this.editingGift = {
      text: this.gift.message,
      attachments: [],
    }
    this.startEditing()
  }

  cancel(): void {
    this.reset()
    this.finishEditing()
  }

  async save(): Promise<void> {
    if (!(await this.$validator.validateAll())) return
    const gift: {
      message: string
      attachment_ids?: number[]
    } = {
      message: this.editingGift.text,
    }
    if (this.editingGift.attachments.length > 0) {
      gift['attachment_ids'] = this.editingGift.attachments.map((file) => file.id)
    }
    this.reset()
    this.$http
      .patch(['gifts', this.gift.id].join('/'), gift)
      .then(({ data }) => {
        Gift.insertOrUpdate({ data })
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        if (err.status === 422) {
          notifyResponseError(err.response.data)
          return
        }
        return Promise.reject(err)
      })
      .finally(() => {
        this.reset()
        this.finishEditing()
      })
  }

  reset(): void {
    this.$validator.pause()
    this.$validator.reset().then(() => this.$validator.resume())
    this.showActions = false
    this.showReaction = false
    this.editingGift = null
  }

  moveToThread(): void {
    this.$router.push({
      name: 'takes',
      params: {
        id: this.gift.id,
      },
    })
  }
}
</script>
<style lang="scss" scoped>
.p-gift-list-item {
  &--editing {
    background-color: #f5f5f5;
  }
}

.c-gift {
  &__body {
    width: 100%;
  }

  &__message {
    word-break: break-all;
    word-wrap: break-word;

    &--editing {
      width: 100%;
    }

    &--edited {
      // noinspection CssInvalidPseudoSelector
      :deep(.markdown-body) {
        &::after {
          display: inline-table;
          margin-left: 4px;
          color: #d3d4d3;
          content: '(edited)';
        }

        > :not(ol, ul, pre, blockquote):last-child {
          display: inline;
        }
      }
    }
  }
}

.c-recipient {
  &__name {
    font-size: .9rem;
  }
}

.c- {
  &list-item {
    &__avatar {
      overflow: visible;
    }
  }
}
</style>
