<template>
  <v-container
    v-if="gift"
    class="p-gift"
  >
    <v-container
      :class="{ 'p-gift__list--xs-only': $vuetify.breakpoint.xsOnly }"
      class="p-gift__list"
    >
      <TimelineGiftListItem :value="gift" />
      <TimelineMessageListItem :value="gift" />
      <v-divider />
      <CommentList
        :model="model"
        :value="comments"
      />
      <v-list-item
        v-if="!$vuetify.breakpoint.xsOnly"
        class="py-4"
      >
        <PostComment
          :commentable="gift"
          :model="model"
        />
      </v-list-item>
    </v-container>
    <v-footer
      v-if="$vuetify.breakpoint.xsOnly"
      :class="{ 'p-gift__footer--xs-only': $vuetify.breakpoint.xsOnly }"
      absolute
      class="p-gift__footer justify-center d-flex flex-column flex-grow-1"
    >
      <PostComment
        :commentable="gift"
        :model="model"
      />
    </v-footer>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import TimelineGiftListItem from '@/components/organisms/timelines/TimelineGiftListItem.vue'
import TimelineMessageListItem from '@/components/organisms/timelines/TimelineMessageListItem.vue'
import CommentList from '@/components/organisms/comments/CommentList.vue'
import PostComment from '@/components/organisms/comments/PostComment.vue'
import { Record } from '@vuex-orm/core'
import Gift from '@/store/models/Gift'
import { Watch } from 'vue-property-decorator'
import Me from '@/store/models/Me'

@Component({
  components: {
    TimelineGiftListItem,
    TimelineMessageListItem,
    CommentList,
    PostComment,
  },
})
export default class extends Vue {
  model: typeof Gift = Gift

  get giftId(): number {
    return +this.$route.params.id
  }

  get gift(): Record {
    return Gift.query()
      .with([
        'comments',
        'comments.user',
        'comments.user.feeling',
        'recipient_feeling',
        'sender',
        'sender_feeling',
        'categories',
        'reactions',
        'reactions.user',
      ])
      .find(this.giftId)
  }

  get comments(): Record {
    if (!this.gift) return []
    return this.gift.comments
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  mounted(): void {
    this.fetch()
  }

  @Watch('giftId')
  fetch(): void {
    Gift.fetch(this.giftId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        this.$router.replace({ name: 'timeline' })
        throw err
      })
      .then(() => {
        this.receive()
      })
  }

  receive(): void {
    if (!this.gift) return
    if (this.currentUser.id !== this.gift.recipient_id) return
    if (this.gift.accepted_at) return
    this.$http.patch(['takes', this.gift.id].join('/')).then(({ data }) => {
      Gift.insertOrUpdate({ data })
    })
  }
}
</script>
<style lang="scss" scoped>
.p-gift {
  position: relative;
  z-index: 0;
  max-width: 100%;
  padding: 0;

  &__list {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;

    &--xs-only {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-right: 0;
      padding-bottom: 111px;
      padding-left: 0;
    }
  }

  &__footer {
    z-index: 4;
    min-height: 111px;

    &--xs-only {
      position: fixed;
    }
  }
}
</style>
