<template>
  <v-row class="ma-0 pa-0">
    <v-col class="ma-0 pa-0">
      <section>
        <slot />
      </section>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import MyContainer from '@/components/atoms/MyContainer.vue'

@Component({
  components: { MyContainer },
})
export default class extends Vue {
}
</script>
