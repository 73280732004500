<template>
  <v-card class="elevation-2">
    <v-card-title class="text-subtitle-1 grey lighten-2">
      お問い合わせ
    </v-card-title>
    <v-card-text class="pt-6">
      以下よりお問い合わせください。<br>
      ボタンをクリックするとお問い合わせフォームが開きます。
    </v-card-text>
    <v-card-actions class="justify-center px-6">
      <v-btn
        class="flex-grow-1"
        href="https://docs.google.com/forms/d/e/1FAIpQLSe0WijnVE52a8xfgcIMmNDMu7aN742W2GhWbqSwLeoIn3ufVA/viewform?usp=sf_link"
        rel="external nofollow noopener"
        target="_blank"
      >
        お問い合わせフォーム
      </v-btn>
    </v-card-actions>
    <v-card-text class="pt-6">
      受付時間
      <ul>
        <li>10:00 〜 18:00 (土日、祝日、弊社休業期間以外)</li>
      </ul>
    </v-card-text>
    <v-card-text>
      注意事項
      <ul>
        <li>お問い合わせの内容や状況により、お時間を頂く場合やご回答できない場合がございます。</li>
        <li>土日、祝日、弊社休業期間中は、返信が翌営業日以降になります。</li>
        <li>
          公式サイトやお知らせにてご案内している内容に関しては、それぞれの告知内容を持って回答とさせていただく場合がございます。
        </li>
        <li>ご意見・ご要望に関しては原則返信いたしませんが、内容を確認し参考にさせていただきます。</li>
      </ul>
    </v-card-text>
    <v-card-text>
      ※Ageluの推奨環境は以下となります。<br>
      OS：Windows 10、Mac OS X<br>
      ブラウザ：Google Chrome 最新版
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import _get from 'lodash/get'

@Component
export default class extends Vue {
  get currentUser(): Record {
    return Me.query().with(['tenant']).first()
  }

  get tenant(): Record {
    return _get(this.currentUser, 'tenant')
  }
}
</script>
