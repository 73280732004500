<template>
  <MyContainer>
    <v-tabs
      v-model="tab"
      class="elevation-2 fill-height"
    >
      <v-tab href="#took">
        もらった
      </v-tab>
      <v-tab href="#gave">
        おくった
      </v-tab>
      <v-tab-item value="took">
        <GiftResult type="took" />
      </v-tab-item>
      <v-tab-item value="gave">
        <GiftResult type="gave" />
      </v-tab-item>
    </v-tabs>
  </MyContainer>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import GiftResult from '@/components/organisms/gifts/GiftResult.vue'
import MyContainer from '@/components/atoms/MyContainer.vue'

@Component({
  components: {
    MyContainer,
    GiftResult,
  },
})
export default class extends Vue {
  tab = 'took'

  mounted(): void {
    this.tab = this.$route.hash ? this.$route.hash.replace(/^#/, '') : 'took'
  }
}
</script>
