<template>
  <v-chip-group
    :value="category"
    column
    @change="changeCategory"
  >
    <v-chip
      v-for="category in categories"
      :key="category.id"
      :disabled="loading"
      :value="category.id"
      filter
    >
      {{ category.name }}
    </v-chip>
  </v-chip-group>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Category from '@/store/models/Category'

@Component
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: string
  @Prop({ default: true })
  readonly loading: boolean

  get category(): string {
    return this.value
  }

  get categories(): Record[] {
    return Category.query().orderBy('display_order', 'asc').orderBy('id', 'asc').all()
  }

  @Emit('input')
  changeCategory(value: string): string {
    return value
  }

  mounted(): void {
    Category.fetchAll()
  }
}
</script>
