// eslint-disable-next-line @typescript-eslint/no-explicit-any
import General from '@/apps/general/App.vue'
import generalRoutes from '@/apps/general/routes'
import Admin from '@/apps/admin/App.vue'
import adminRoutes from '@/apps/admin/routes'
import loadView from '@/apps/loadView'
import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
  {
    name: 'wizard',
    path: '/wizard',
    component: loadView('admin/Wizard'),
    meta: {
      auth: true,
      admin: true,
    },
  },
  {
    name: 'notices',
    path: '/notices',
    component: loadView('NoticeList'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin',
    component: Admin,
    meta: {
      auth: true,
    },
    children: adminRoutes,
  },
  {
    path: '',
    component: General,
    meta: {
      auth: true,
    },
    children: generalRoutes,
  },
  {
    path: '*',
    component: loadView('errors/NotFound'),
  },
]
export default routes
