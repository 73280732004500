<template>
  <v-dialog
    :value="active"
    class="p-dashboard-detail"
    @input="input"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-divider />
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :loading="loading"
        disable-sort
        hide-default-footer
      >
        <template #[`item.ranking`]="{ item }">
          <v-avatar
            :size="item.ranking >3 ? 32 : 40"
            color="primary"
          >
            <span
              :style="{ 'font-size': item.ranking >3 ? '18px' : '24px' }"
              class="white--text"
            >{{
              item.ranking
            }}</span>
          </v-avatar>
        </template>
        <template #[`item.displayName`]="{ item }">
          <v-avatar :size="24">
            <img :src="item.avatarImage">
          </v-avatar>
          {{ item.displayName }}
        </template>
      </v-data-table>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn @click="hide">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import { DataTableHeader } from 'vuetify'

@Component
export default class extends Vue {
  @Prop({ default: false })
  readonly value!: boolean
  @Prop({ default: false })
  readonly loading?: boolean
  @Prop({ default: '' })
  readonly title!: string
  @Prop({ default: [] })
  readonly items!: Record[]

  get headers(): DataTableHeader[] {
    return [
      {
        text: '順位',
        align: 'center',
        value: 'ranking',
      },
      {
        text: '名前',
        align: 'start',
        value: 'displayName',
      },
      {
        text: '感謝した回数',
        align: 'center',
        value: 'sent_count',
      },
      {
        text: 'おくったTips',
        align: 'center',
        value: 'sent_sum',
      },
      {
        text: '感謝してもらった回数',
        align: 'center',
        value: 'took_count',
      },
      {
        text: 'もらったTips',
        align: 'center',
        value: 'took_sum',
      },
      {
        text: '前回の順位',
        align: 'center',
        value: 'last_ranking',
      },
    ]
  }

  get active(): boolean {
    return this.value
  }

  @Emit()
  input(value: boolean): boolean {
    document.querySelector('.v-dialog.v-dialog--active').scrollTo(0, 0)
    return value
  }

  hide(): void {
    this.input(false)
  }
}
</script>
