<template>
  <v-container fluid>
    <MemberList ref="memberList" />
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import MemberList from '@/components/organisms/members/MemberList.vue'
import Component from 'vue-class-component'

@Component({
  components: {
    MemberList,
  },
  metaInfo() {
    return {
      title: 'メンバー一覧',
    }
  },
})
export default class extends Vue {
}
</script>
