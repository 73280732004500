<template>
  <v-app>
    <OverstayingAlert v-if="admin" />
    <template v-if="error">
      <NotFound v-if="error.status === 404" />
      <Forbidden v-else-if="error.status === 403" />
      <NotWork v-else />
    </template>
    <template v-else-if="!loading">
      <router-view />
    </template>
    <NoticeDialog />
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Me from '@/store/models/Me'
import config from '@/config'
import _get from 'lodash/get'
import { ScreenError } from '@/store/modules/screen/types'
import { Record } from '@vuex-orm/core'
import tenantChannels from '@/config/channels/tenant'
import OverstayingAlert from '@/components/organisms/tenants/OverstayingAlert.vue'
import NotFound from '@/pages/errors/NotFound.vue'
import Forbidden from '@/pages/errors/Forbidden.vue'
import NotWork from '@/pages/errors/NotWork.vue'
import NoticeDialog from '@/components/organisms/NoticeDialog.vue'

@Component({
  components: {
    NoticeDialog,
    NotWork,
    Forbidden,
    NotFound,
    OverstayingAlert,
  },
  metaInfo() {
    const titles = ['Agelu']
    if (this.tenant?.name) {
      titles.push(this.tenant.name)
    }
    return {
      titleTemplate(title) {
        const _titles = [...titles]
        if (title) {
          _titles.push(title)
        }
        return `${this.envMark}${_titles.reverse().join(' | ')}`
      },
    }
  },
})
export default class App extends Vue {
  loading = true

  get error(): ScreenError | null {
    return this.$store.state.screen.error
  }

  get currentUser(): Record {
    return Me.query().with(['tenant']).first()
  }

  get admin(): boolean {
    return _get(this.currentUser, 'admin')
  }

  get tenant(): Record {
    return _get(this.currentUser, 'tenant')
  }

  created(): void {
    this.$channels.subscribe(tenantChannels, {
      tenant: location.host.split('.')[0],
    })
  }

  async mounted(): Promise<void> {
    // HACK: パブリックのサブドメインの場合、認証できずに後続の処理でエラーになるので追加
    const subdomain = _get(config.excludedSubdomains, location.host.split('.')[0])
    if (subdomain) return
    try {
      if (!this.$route?.meta?.auth) return
      this.loading = true
      await Me.load()
    } finally {
      this.loading = false
    }
  }

  @Watch('$route')
  appear(): void {
    if (this.currentUser && this.currentUser.online) return
    this.$channels.get('AppearanceChannel').appear()
  }
}
</script>
