<template>
  <PanelBase
    :open="open"
    :title="title"
    @update:open="$emit('update:open', $event)"
  >
    <template #help>
      <span>閲覧しているグループの中で業務過多など何かしらの要因により、他メンバーとの連携がうまく行われていない可能性があるメンバーを表示しています。</span>
    </template>
    <div class="py-0 px-4">
      <PanelUser
        :avatar="_.get(problematicPerson, 'avatarImage')"
        :loading="isLoading()"
        :name="_.get(problematicPerson, 'displayName')"
      />
      <PanelRanking
        :gives="_.get(problematicPerson, 'sent_sum')"
        :last-rank="_.get(problematicPerson, 'last_ranking')"
        :loading="isLoading()"
        :takes="_.get(problematicPerson, 'took_sum')"
      >
        <template #help-last-rank>
          <span>{{ lastTermText }}の期間をもとに比較した順位です。</span>
        </template>
      </PanelRanking>
    </div>
    <template #actions>
      <v-btn
        :loading="isLoading()"
        depressed
        small
        @click="showDetail"
      >
        <v-icon>{{ icons.mdiAccountSearch }}</v-icon>
        もっと確認
      </v-btn>
    </template>
    <DashboardDetail
      v-model="detail"
      :items="problematicPeople"
      :loading="isLoading()"
      :title="title"
    />
  </PanelBase>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Item, Record } from '@vuex-orm/core'
import _head from 'lodash/head'
import * as http from '@/utils/http'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import { User, UserStatus } from '@/store/models/Person'
import { mdiAccountSearch } from '@mdi/js'
import DashboardDetail from '@/components/organisms/dashboard/DashboardDetail.vue'
import { PanelBase, PanelRanking, PanelUser } from '@/components/organisms/dashboard/panel'
import Department from '@/store/models/Department'

@Component({
  name: 'dashboard-problematic-person',
  components: {
    PanelBase,
    PanelUser,
    PanelRanking,
    DashboardDetail,
  },
})
export default class extends Vue {
  @Prop({ default: {} })
  readonly value!: {
    dates: [string, string]
    tags: string[]
    status: 'all' | UserStatus
    department: Item<Department>
  }
  @Prop({ default: false })
  readonly loading?: boolean
  @Prop({ default: false })
  readonly open?: boolean
  readonly icons = {
    mdiAccountSearch,
  }
  readonly title = '問題が存在している可能性'
  _loading = true
  detail = false
  data: Record = null

  get dates(): [string, string] {
    return this.value.dates
  }

  get tags(): string[] {
    return this.value.tags
  }

  get status(): 'all' | UserStatus {
    return this.value.status
  }

  get department(): Item<Department> {
    return this.value.department
  }

  get lastTermText(): string {
    if (_isNil(this.data)) return ''
    return `${this.data['last_term'].from} ~ ${this.data['last_term'].to}`
  }

  get problematicPeople(): Record[] {
    if (_isNil(this.data)) return []
    return this.data.contributors.map((contributor) => {
      const user: Record = User.find(+contributor.id)
      contributor.avatarImage = _get(user, 'avatarImage', '')
      contributor.displayName = _get(user, 'displayName', contributor.email)
      return contributor
    })
  }

  get problematicPerson(): Record {
    return _head(this.problematicPeople)
  }

  isLoading(): boolean {
    return this.loading || this._loading
  }

  async fetch(): Promise<void> {
    this._loading = true
    const params = this.createParams({
      sort: [
        { name: 'contribution', order: 'asc' },
        { name: 'last_contribution', order: 'asc' },
      ],
    })
    try {
      const { data } = await this.$http.get('summaries/contributors', {
        params,
      })
      this.data = data
    } finally {
      this._loading = false
    }
  }

  createParams({ sort }: { sort?: http.SearchSort }): Partial<http.RansackParams> {
    sort.push({ name: 'id', order: 'asc' })
    const params = <Partial<http.RansackParams>>Object.assign(
      {},
      new http.SearchCondition({ sort }).toRansackParams(),
      User.createSearchCondition({
        departmentId: this.department?.id,
        tags: this.tags,
        status: this.status,
      }),
    )
    params['q[id_gt]'] = 0
    params['q[created_at_lteq]'] = this.$moment(this.dates[1]).endOf('day').format()

    return {
      from: this.$moment(this.dates[0]).startOf('day').format(),
      to: this.$moment(this.dates[1]).endOf('day').format(),
      ...params,
    }
  }

  showDetail(): void {
    this.detail = true
  }
}
</script>
