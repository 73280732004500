<template>
  <v-dialog
    v-model="value"
    content-class="p-imagePreviewDialog"
  >
    <v-form @submit.stop.prevent="submit">
      <v-card>
        <v-card-title :style="{ height: `${headerHeight}px` }">
          <span class="p-imagePreviewDialog__title">{{ fileName }}</span>
        </v-card-title>
        <div
          :style="{ maxHeight: `${maxHeight()}px` }"
          class="grey lighten-2 ma-0 pa-0 d-flex overflow-auto"
        >
          <v-img
            :height="height"
            :src="_.get(file, 'url')"
            :style="{ aspectRatio }"
            :width="width"
            class="white ma-auto flex-grow-0"
          >
            <template #placeholder>
              <v-row
                align="center"
                class="ma-0 pa-0 fill-height"
                justify="center"
              >
                <v-progress-circular
                  color="primary"
                  indeterminate
                />
              </v-row>
            </template>
          </v-img>
        </div>
        <v-divider />
        <v-card-actions :style="{ height: `${footerHeight}px` }">
          <v-spacer />
          <v-btn
            v-if="!_.isNil(imagePreview.delete) && !_.isNil(imagePreview.file)"
            color="error"
            text
            type="submit"
          >
            {{ $t('button.delete') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('button.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace, State } from 'vuex-class'
import { Record } from '@vuex-orm/core'
import _get from 'lodash/get'

const ImagePreview = namespace('imagePreview')
const borderWidth = 1
@Component
export default class extends Vue {
  @State('imagePreview') imagePreview
  @ImagePreview.Action('hide') hide
  @ImagePreview.Action('delete') delete
  aspectRatio: string | number = ''
  width: string | number = 0
  height: string | number = 0
  headerHeight = 64
  footerHeight = 52

  get value(): boolean {
    return this.imagePreview.value
  }

  set value(value: boolean) {
    if (value) return
    this.hide()
  }

  get fileName(): string {
    return decodeURIComponent(_get(this.imagePreview.file, 'url', '').split('/').pop())
  }

  get file(): Record {
    if (this.imagePreview.file) {
      this.aspectRatio = ''
      this.width = 0
      this.height = this.maxHeight()
      const img = new Image()
      img.addEventListener('load', () => {
        this.aspectRatio = img.width / img.height
        let width: number
        if (img.width > this.maxWidth()) {
          width = this.maxWidth()
        } else {
          width = img.width
        }
        const height = width / this.aspectRatio
        let zoom = 1
        if (width < this.maxWidth() / 2 && height < this.maxHeight() / 2) {
          zoom = 2
        }
        if (this.aspectRatio > 1) {
          this.width = height > this.maxHeight() ? this.maxHeight() * this.aspectRatio : width * zoom
          this.height = 'auto'
        } else {
          this.height = height > this.maxHeight() ? this.maxHeight() : height * zoom
          this.width = 'auto'
        }
        img.remove()
      })
      img.src = _get(this.imagePreview.file, 'url')
    }
    return this.imagePreview.file
  }

  maxWidth(): number {
    return window.innerWidth * 0.9
  }

  maxHeight(): number {
    return window.innerHeight * 0.9 - this.headerHeight - this.footerHeight - borderWidth * 2
  }

  close(): void {
    this.value = false
  }

  async submit(): Promise<void> {
    await this.delete()
    this.value = false
  }
}
</script>
<style lang="scss" scoped>
.p-imagePreviewDialog {
  min-width: 100px;

  &__title {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
