<template>
  <v-app>
    <Navigation v-model="drawer" />
    <Header @click:menu="drawer = !drawer" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import Header from '@/apps/admin/Header.vue'
import Navigation from '@/apps/admin/Navigation.vue'

@Component({
  components: {
    Header,
    Navigation,
  },
  metaInfo() {
    return {
      title: '管理者機能',
    }
  },
})
export default class extends Vue {
  drawer: boolean = null
}
</script>
