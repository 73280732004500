const _getElement = (html): Node => {
  const $span = document.createElement('span')
  $span.innerHTML = html
  if ($span.childNodes.length > 1) return $span
  return $span.firstChild
}
const _extractTags = (node: Node, tags: string[]): void => {
  const childNodes = node.childNodes
  for (let idx = 0; idx < childNodes.length; idx++) {
    const child = childNodes[idx]
    if (child instanceof Element) {
      if (child.tagName.toLowerCase() !== 'p') continue
      _extractTags(child, tags)
    }
    if (child instanceof Text) {
      child.textContent.split('\n').forEach((text) => {
        const matches = text.match(/(^|\s)(#[^\s]+)/gm)
        if (!matches) return
        matches.forEach((match) => {
          tags.push(match.trim().slice(1))
        })
      })
    }
  }
}
const extractTags = (markdown: string): string[] => {
  const tags: string[] = []
  if (markdown === '') return tags
  _extractTags(_getElement(markdown), tags)
  return tags
}
const _tagToLink = (node: Node): void => {
  const childNodes = node.childNodes
  for (let idx = 0; idx < childNodes.length; idx++) {
    const child = childNodes[idx]
    if (child instanceof Element) {
      if (child.tagName.toLowerCase() !== 'p') continue
      _tagToLink(child)
    }
    if (child instanceof Text) {
      const content = child.textContent
        .split('\n')
        .map((text) => {
          return text.replace(/(^|\s)(#[^\s]+)/gm, `$1<a class="link-hashTag" href="#" data-hashTag="$2">$2</a>`)
        })
        .join('\n')
      child.parentNode.replaceChild(_getElement(content), child)
    }
  }
}
const tagToLink = (markdown: string): string => {
  if (markdown === '') return ''
  const $md = _getElement(markdown) as HTMLDivElement
  _tagToLink($md)
  return $md.outerHTML
}
export { extractTags, tagToLink }
